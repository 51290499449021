
import { RouteName } from "@/constante/RouterConst";
import ApiGestionCap from "@/services/ApiGestionCap";
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import { useReturnStore } from "@/store/returnStore";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import { usePendingStore } from "@/store/pendingStore";
import { ModelCustomerStock } from "@/type/Return/ReturnType";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import ServiceTable from "@/services/ServiceTable";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";
import ModalReturnItemError from "@/components/return/ModalReturnItemError.vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";

export default defineComponent({
  name: "CustomerStock",
  components: {
    HeaderCustomerData,
    InputTextSite,
    BlockMsgError,
    ButtonSite,
    ModalReturnItemError,
  },
  setup() {
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const idCustomer = ref(null as string | null);

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const rows = ref([] as ModelCustomerStock[]);

    const family = ref([] as string[]);

    const columns = [
      {
        name: "regroupementFamily",
        required: true,
        label: "message.customer.customer_stock.table.regroupementFamily",
        align: "left",
        field: (row: ModelCustomerStock) => row.regroupementFamily,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "idErpItem",
        required: true,
        label: "message.customer.customer_stock.table.idErpItem",
        align: "left",
        field: (row: ModelCustomerStock) => row.idErpItemFormated,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "vendorItemNo",
        required: true,
        label: "message.customer.customer_stock.table.vendorItemNo",
        align: "left",
        field: (row: ModelCustomerStock) => row.vendorItemNo,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "stockQty",
        required: true,
        label: "message.customer.customer_stock.table.stockQty",
        align: "center",
        field: (row: ModelCustomerStock) => row.stockQty,
        sortable: true,
      },
      {
        name: "stockAvaiblable",
        required: true,
        label: "message.customer.customer_stock.table.stockAvaiblable",
        align: "center",
        field: (row: ModelCustomerStock) => row.stockQty - row.qtyOnSalesOrder,
        sortable: true,
      },
      {
        name: "qtyOnPurchaseOrder",
        required: true,
        label: "message.customer.customer_stock.table.qtyOnPurchaseOrder",
        align: "center",
        field: (row: ModelCustomerStock) => row.qtyOnPurchaseOrder,
        sortable: true,
      },
      {
        name: "qtyOnSalesOrder",
        required: true,
        label: "message.customer.customer_stock.table.qtyOnSalesOrder",
        align: "center",
        field: (row: ModelCustomerStock) => row.qtyOnSalesOrder,
        sortable: true,
      },
      {
        name: "qtyStockType",
        required: true,
        label: "message.customer.customer_stock.table.qtyStockType",
        align: "center",
        field: (row: ModelCustomerStock) => row.qtyStockType,
        sortable: true,
      },
    ];
    const filter = ref({
      idErpItem: "" as string,
      vendorItemNo: "" as string,
      regroupementFamily: "" as string,
    });

    return {
      returnStore,
      idCustomer,
      getCustomer,
      pendingStore,
      rows,
      columns,
      family,
      filter,
      ServiceTable,
      globalStore,
    };
  },
  async mounted() {
    this.globalStore.$patch({ errorMessage: null });
    this.pendingStore.$patch({ routerIsPending: true });
    await isTokenReady();
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: "Customers" });
    }
    //récupération des données du customer
    try {
      this.returnStore.refreshCustomer(this.idCustomer);
    } catch (error) {
      this.pendingStore.$patch({ routerIsPending: false });
      this.$router.push({ name: RouteName.Error });
    }
    //récupération des données purchase
    try {
      const data = await ApiGestionCap.getStockCustomer(this.idCustomer);
      if (data.length === 0) {
        const errorSite = {
          message: this.$t("message.customer.customer_stock.no_data"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      } else {
        this.initFamily(data);
        this.rows = data;
      }
    } catch (error) {
      this.pendingStore.$patch({ routerIsPending: false });
      this.$router.push({ name: RouteName.Error });
    }
    this.pendingStore.$patch({ routerIsPending: false });
  },
  methods: {
    initFamily(data: ModelCustomerStock[]) {
      data.forEach((element) => {
        if (!this.family.includes(element.regroupementFamily)) {
          this.family.push(element.regroupementFamily);
        }
      });
      //trie du tableau par ordre alphabétique
      this.family.sort();
    },
    openModalReturnItemError() {
      const modalReturnItemError = this.$refs.modalReturnItemError as any;
      if (modalReturnItemError) {
        modalReturnItemError.modalOpen();
      }
    },
  },
});
