
import ApiGestionCap from "@/services/ApiGestionCap";
import { usePendingStore } from "@/store/pendingStore";
import { useGlobalStore } from "@/store/globalStore";
import { defineComponent, ref } from "vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { useI18n } from "vue-i18n";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import Separator from "@/components/global/Separator.vue";
import { ImportedUser } from "@/type/UserType";
import BinariStatus from "@/components/global/BinariStatus.vue";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import InputFileSite from "@/components/customInput/InputFileSite.vue";

export default defineComponent({
  name: "GestionRefTypeCustomerGroup",
  components: {
    ButtonSite,
    BlockMsgError,
    Separator,
    BinariStatus,
    InputFileSite,
  },
  setup() {
    const csv = ref(null as null | File);
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const pendingStore = usePendingStore();

    const columns = [
      {
        name: "username",
        required: true,
        label: "message.create_user.table.username",
        align: "left",
        field: (row: ImportedUser) => row.username,
        sortable: true,
      },
      {
        name: "firstName",
        required: true,
        label: "message.create_user.table.firstName",
        align: "left",
        field: (row: ImportedUser) => row.firstName,
        sortable: true,
      },
      {
        name: "lastName",
        required: true,
        label: "message.create_user.table.lastName",
        align: "left",
        field: (row: ImportedUser) => row.lastName,
        sortable: true,
      },
      {
        name: "email",
        required: true,
        label: "message.create_user.table.email",
        align: "left",
        field: (row: ImportedUser) => row.email,
        sortable: true,
      },
      {
        name: "erpId",
        required: true,
        label: "message.create_user.table.erpId",
        align: "center",
        field: (row: ImportedUser) => row.erpId,
        sortable: true,
      },
      {
        name: "isManager",
        required: true,
        label: "message.create_user.table.isManager",
        align: "center",
        field: (row: ImportedUser) => row.isManager,
        sortable: true,
      },
      {
        name: "idKeycloak",
        required: true,
        label: "message.create_user.table.idKeycloak",
        align: "lecenterft",
        field: (row: ImportedUser) => row.idKeycloak,
        sortable: true,
      },
      {
        name: "createdInKeycloak",
        required: true,
        label: "message.create_user.table.createdInKeycloak",
        align: "center",
        field: (row: ImportedUser) => row.createdInKeycloak,
        sortable: true,
      },
      {
        name: "createdInMyGarage",
        required: true,
        label: "message.create_user.table.createdInMyGarage",
        align: "center",
        field: (row: ImportedUser) => row.createdInMyGarage,
        sortable: true,
      },
    ];
    const rows = ref([] as ImportedUser[]);

    const csvUsers = ref(null as null | number);
    const csvUsersImportedKeycloak = ref(null as null | number);
    const csvUsersImportedMyGarage = ref(null as null | number);
    const csvManagerMyGarage = ref(null as null | number);

    return {
      globalStore,
      t,
      pendingStore,
      csv,
      columns,
      rows,
      filter: ref(""),
      csvUsers,
      csvUsersImportedKeycloak,
      csvUsersImportedMyGarage,
      csvManagerMyGarage,
    };
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  methods: {
    /**
     * Fonction qui permet la création de nouveau utilisateur dans le sso depuis un fichier CSV
     */
    async uploadUsers() {
      this.globalStore.$patch({ errorMessage: null });
      this.csvUsers = null;
      this.csvUsersImportedKeycloak = null;
      this.csvUsersImportedMyGarage = null;
      this.csvManagerMyGarage = null;
      if (this.csv) {
        const form = new FormData();
        form.append("csv", this.csv);
        try {
          this.pendingStore.$patch({ routerIsPending: true });
          this.rows = await ApiGestionCap.createUsers(form);
          this.csvUsers = this.rows.length;
          this.csvUsersImportedKeycloak = this.rows.filter(function (
            user: ImportedUser
          ) {
            return user.createdInKeycloak;
          }).length;
          this.csvUsersImportedMyGarage = this.rows.filter(function (
            user: ImportedUser
          ) {
            return user.createdInMyGarage;
          }).length;
          this.csvManagerMyGarage = this.rows.filter(function (
            user: ImportedUser
          ) {
            return user.isManager;
          }).length;
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
        this.pendingStore.$patch({ routerIsPending: false });
      } else {
        const errorSite = {
          message: this.t("message.create_user.error.missingCsv"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      }
    },
  },
});
