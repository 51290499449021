export default {
    filterData(rows: any[], filters: any) {
        for (const [key, value] of Object.entries(filters)) {
            //filtre sur les colonnes avec le filtre correspondant à la clef voulu
            //test du type du filtre (string ou boolean ou null)
            if (typeof value === "string") {
                const filterValue = filters[key].toLowerCase().replace(/\s/g, '');
                rows = rows.filter(
                    (row) =>
                        (row[key] + "")
                            .toLowerCase()
                            .replace(/\s/g, '')
                            .indexOf(filterValue) !== -1
                );
            } else if (typeof value === "boolean") {
                rows = rows.filter((row) => row[key] === filters[key]);
            }
        }
        return rows;
    },
}
