import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "Breadcrumb align-items-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  href: "#"
}
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getTreeBreadcrumb)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTreeBreadcrumb.menuFields, (menuField, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              (null !== menuField.routerRouteName)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: menuField.routerRouteName }
                  }, {
                    default: _withCtx(() => [
                      ('HOME' == menuField.routerRouteName.toUpperCase())
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                            _createVNode(_component_q_icon, {
                              name: "home",
                              size: "1.3em"
                            })
                          ]))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            innerHTML: _ctx.$t(menuField.libelleLink)
                          }, null, 8, _hoisted_4))
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock("a", _hoisted_5, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.$t(menuField.libelleLink)
                    }, null, 8, _hoisted_6)
                  ]))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}