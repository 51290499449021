import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 subTitle" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 subTitle" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = {
  key: 0,
  class: "row"
}
const _hoisted_8 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_option_group = _resolveComponent("q-option-group")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createBlock(_component_TemplateModaleSite, {
    idModal: "ModalReturnFile",
    saveButton: _ctx.saveButton,
    labelModal: _ctx.$t('message.return.select_return.modal_return_file.title'),
    isOpen: _ctx.isOpen,
    onModal_close: _ctx.modalClose,
    onModal_save: _ctx.generateReturnFile,
    class: "modalReturnFile",
    isPending: _ctx.isPending
  }, {
    modalTitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_return.modal_return_file.title")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createVNode(_component_q_form, { ref: "generateFileForm" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("message.return.select_return.modal_return_file.type_choice")), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_q_select, {
                dense: "",
                denseOpts: "",
                filled: "",
                modelValue: _ctx.typeMode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeMode) = $event)),
                options: _ctx.typeOptions
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("message.return.select_return.modal_return_file.format_choice")), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_q_option_group, {
                modelValue: _ctx.group,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.group) = $event)),
                options: _ctx.options,
                color: "primary",
                disable: _ctx.disableElement
              }, null, 8, ["modelValue", "options", "disable"])
            ])
          ]),
          (_ctx.isEmail)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_InputTextSite, {
                    type: "email",
                    modelValue: _ctx.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                    name: "email",
                    label: 
                _ctx.$t('message.return.select_return.modal_return_file.email')
              ,
                    "lazy-rules": "",
                    disable: _ctx.disableElement,
                    rules: [
                (val) =>
                  !!val ||
                  _ctx.$t(
                    'message.return.select_return.modal_return_file.not_null_email'
                  ),
                _ctx.ServiceFormValidation.isValidEmail(_ctx.email),
              ]
                  }, null, 8, ["modelValue", "label", "disable", "rules"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_BlockMsgError)
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }, 8, ["saveButton", "labelModal", "isOpen", "onModal_close", "onModal_save", "isPending"]))
}