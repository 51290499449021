import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = {
  key: 1,
  class: "row"
}
const _hoisted_5 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCustomerData = _resolveComponent("HeaderCustomerData")!
  const _component_BlockCustomerOrderCa = _resolveComponent("BlockCustomerOrderCa")!
  const _component_TableCustomerOrders = _resolveComponent("TableCustomerOrders")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderCustomerData),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.customer_orders")), 1)
      ])
    ]),
    (_ctx.modelCustomerOrderCa)
      ? (_openBlock(), _createBlock(_component_BlockCustomerOrderCa, {
          key: 0,
          modelCustomerOrderCa: _ctx.modelCustomerOrderCa
        }, null, 8, ["modelCustomerOrderCa"]))
      : _createCommentVNode("", true),
    (_ctx.modelCustomerOrders.length > 0 && _ctx.getCustomer)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_TableCustomerOrders, {
              modelCustomerOrders: _ctx.modelCustomerOrders,
              customer: _ctx.getCustomer
            }, null, 8, ["modelCustomerOrders", "customer"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}