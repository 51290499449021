
import { defineComponent } from "vue";

export default defineComponent({
  name: "TooltipSite",
  props: {
    anchor: {
      type: String,
      required: false,
      default: "bottom middle",
    },
    self: {
      type: String,
      required: false,
      default: "center middle",
    },
    classToolTip: {
      type: String,
      required: false,
      default: "ACTION",
    },
  },
});
