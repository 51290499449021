
import { defineComponent, PropType, ref, computed } from "vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { ModalBtnType, ModalWidthType } from "@/type/Modal/ModalType";
import TooltipSite from "@/components/global/TooltipSite.vue";
import PendingSpinner from "@/components/global/PendingSpinner.vue";
export default defineComponent({
  name: "TemplateModaleSite",
  components: {
    ButtonSite,
    TooltipSite,
    PendingSpinner
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    idModal: {
      type: String,
      required: true,
    },
    labelModal: {
      type: String,
      required: true,
    },
    classBootStrapWithModal: {
      type: String,
      required: false,
      default: "modal-lg",
    },
    width: {
      type: String,
      required: false,
      default: "MEDIUM",
    },
    noCloseButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeButton: {
      type: Object as PropType<ModalBtnType>,
      required: false,
      default: {
        label: "message.btn.close",
        affBtn: true,
      } as ModalBtnType,
    },
    isPending : {
      type: Boolean,
      required: false,
      default: false,
    },
    saveButton: {
      type: Object as PropType<ModalBtnType>,
      required: false,
      default: {
        label: "message.btn.save",
        affBtn: true,
      } as ModalBtnType,
    },
    isMaximized: {
      type: Boolean,
      required: false,
      default: false,
    },
    colorClass: {
      type: String,
      required: false,
      default: "ACTION",
    },
  },
  emits: ["modal_save", "modal_close"],
  setup(props) {
    /*** props add ***/
    const maximizedToggle = ref(props.isMaximized);

    const modalWidth = ref({} as ModalWidthType);

    modalWidth.value.full = false;

    switch (props.width) {
      case "SMALL":
        modalWidth.value.class = "modal-small";
        break;
      case "MEDIUM":
        modalWidth.value.class = "modal-medium";
        break;
      case "FULL":
        modalWidth.value.full = true;
        modalWidth.value.class = "";

        break;
      default: {
        modalWidth.value.full = true;
        modalWidth.value.class = "";
      }
    }

    /*** computed ****/
    const haveFooter = computed(() => {
      return props.closeButton.affBtn || props.saveButton.affBtn;
    });
    const haveCloseButton = computed(() => {
      return !props.noCloseButton || !props.closeButton.affBtn;
    });
    const modalWidthClass = computed(() => {
      return "modal-dialog " + props.classBootStrapWithModal;
    });

    const getLabelBtnSize = computed(() => {
      return (
        "message.btn.resize." +
        (maximizedToggle.value ? "minimise" : "maximise")
      );
    });

    const modalIsOpen = computed(() => {
      //return props.isOpen;
      return props.isOpen;
    });

    return {
      maximizedToggle,
      haveFooter,
      haveCloseButton,
      modalWidthClass,
      modalIsOpen,
      modalWidth,
      getLabelBtnSize,
      nodeDragg: null as any,
      target: null as any,
    };
  },

  methods: {
    /**
     * Appel de la méthod sur le parent de la method save
     */
    actionSave() {
      this.$emit("modal_save");
    },
    /**
     * Appel de la méthod sur le parent de l'action de fermeture de la modal
     */
    actionClose() {
      this.maximizedToggle = this.isMaximized;
      this.$emit("modal_close");
    },
    /**
     * Fonction qui permet de focus en haut à l'ouverture de la modale
     */
    onShow() {
      let dialogElements = document.getElementsByClassName("q-dialog");
      this.target = dialogElements[0].querySelector(".q-card");
      this.nodeDragg = this.target.querySelector(".q-bar");
      this.nodeDragg.addEventListener("mousedown", this.onGrab);
    },
    /**
     * Fonction qui permet de focus reset
     */
    onHide() {
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.onLetGo);
      this.nodeDragg.removeEventListener("mousedown", this.onGrab);
    },
    /**
     * Fonction qui permet de faire glisser la modal
     */
    onDrag(e: any) {
      let originalStyles = window.getComputedStyle(this.target);
      this.target.style.left =
        parseInt(originalStyles.left) + e.movementX + "px";
      this.target.style.top = parseInt(originalStyles.top) + e.movementY + "px";
    },
    /**
     *Fonction qui permet de relacher la modal
     */
    onLetGo() {
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.onLetGo);
    },
    /**
     *Fonction qui permet d'attraper la modal
     */
    onGrab() {
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.onLetGo);
    },
  },
});
