import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row Sheet" }
const _hoisted_2 = { class: "col-md-12 titleSheet" }
const _hoisted_3 = { class: "col-md-12 sheet" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-12" }
const _hoisted_6 = {
  key: 0,
  class: "row"
}
const _hoisted_7 = { class: "col-md-3" }
const _hoisted_8 = { class: "col-md-9" }
const _hoisted_9 = { class: "right" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-md-3" }
const _hoisted_12 = { class: "col-md-9" }
const _hoisted_13 = { class: "right" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-md-3" }
const _hoisted_16 = { class: "col-md-9" }
const _hoisted_17 = { class: "right" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-md-3" }
const _hoisted_20 = { class: "col-md-9" }
const _hoisted_21 = { class: "right" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-md-3" }
const _hoisted_24 = { class: "col-md-9" }
const _hoisted_25 = { class: "right" }
const _hoisted_26 = { class: "row" }
const _hoisted_27 = { class: "col-md-3" }
const _hoisted_28 = { class: "col-md-9" }
const _hoisted_29 = { class: "right" }
const _hoisted_30 = { class: "col-md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockCustomerOrderCa = _resolveComponent("BlockCustomerOrderCa")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t("message.customer.sheet.title")), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.customer.customerDiscGroupName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.customer.sheet.customer_disc_group")), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.customer.customerDiscGroupName), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.customer.sheet.customer_id")), 1)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.customer.id), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.customer.sheet.customer_name")), 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.customer.name), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.customer.sheet.address")), 1)
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.customer.postCode + " " + _ctx.customer.city), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.customer.sheet.phone")), 1)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.customer.phoneNo), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.customer.sheet.email")), 1)
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.customer.email), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_30, [
      (_ctx.modelCustomerOrderCa !== null)
        ? (_openBlock(), _createBlock(_component_BlockCustomerOrderCa, {
            key: 0,
            modelCustomerOrderCa: _ctx.modelCustomerOrderCa
          }, null, 8, ["modelCustomerOrderCa"]))
        : _createCommentVNode("", true)
    ])
  ]))
}