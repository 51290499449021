
import { isTokenReady } from "@baloise/vue-keycloak";
import { defineComponent, PropType } from "vue";
import { HeaderStepButtonType } from "@/type/HeaderStepButtonType";
import { useReturnStore } from "@/store/returnStore";

export default defineComponent({
  name: "HeaderStepButton",
  props: {
    btns: {
      type: Array as PropType<Array<HeaderStepButtonType>>,
      required: true,
    },
  },
  async mounted() {
    await isTokenReady();
  },
  methods: {
    redirect(HeaderStepButtonReturn: HeaderStepButtonType) {
      this.$router.push({
        name: HeaderStepButtonReturn.route,
        params: HeaderStepButtonReturn.params,
      });
    },
    isActualRoute(btn: HeaderStepButtonType) {
      return this.$route.name === btn.route;
    },
  },
});
