import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "amazonId" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "date" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "date" }
const _hoisted_7 = { class: "row name" }
const _hoisted_8 = { class: "row addr1" }
const _hoisted_9 = { class: "row addr2" }
const _hoisted_10 = { class: "row addr3" }
const _hoisted_11 = { class: "row zipcodeCity" }
const _hoisted_12 = { class: "row tel" }
const _hoisted_13 = { class: "tableOrderItem" }
const _hoisted_14 = { class: "qty" }
const _hoisted_15 = {
  key: 0,
  class: "row binary"
}
const _hoisted_16 = { class: "col-12" }
const _hoisted_17 = {
  key: 1,
  class: "row"
}
const _hoisted_18 = { class: "col-12" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 0,
  class: "row"
}
const _hoisted_21 = { key: 1 }
const _hoisted_22 = {
  key: 0,
  class: "row"
}
const _hoisted_23 = { class: "col-12 erpOrderNo" }
const _hoisted_24 = {
  key: 1,
  class: "row"
}
const _hoisted_25 = { class: "col-12 erpDateIntegration" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_BinariStatus = _resolveComponent("BinariStatus")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_OrderError = _resolveComponent("OrderError")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createBlock(_component_q_table, {
    ref: 'tableAmazonOrder',
    rows: _ctx.rows,
    "rows-per-page-options": [10, 20, 50, 100],
    columns: _ctx.columns,
    "row-key": "id",
    class: "tableQuasar tableAmazonOrder",
    "wrap-cells": ""
  }, {
    "top-left": _withCtx((props) => [
      _createVNode(_component_q_btn, {
        flat: "",
        round: "",
        icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
        onClick: props.toggleFullscreen,
        class: "q-ml-md"
      }, null, 8, ["icon", "onClick"])
    ]),
    header: _withCtx((props) => [
      _createVNode(_component_q_tr, { props: props }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
            return (_openBlock(), _createBlock(_component_q_th, {
              key: col.name,
              props: props
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
              ]),
              _: 2
            }, 1032, ["props"]))
          }), 128))
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    body: _withCtx((props) => [
      _createVNode(_component_q_tr, { props: props }, {
        default: _withCtx(() => [
          _createVNode(_component_q_td, {
            key: "amazonId",
            props: props
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(props.row.amazonId), 1)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.date_order", {
                  date:  _ctx.$d(props.row.formatedDateOrderOr, "short", "fr"),
                })), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.date_req_ship", {
                  date: _ctx.$d(props.row.formatedDateReqShipOr, "short", "fr"),
                })), 1)
              ])
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            key: "receiver",
            props: props
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, _toDisplayString(props.row.shipToName), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(props.row.shipToAddress1), 1),
              _createElementVNode("div", _hoisted_9, _toDisplayString(props.row.shipToAddress2), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(props.row.shipToAddress3), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(props.row.shipToZipCode) + " " + _toDisplayString(props.row.shipToCity), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.phone", {
                phone: props.row.phoneNumber,
              })), 1)
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            key: "orderItems",
            props: props
          }, {
            default: _withCtx(() => [
              _createElementVNode("table", _hoisted_13, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.sku")), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.stock")), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.price")), 1)
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.row.orderItems, (orderItem, sku) => {
                    return (_openBlock(), _createElementBlock("tr", { key: sku }, [
                      _createElementVNode("td", null, _toDisplayString(orderItem.sku), 1),
                      _createElementVNode("td", null, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["blockQty", 
                        _ctx.ServiceOrder.isStockOrderItemOk(props.row, orderItem) ||
                        props.row.isSendToErp
                          ? 'positive'
                          : 'negative'
                      ])
                        }, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(orderItem.qty), 1)
                        ], 2)
                      ]),
                      _createElementVNode("td", null, _toDisplayString(_ctx.$n(orderItem.price, "price")), 1)
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            key: "status",
            class: "status",
            props: props
          }, {
            default: _withCtx(() => [
              (!props.row.isSendToErp)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_BinariStatus, {
                        status: !_ctx.ServiceOrder.checkErrOrder(props.row)
                      }, null, 8, ["status"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_q_icon, {
                        name: "browser_updated",
                        color: "positive",
                        class: "binariIcon"
                      })
                    ])
                  ]))
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_td, {
            key: "info",
            class: "info",
            props: props
          }, {
            default: _withCtx(() => [
              (!props.row.isSendToErp)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    (props.row.orderErrors.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createVNode(_component_OrderError, {
                            idOrder: props.row,
                            onOpenModalUpdateOrder: _ctx.openModalUpdateOrder,
                            onOpenModalRemoveOrder: _ctx.openModalRemoveOrder
                          }, null, 8, ["idOrder", "onOpenModalUpdateOrder", "onOpenModalRemoveOrder"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    (props.row.erpOrderNo)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.erp_order_no", {
                    erpOrderNo: props.row.erpOrderNo,
                  })), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (props.row.isSendToErp)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("message.amazon.order_batch_detail.erp_integrated_date", {
                    date: props.row.formatedErpDateIntegration,
                  })), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 2
      }, 1032, ["props"])
    ]),
    _: 1
  }, 8, ["rows", "columns"]))
}