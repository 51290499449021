import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "blockError"
}
const _hoisted_2 = { class: "row justify-around" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "text-center error" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_ctx.getErrorMessage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "report_problem",
                color: "red-6"
              }),
              _createElementVNode("span", {
                innerHTML: _ctx.getErrorMessage.message
              }, null, 8, _hoisted_5)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}