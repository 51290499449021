
import { useReturnStore } from "@/store/returnStore";
import { computed, defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import ApiGestionCap from "@/services/ApiGestionCap";
import { useI18n } from "vue-i18n";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import { FindItem } from "@/type/Return/ReturnType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";
import BinariStatus from "@/components/global/BinariStatus.vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";

export default defineComponent({
  name: "ModalFindItem",
  components: {
    TemplateModaleSite,
    InputTextSite,
    BlockMsgError,
    BinariStatus,
    ButtonSite,
  },
  props: {},
  setup() {
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const returnStore = useReturnStore();

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);

    const isPending = ref(false as boolean);

    const modelFindItem = ref(null as FindItem | null);

    const idErpItem = ref("" as string);

    return {
      isOpen,
      saveButton,
      getCustomer,
      ServiceFormValidation,
      isPending,
      modelFindItem,
      idErpItem,
      t,
      globalStore,
    };
  },

  methods: {
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    modalOpen() {
      this.globalStore.$patch({ errorMessage: null });
      this.idErpItem = "";
      this.modelFindItem = null;
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
    /**
     * fonction qui permet de rechercher un article
     */
    async findItem() {
      if (this.getCustomer) {
        this.globalStore.$patch({ errorMessage: null });
        if (!this.idErpItem) {
          const errorSite = {
            message: this.t(
              "message.return.select_return.modal_find_item.id_erp_item_required"
            ),
            type: ERROR_TYPE.GLOBAL,
          } as ErrorSite;
          this.globalStore.$patch({ errorMessage: errorSite });
          return;
        }
        this.isPending = true;
        try {
          this.modelFindItem = await ApiGestionCap.findItem(
            this.getCustomer.id,
            this.idErpItem
          );
          if (null === this.modelFindItem) {
            const errorSite = {
              message: this.t(
                "message.return.select_return.modal_find_item.id_erp_item_not_found"
              ),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
          }
        } catch (error) {
          router.push({ name: RouteName.Error });
        }

        this.isPending = false;
      }
    },
  },
});
