import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row justify-center header text-center" }
const _hoisted_2 = { class: "col-md-4" }
const _hoisted_3 = { class: "col-md-4" }
const _hoisted_4 = { class: "col-md-4" }
const _hoisted_5 = { class: "row justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createBlock(_component_TemplateModaleSite, {
    idModal: "ModalDetailOrder",
    saveButton: _ctx.saveButton,
    labelModal: 
      _ctx.$t('message.customer.customer_orders.modal_detail_order.title', {
        orderNo: _ctx.idOrder,
      })
    ,
    isOpen: _ctx.isOpen,
    onModal_close: _ctx.modalClose,
    class: "ModalDetailOrder",
    isPending: _ctx.isPending
  }, {
    modalTitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("message.customer.customer_orders.modal_detail_order.title", {
          orderNo: _ctx.idOrder,
        })), 1)
    ]),
    modalBody: _withCtx(() => [
      (_ctx.modelCustomerOrderDetail)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(
                "message.customer.customer_orders.modal_detail_order.orderNo",
                {
                  orderNo: _ctx.modelCustomerOrderDetail.orderNo,
                  date: _ctx.$d(_ctx.modelCustomerOrderDetail.formatedDateOrder, "short"),
                }
              )), 1),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(
                "message.customer.customer_orders.modal_detail_order.externalRef",
                {
                  externalRef: _ctx.modelCustomerOrderDetail.externalRef,
                }
              )), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("message.customer.customer_orders.modal_detail_order.amount", {
                amount: _ctx.$n(_ctx.modelCustomerOrderDetail.orderAmount, "price"),
              })), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_q_table, {
                ref: 'tableDetailOrder',
                rows: _ctx.rows,
                columns: _ctx.columns,
                "row-key": "id",
                class: "tableBusiness",
                "hide-pagination": "",
                "hide-bottom": "",
                "wrap-cells": "",
                "rows-per-page-options": [0],
                filter: _ctx.filter,
                "filter-method": _ctx.ServiceTable.filterData,
                separator: 'cell'
              }, {
                "top-left": _withCtx((props) => [
                  _createVNode(_component_q_btn, {
                    flat: "",
                    round: "",
                    icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
                    onClick: props.toggleFullscreen,
                    class: "q-ml-md"
                  }, null, 8, ["icon", "onClick"])
                ]),
                header: _withCtx((props) => [
                  _createVNode(_component_q_tr, { props: props }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                        return (_openBlock(), _createBlock(_component_q_th, {
                          key: col.name,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(col.noTrans ? col.label : _ctx.$t(col.label)), 1)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  _createVNode(_component_q_tr, {
                    props: props,
                    class: "searchHeader"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                        return (_openBlock(), _createBlock(_component_q_td, {
                          key: col.name,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            (_ctx.filter.hasOwnProperty(col.name))
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (col.name === 'regroupementFamily')
                                    ? (_openBlock(), _createBlock(_component_q_select, {
                                        key: 0,
                                        dense: "",
                                        denseOpts: "",
                                        clearable: "",
                                        filled: "",
                                        modelValue: _ctx.filter[col.name],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                                        options: _ctx.family
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                    : (_openBlock(), _createBlock(_component_InputTextSite, {
                                        key: 1,
                                        modelValue: _ctx.filter[col.name],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                                        dense: ""
                                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                _: 1
              }, 8, ["rows", "columns", "filter", "filter-method"])
            ])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["saveButton", "labelModal", "isOpen", "onModal_close", "isPending"]))
}