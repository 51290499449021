import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = {
  key: 1,
  class: "row justify-center"
}
const _hoisted_5 = { class: "col-md-12" }
const _hoisted_6 = {
  key: 1,
  class: "row"
}
const _hoisted_7 = { class: "col-12 blockNextStep" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCustomerData = _resolveComponent("HeaderCustomerData")!
  const _component_HeaderStepButton = _resolveComponent("HeaderStepButton")!
  const _component_ExpandReturnItemFamily = _resolveComponent("ExpandReturnItemFamily")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_HeaderSelectReturnItem = _resolveComponent("HeaderSelectReturnItem")!
  const _component_q_page_sticky = _resolveComponent("q-page-sticky")!
  const _component_q_page = _resolveComponent("q-page")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_ModalStatsDetailReturnItem = _resolveComponent("ModalStatsDetailReturnItem")!
  const _component_ModalReturnItemByFamily = _resolveComponent("ModalReturnItemByFamily")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeaderCustomerData),
      (_ctx.getHeaderStepReturn)
        ? (_openBlock(), _createBlock(_component_HeaderStepButton, {
            key: 0,
            btns: _ctx.getHeaderStepReturn
          }, null, 8, ["btns"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.select_return_order")), 1)
        ])
      ]),
      _createVNode(_component_q_layout, {
        view: "hHr LpR lFf",
        container: "",
        class: "selectReturnItem column justify-between no-wrap"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_page_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_page, { class: "blockPage" }, {
                default: _withCtx(() => [
                  (_ctx.getReturnPreco)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getReturnPreco.modelFamilyReturnItemRecommandations, (familyReturnItemRecommandations) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: familyReturnItemRecommandations.regroupementFamily,
                          class: "col-md-12 blockExpand"
                        }, [
                          _createVNode(_component_ExpandReturnItemFamily, {
                            familyReturnItemRecommandations: 
                    familyReturnItemRecommandations
                  ,
                            onOpenModalStatsDetailReturnItem: 
                    _ctx.openModalStatsDetailReturnItem
                  ,
                            onOpenModalReturnItemByFamily: _ctx.openModalReturnItemByFamily
                          }, null, 8, ["familyReturnItemRecommandations", "onOpenModalStatsDetailReturnItem", "onOpenModalReturnItemByFamily"])
                        ]))
                      }), 128))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(_component_BlockMsgError)
                        ])
                      ])),
                  _createVNode(_component_q_page_sticky, {
                    expand: true,
                    position: "top",
                    class: "z-top"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_HeaderSelectReturnItem)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.getReturnPreco)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ButtonSite, {
                onClick: _ctx.nextStep,
                typeButton: 'PASTEL_VALIDATE',
                "icon-right": 'navigate_next'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_return.btn.next_step")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ModalStatsDetailReturnItem, { ref: "ModalStatsDetailReturnItem" }, null, 512),
    _createVNode(_component_ModalReturnItemByFamily, { ref: "ModalReturnItemByFamily" }, null, 512)
  ], 64))
}