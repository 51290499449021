
import { computed, defineComponent, ref } from "vue";
import Separator from "@/components/global/Separator.vue";
import { usePendingStore } from "@/store/pendingStore";
import type { QTableProps } from "quasar";
import { useGlobalStore } from "@/store/globalStore";
import { useI18n } from "vue-i18n";
import {
  AmazonOrderBatch,
  ModelSearchAmazonOrderBatch,
  PaginateAmazonOrderBatch,
} from "@/type/Amazon/AmazonOrderBatchType";
import { ErrorGlobal, ErrorSite, ERROR_TYPE } from "@/type/Error";
import { BasePaginationQuasarTable } from "@/type/PaginationType";
import ApiGestionCap from "@/services/ApiGestionCap";
import { router } from "@/router";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { RouteName } from "@/constante/RouterConst";
import { isTokenReady } from "@baloise/vue-keycloak";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import InputFileSite from "@/components/customInput/InputFileSite.vue";
import InputDateSite from "@/components/customInput/InputDateSite.vue";
import date from 'quasar/src/utils/date.js';;
import ServiceFormValidation from "@/services/ServiceFormValidation";
import ExportsAmazon from "@/components/amazon/ExportsAmazon.vue";
import { InputDateSiteUpdateType } from "@/type/InputDateSiteUpdateType";
import ServiceFormatDate from "@/services/ServiceFormatDate";
import dialog from "@/services/DialogSite";
import ServiceMercure from "@/services/ServiceMercure";
import { MercureOrderBatchProcessing } from "@/type/Amazon/MercureOrderBatchProcessingType";
import notify from "@/services/NotifySite";
import { useUserStore } from "@/store/userStore";
import { UserSite } from "@/type/UserType";

export default defineComponent({
  name: "AmazonOrderBatchs",
  components: {
    ButtonSite,
    Separator,
    BlockMsgError,
    InputFileSite,
    InputDateSite,
    ExportsAmazon,
  },
  setup() {
    const csv = ref(null as null | File);
    const globalStore = useGlobalStore();
    const userStore = useUserStore();
    const pendingStore = usePendingStore();
    const { t } = useI18n();
    const today = new Date();
    const startDate = today.setDate(today.getDate() - 1);
    const start = ref(date.formatDate(startDate, "DD/MM/YYYY") as string);
    const end = ref(date.formatDate(new Date(), "DD/MM/YYYY") as string);

    const getUser = computed(() => {
      return userStore.getUser;
    });

    const modelSearchAmazonOrderBatch = ref(
      null as null | ModelSearchAmazonOrderBatch
    );

    const actualPage = ref(1 as number);
    const actualLimit = ref(10 as number);
    const sortBy = ref("timestampDateImport" as string);
    const descending = ref(false as boolean);
    const pagination = ref({
      sortBy: "timestampDateImport",
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0,
    });
    const loading = ref(false);
    const rows = ref([] as AmazonOrderBatch[]);
    const columns = [
      {
        name: "id",
        required: true,
        label: "message.amazon.order_batch.id",
        align: "left",
        field: (row: AmazonOrderBatch) => row.id,
        sortable: true,
      },
      {
        name: "nameUserSite",
        required: true,
        label: "message.amazon.order_batch.name",
        align: "left",
        field: (row: AmazonOrderBatch) => row.idUserSite.usernameKeycloak,
        sortable: true,
      },
      {
        name: "timestampDateImport",
        required: true,
        label: "message.amazon.order_batch.date_import",
        align: "left",
        field: (row: AmazonOrderBatch) => row.formatedDateImport,
        sortable: true,
      },
      {
        name: "timestampDateIntegrationStart",
        required: true,
        label: "message.amazon.order_batch.date_integration_start",
        align: "left",
        field: (row: AmazonOrderBatch) => row.formatedDateIntegrationStart,
        sortable: true,
      },
      {
        name: "timestampDateExportPackageShipped",
        required: true,
        label: "message.amazon.order_batch.date_export_package_shipped",
        align: "left",
        field: (row: AmazonOrderBatch) => row.formatedDateExportPackageShipped,
        sortable: true,
      },
      {
        name: "timestampDateExportPackageCurrentDelivery",
        required: true,
        label:
          "message.amazon.order_batch.date_export_package_current_delivery",
        align: "left",
        field: (row: AmazonOrderBatch) =>
          row.formatedDateExportPackageCurrentDelivery,
        sortable: true,
      },
      {
        name: "timestampDateExportPackageTntStatus",
        required: true,
        label: "message.amazon.order_batch.date_export_package_tnt_status",
        align: "left",
        field: (row: AmazonOrderBatch) =>
          row.formatedDateExportPackageTntStatus,
        sortable: true,
      },
      {
        name: "status",
        required: true,
        label: "message.amazon.order_batch.status",
        align: "center",
        sortable: false,
      },
      {
        name: "duplicate",
        required: true,
        label: "message.amazon.order_batch.duplicate",
        align: "center",
        sortable: false,
      },
    ];

    const eventOrderBatchProcessing = ref(null as null | EventSource);
    return {
      filter: ref(""),
      start,
      end,
      pendingStore,
      globalStore,
      t,
      actualPage,
      actualLimit,
      pagination,
      loading,
      sortBy,
      descending,
      modelSearchAmazonOrderBatch,
      rows,
      columns,
      csv,
      eventOrderBatchProcessing,
      getUser,
    };
  },
  async mounted() {
    const inputeDateStart = this.$refs.inputeDateStart as any;
    const inputeDateEnd = this.$refs.inputeDateEnd as any;
    inputeDateStart.setDate(this.start);
    inputeDateEnd.setDate(this.end);
    this.pendingStore.$patch({ routerIsPending: true });
    await isTokenReady();
    await this.searchOrderBatchs(null);
    this.pendingStore.$patch({ routerIsPending: false });
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
    if (this.eventOrderBatchProcessing) {
      this.eventOrderBatchProcessing.close();
    }
  },
  methods: {
    disableOrder(orderBatch: AmazonOrderBatch) {
      if (
        orderBatch.processing &&
        orderBatch.idUserSiteProcessing &&
        this.getUser
      ) {
        return (
          orderBatch.idUserSiteProcessing.usernameKeycloak !==
          this.getUser.userName
        );
      }
      return false;
    },
    subscribToOrderBatchMessage() {
      if (this.rows) {
        const idOrderBatchs = this.rows.map((orderBatch: AmazonOrderBatch) => {
          return orderBatch.id;
        });
        this.eventOrderBatchProcessing =
          ServiceMercure.subscribeOrderToValidate(idOrderBatchs);
        this.eventOrderBatchProcessing.onmessage = async (e) => {
          const mercureOrderToValidate = JSON.parse(
            e.data
          ) as MercureOrderBatchProcessing;
          this.rows.forEach((orderBatch: AmazonOrderBatch) => {
            if (orderBatch.id === mercureOrderToValidate.idOrderBatch) {
              orderBatch.processing = mercureOrderToValidate.processing;
              if (mercureOrderToValidate.username) {
                const userProcessing = {
                  usernameKeycloak: mercureOrderToValidate.username,
                } as UserSite;
                orderBatch.idUserSiteProcessing = userProcessing;
              }
            }
          });
          notify.mercure(
            this.t(mercureOrderToValidate.message, {
              idOrderBatch: mercureOrderToValidate.idOrderBatch,
              username: mercureOrderToValidate.username,
            })
          );
        };
      }
    },
    /**
     * fonction de maj de l'input date start
     */
    updateDateStart(inputDateSiteUpdateType: InputDateSiteUpdateType) {
      this.start = inputDateSiteUpdateType.newDate;
    },
    /**
     * fonction de maj de l'input date end
     */
    updateDateEnd(inputDateSiteUpdateType: InputDateSiteUpdateType) {
      this.end = inputDateSiteUpdateType.newDate;
    },
    /**
     * Ouverture de la page de gestion des reliquats status de transport
     */
    openManageShipStatusRemainder() {
      router.push({ name: RouteName.AmazonManageShppingStatusRemainder });
    },

    /**
     * Fonction qui permet d'envoyer des commandes amazon et de crée un order batch
     */
    async uploadOrders() {
      this.globalStore.$patch({ errorMessage: null });
      if (this.csv) {
        const form = new FormData();
        form.append("csv", this.csv);
        try {
          this.pendingStore.$patch({ routerIsPending: true });
          await ApiGestionCap.importOrderBatch(form);
          await this.searchOrderBatchs(null);
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
        this.pendingStore.$patch({ routerIsPending: false });
      } else {
        const errorSite = {
          message: this.t("message.amazon.order_batch.error.missing_csv"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      }
    },
    /**
     * fonction appeler par le tableau pour refrech les orders batch
     */
    onRequest(props: Parameters<NonNullable<QTableProps["onRequest"]>>[0]) {
      this.searchOrderBatchs(props.pagination);
    },
    /**
     * Fonction qui faire la recherche des order batch
     */
    async searchOrderBatchs(pagination: BasePaginationQuasarTable | null) {
      this.globalStore.$patch({ errorMessage: null });
      if (pagination) {
        const { page, rowsPerPage, sortBy, descending } = pagination;
        this.actualPage = page;
        this.actualLimit = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      } else {
        this.actualPage = 1;
      }
      if (
        null === this.start ||
        !ServiceFormValidation.checkIsDateIsFormatedFr(this.start) ||
        null === this.end ||
        !ServiceFormValidation.checkIsDateIsFormatedFr(this.end)
      ) {
        const errorSite = {
          message: this.t("message.amazon.order_batch.search.error_global"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      } else {
        if (null === pagination) {
          this.pendingStore.$patch({ routerIsPending: true });
        }
        this.loading = true;
        this.modelSearchAmazonOrderBatch = {
          start: ServiceFormatDate.formatDateToGb(this.start),
          end: ServiceFormatDate.formatDateToGb(this.end),
          page: this.actualPage,
          limit: this.actualLimit,
          sortBy: this.sortBy,
          descending: this.descending,
        };
        try {
          const response = await ApiGestionCap.findPaginateOrderBatch(
            this.modelSearchAmazonOrderBatch
          );

          if (this.isErrorGlobal(response)) {
            const errorSite = {
              message: this.t(response.message),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
          } else {
            this.rows = response.arrayPaginate;
            this.pagination.page = response.actualPage;
            this.pagination.rowsPerPage = response.nbItemsPerPage;
            this.pagination.rowsNumber = response.arraySize;
            this.pagination.sortBy = this.sortBy;
            this.pagination.descending = this.descending;
            this.subscribToOrderBatchMessage();
          }
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
        this.loading = false;
        if (null === pagination) {
          this.pendingStore.$patch({ routerIsPending: false });
        }
      }
    },
    /**
     * Suppression des orderBatch si aucune commande dans le batch
     */
    async deleteOrderBatch(idOrderbatch: number) {
      dialog
        .comfirm(
          this.t(`message.amazon.order_batch.comfirm_delete`, {
            id: idOrderbatch,
          }),
          true
        )
        .onOk(async () => {
          try {
            this.loading = true;
            await ApiGestionCap.deleteOrderBatch(idOrderbatch);
            await this.searchOrderBatchs(null);
            this.loading = false;
          } catch (error) {
            router.push({ name: RouteName.Error });
          }
        });
    },
    /**
     * Controle si l'objet est de type error global
     */
    isErrorGlobal(
      error: PaginateAmazonOrderBatch | ErrorGlobal
    ): error is ErrorGlobal {
      return (error as ErrorGlobal).message !== undefined;
    },
    /**
     * Redirection vers la vue du detail du order batch
     */
    redirectToAmazonDetailOrderBatch(amazonOrderBatch: AmazonOrderBatch) {
      router.push({
        name: RouteName.AmazonDetailOrderBatch,
        params: {
          idOrderbatch: amazonOrderBatch.id,
        },
      });
    },
    redirectToAmazonShippingStats() {
      router.push({ name: RouteName.AmazonShippingStats });
    },
  },
});
