
import ServiceFormValidation from "@/services/ServiceFormValidation";
import { defineComponent, ref } from "vue";
import {InputDateSiteUpdateType} from "@/type/InputDateSiteUpdateType";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "InputDateSite",
  emits: ["updateDate"],
  props: {
    /**
     * Prop qui permet de set la date du composant
     */
    idElement: {
      type: Number,
      required: false,
      default : null
    },
  },
  setup() {
    const { t } = useI18n();
    const dateModel = ref("" as string);
   
    return {
      t,
      dateModel,
    };
  },
  methods: {
    resetValidation(){
      const inputDate = this.$refs.inputDate as any;
      inputDate.resetValidation();
    },

    validate() : boolean{
      const inputDate = this.$refs.inputDate as any;
      let isValid = false as boolean;
      isValid = inputDate.validate();
  
      return isValid;
    },
    /**
     * Fonction qui met à jours la date + le model du parent via emit
     */
    updateDate(newDate: string) {
      
      this.dateModel = newDate;
      const objReturn = {
        idElement: this.idElement,
        newDate: newDate,
      } as InputDateSiteUpdateType;
      
      this.$emit("updateDate", objReturn);
    },
    /**
     * Fonction qui permet au parent de set la date du composant
     */
    setDate(newDate: string) {
      this.dateModel = newDate;
    },
    /**
     * Fonction qui appelle le service de controle de formulaire pour controller le format de la date
     */
    checkDate(testDate: string) {
      return (
        ServiceFormValidation.checkIsDateIsFormatedFr(testDate) ||
        this.t('message.input.date.error')
      );
    },
  },
});
