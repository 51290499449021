
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExpandGlobalReturn",
  props: {
    title: {
      type: String,
      required: true,
    },
    addHeaderLeft: {
      type: Boolean,
      default: false,
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
});
