import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row justify-center" }
const _hoisted_5 = { class: "col-md-12" }
const _hoisted_6 = { class: "row blockAction" }
const _hoisted_7 = { class: "col-md-3" }
const _hoisted_8 = {
  key: 0,
  class: "row"
}
const _hoisted_9 = { class: "col-md-12 error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_InputDateSite = _resolveComponent("InputDateSite")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.amazon_manage_shipping_remainder")), 1)
      ])
    ]),
    _createVNode(_component_Separator),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_q_table, {
          ref: 'tableAmazonOrderShipRemainder',
          rows: _ctx.orders,
          "rows-per-page-options": [10, 20, 50, 100],
          columns: _ctx.columns,
          "row-key": "id",
          class: "tableQuasar",
          "wrap-cells": "",
          "binary-state-sort": "",
          filter: _ctx.filter
        }, {
          "top-left": _withCtx((props) => [
            _createVNode(_component_q_btn, {
              flat: "",
              round: "",
              icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
              onClick: props.toggleFullscreen,
              class: "q-ml-md"
            }, null, 8, ["icon", "onClick"])
          ]),
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          "top-right": _withCtx(() => [
            _createVNode(_component_q_input, {
              outlined: "",
              modelValue: _ctx.filter,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter) = $event)),
              label: _ctx.$t('message.search.searchGlobal')
            }, {
              append: _withCtx(() => [
                (_ctx.filter !== '')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      name: "close",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filter = '')),
                      class: "cursor-pointer"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_icon, { name: "search" })
              ]),
              _: 1
            }, 8, ["modelValue", "label"])
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "Type",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.idRefClientErp.libelle), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "ShippingDate",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$d(props.row.formatedErpShippingDate, "short")), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "AmazonOrderId",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.amazonId), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "Carrier",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.idRefTypeCarrier.libelle), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "ShippingCode",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.erpFullPackingNo), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "newStatus",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_select, {
                      outlined: "",
                      modelValue: props.row.idRefAmazonShippingStatus,
                      "onUpdate:modelValue": [($event: any) => ((props.row.idRefAmazonShippingStatus) = $event), ($event: any) => (_ctx.checkInputDate(props.row))],
                      options: _ctx.amazonFinalStatus,
                      class: "qselect",
                      clearable: "",
                      "option-value": "id",
                      "option-label": "status",
                      label: 
                    _ctx.$t(
                      'message.amazon.manage_shipping_status_remainder.form.input.select_new_status.placeholder'
                    )
                  
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "label"])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "newDateStatus",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_form, {
                      ref: 'qform_' + props.row.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputDateSite, {
                          onUpdateDate: _ctx.updateDateStatus,
                          idElement: props.row.id,
                          ref: 'date_' + props.row.id
                        }, null, 8, ["onUpdateDate", "idElement"])
                      ]),
                      _: 2
                    }, 1536)
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "filter"])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ButtonSite, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveManageShipStatusRemainder())),
          typeButton: 'INFO',
          ref: "btnForm",
          disabled: _ctx.disableBtnForm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.manage_shipping_status_remainder.btn.save")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    (_ctx.formError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("message.amazon.manage_shipping_status_remainder.form.error")), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}