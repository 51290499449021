import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "bold" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = {
  key: 0,
  class: "row titleFamily"
}
const _hoisted_6 = { class: "libelle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_BinariStatus = _resolveComponent("BinariStatus")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createBlock(_component_TemplateModaleSite, {
    idModal: "ModalReturnItemByFamily",
    saveButton: _ctx.saveButton,
    labelModal: 
      _ctx.$t('message.return.select_return.modal_return_item_by_family.title')
    ,
    isOpen: _ctx.isOpen,
    onModal_close: _ctx.modalClose,
    class: "modalReturnItemByFamily",
    isPending: _ctx.isPending
  }, {
    modalTitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_return.modal_return_item_by_family.title")), 1)
    ]),
    modalBody: _withCtx(() => [
      (_ctx.rows)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(
                "message.return.select_return.modal_return_item_by_family.family"
              )), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.regroupementFamily), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_table, {
                ref: 'tableReturnItemByFamily',
                rows: _ctx.rows,
                columns: _ctx.columns,
                "row-key": "id",
                class: "tableBusiness",
                "hide-pagination": "",
                "hide-bottom": "",
                "wrap-cells": "",
                "rows-per-page-options": [0],
                filter: _ctx.filter,
                "filter-method": _ctx.ServiceTable.filterData,
                pending: _ctx.isPending,
                separator: 'cell'
              }, {
                "top-left": _withCtx((props) => [
                  _createVNode(_component_ButtonSite, {
                    flat: "",
                    round: "",
                    typeButton: 'BASE',
                    icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
                    onClick: props.toggleFullscreen
                  }, null, 8, ["icon", "onClick"]),
                  (props.inFullscreen)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.regroupementFamily), 1))
                    : _createCommentVNode("", true)
                ]),
                header: _withCtx((props) => [
                  _createVNode(_component_q_tr, { props: props }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                        return (_openBlock(), _createBlock(_component_q_th, {
                          key: col.name,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t(col.label)), 1)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["props"]),
                  _createVNode(_component_q_tr, {
                    props: props,
                    class: "searchHeader"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                        return (_openBlock(), _createBlock(_component_q_td, {
                          key: col.name,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            (_ctx.filter.hasOwnProperty(col.name))
                              ? (_openBlock(), _createBlock(_component_InputTextSite, {
                                  key: 0,
                                  modelValue: _ctx.filter[col.name],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                                  dense: ""
                                }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                body: _withCtx((props) => [
                  _createVNode(_component_q_tr, { props: props }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_td, {
                        key: "vendor",
                        class: "vendor",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.vendor), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "idErpItem",
                        class: "idErpItem",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.idErpItemFormated), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "shortRef",
                        class: "shortRef",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.shortRef), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "designation",
                        class: "designation",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.designation), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "qtyStockCustomer",
                        class: "qtyStockCustomer",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.qtyStockCustomer), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "isReturnable",
                        class: "isReturnable",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BinariStatus, {
                            status: props.row.qtyPossibleToReturn > 0 ? true : false
                          }, null, 8, ["status"])
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "qtyStockReservedCustomer",
                        class: "qtyStockReservedCustomer",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.qtyStockReservedCustomer), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "qtyStockType",
                        class: "qtyStockType",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.qtyStockType), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "historicalQuantityBuyToCap",
                        class: "historicalQuantityBuyToCap",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.historicalQuantityBuyToCap), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "qtyPossibleToReturn",
                        class: "qtyPossibleToReturn",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.qtyPossibleToReturn), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                _: 1
              }, 8, ["rows", "columns", "filter", "filter-method", "pending"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["saveButton", "labelModal", "isOpen", "onModal_close", "isPending"]))
}