import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!

  return (_openBlock(), _createBlock(_component_q_tooltip, {
    anchor: _ctx.anchor,
    self: _ctx.self,
    label: "Scale Menu",
    class: _normalizeClass(_ctx.classToolTip)
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["anchor", "self", "class"]))
}