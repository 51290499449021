/**
 * Service qui permet de formater des dates
 */
import date from 'quasar/src/utils/date.js';;

export default {
    //convertie Date en Y-m-d
    formatDate(date: Date): string {
        const d = new Date(date);
        let month = (d.getMonth() + 1).toString();
        let day = d.getDate().toString();
        const year = d.getFullYear();
        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }
        return [year, month, day].join("/");
    },

    formatDateToGb(strDate: string): string {
        return date.formatDate(
            date.extractDate(strDate, "DD/MM/YYYY"),
            "YYYY-MM-DD"
        );

    },

    //convertie 16/08/2022 , 12:18:18 en date javascript
    formatStringToDate(date: string | undefined) {
        if (undefined !== date) {
            const [dateValues, timeValues] = date.split(" , ");
            const [day, month, year] = dateValues.split("/");
            const [hours, minutes, seconds] = timeValues.split(":");
            return new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
        }
    },

    // Cette fonction prend en entrée une chaîne de caractères au format "MM/YY"
    // et renvoie un objet Date correspondant au premier jour du mois et de l'année indiqués dans la chaîne.
    ParseMonthYearString(str: string) {
        // On divise la chaîne en deux parties : le mois et l'année.
        const [month, year] = str.split('/');

        // On crée un nouvel objet Date en utilisant le constructeur de Date et en passant le mois et l'année extraits de la chaîne d'entrée, ainsi que le jour 1.
        // Notez que le mois doit être décrémenté de 1 car les mois dans les objets Date sont indexés à partir de 0 (janvier = 0, février = 1, etc.).
        const date = new Date(parseInt(`20${year}`), parseInt(month) - 1, 1);

        // On renvoie l'objet Date ainsi créé.
        return date;
    }
}
