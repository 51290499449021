
import { defineComponent, ref, toRaw } from "vue";

export default defineComponent({
  name: "InputNumberSite",
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  emits: ["updateQty"],
  setup(props) {
    const qty = ref(toRaw(props.value) as number);
    return {
      qty,
    };
  },
  methods: {
    /**
     * Permet de mettre le focus au click sur la valeur du number site 
     */
    addFocus() {
      const refQuantity = this.$refs["quantity_" + this.id] as any;
      const refQuantityElement = refQuantity.$el as HTMLInputElement;
      refQuantityElement.focus();
    },
    /**
     * Permet au parent de set la valeur du input number
     */
    setValue(quantity: number) {
      this.qty = quantity;
    },
    /**
     * Fonction qui s'applique à chaque maj du input number
     */
    updateQty() {
      let qty = 0 as number;
      if (
        typeof this.qty === "number" &&
        0 < this.qty &&
        Number.isInteger(this.qty)
      ) {
        qty = this.qty;
      } else {
        qty = 0;
        this.qty = 0;
      }
      this.$emit("updateQty", qty);
    },
  },
});
