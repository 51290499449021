/**
 * Fichier de constante des type de log de my garage
 */

const CODE_CONNECT = 'CONNECT';
const CODE_ITEM = 'ITEM';
const CODE_IMMAT = 'IMMAT';
const CODE_ORDER = 'ORDER';

const CODE_LOG = [CODE_CONNECT, CODE_ITEM, CODE_IMMAT, CODE_ORDER ];

export {CODE_LOG, CODE_CONNECT, CODE_ITEM, CODE_IMMAT, CODE_ORDER };