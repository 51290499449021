
import { useReturnStore } from "@/store/returnStore";
import { computed, defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import ApiGestionCap from "@/services/ApiGestionCap";
import { useI18n } from "vue-i18n";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import { ReturnItemRecommandation } from "@/type/Return/ReturnType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import { useGlobalStore } from "@/store/globalStore";
import BinariStatus from "@/components/global/BinariStatus.vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import ServiceTable from "@/services/ServiceTable";

export default defineComponent({
  name: "ModalReturnItemByFamily",
  components: {
    TemplateModaleSite,
    InputTextSite,
    BinariStatus,
    ButtonSite,
  },
  props: {},
  setup() {
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const returnStore = useReturnStore();

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);

    const isPending = ref(false as boolean);

    const rows = ref([] as ReturnItemRecommandation[]);

    const regroupementFamily = ref("" as string);

    const filter = ref({
      idErpItem: "" as string,
      shortRef: "" as string,
      vendor: "" as string,
      designation: "" as string,
    });

    const columns = [
      {
        name: "vendor",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.vendor",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.vendor,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "idErpItem",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.idErpItem",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.idErpItem,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "shortRef",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.shortRef",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.shortRef,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "designation",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.designation",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.designation,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "qtyStockCustomer",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.qtyStockCustomer",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyStockCustomer,
        sortable: true,
      },
      {
        name: "isReturnable",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.isReturnable",
        align: "center",
        field: (row: ReturnItemRecommandation) =>
          row.qtyPossibleToReturn > 0 ? true : false,
        sortable: true,
      },
      {
        name: "qtyStockReservedCustomer",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.qtyStockReservedCustomer",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyStockReservedCustomer,
        sortable: true,
      },
      {
        name: "qtyStockType",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.qtyStockType",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyStockType,
        sortable: true,
      },
      {
        name: "historicalQuantityBuyToCap",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.historicalQuantityBuyToCap",
        align: "center",
        field: (row: ReturnItemRecommandation) =>
          row.historicalQuantityBuyToCap,
        sortable: true,
      },
      {
        name: "qtyPossibleToReturn",
        required: true,
        label:
          "message.return.select_return.modal_return_item_by_family.table.qtyPossibleToReturn",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtyPossibleToReturn,
        sortable: true,
      },
    ];

    return {
      isOpen,
      saveButton,
      getCustomer,
      ServiceFormValidation,
      isPending,
      rows,
      regroupementFamily,
      t,
      globalStore,
      filter,
      columns,
      ServiceTable,
    };
  },

  methods: {
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    modalOpen(regroupementFamily: string) {
      this.globalStore.$patch({ errorMessage: null });
      this.regroupementFamily = regroupementFamily;
      this.rows = [];
      this.getReturnItemRecommandations();
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
    async getReturnItemRecommandations() {
      if (this.getCustomer) {
        this.isPending = true;
        try {
          this.rows = await ApiGestionCap.getReturnItemRecoByRegroupementFamily(
            this.getCustomer.id,
            this.regroupementFamily
          );
        } catch (error) {
          this.isPending = false;
          router.push({ name: RouteName.Error });
        }
        this.isPending = false;
      }
    },
  },
});
