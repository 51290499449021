
import { defineComponent, ref } from "vue";
import { useReturnStore } from "@/store/returnStore";
import { usePendingStore } from "@/store/pendingStore";
import { ModelCustomerOrder } from "@/type/Return/ReturnType";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import { useI18n } from "vue-i18n";
import ModalDetailOrder from "@/components/return/ModalDetailOrder.vue";
import { Customer } from "@/type/CustomerType";
import ServiceTable from "@/services/ServiceTable";

export default defineComponent({
  name: "TableCustomerOrders",
  components: {
    InputTextSite,
    ModalDetailOrder,
  },
  props: {
    modelCustomerOrders: {
      type: Array as () => ModelCustomerOrder[],
      required: true,
    },
    customer: {
      type: Object as () => Customer,
      required: true,
    },
  },
  setup(props) {
    const { t, d, n } = useI18n();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();

    const rows = ref(props.modelCustomerOrders as ModelCustomerOrder[]);

    const family = ref([] as string[]);

    const columns = [
      {
        name: "orderNo",
        required: true,
        label: "message.customer.customer_orders.table.orderNo",
        align: "left",
        field: (row: ModelCustomerOrder) => row.orderNo,
        sortable: true,
      },
      {
        name: "formatedDateOrder",
        required: true,
        label: "message.customer.customer_orders.table.formatedDateOrder",
        align: "center",
        field: (row: ModelCustomerOrder) => d(row.formatedDateOrder, "short"),
        sortable: true,
      },
      {
        name: "externalRef",
        required: true,
        label: "message.customer.customer_orders.table.externalRef",
        align: "left",
        field: (row: ModelCustomerOrder) => row.externalRef,
        sortable: true,
      },
      {
        name: "orderAmount",
        required: true,
        label: "message.customer.customer_orders.table.orderAmount",
        align: "center",
        field: (row: ModelCustomerOrder) => n(row.orderAmount, "price"),
        sortable: true,
      },
    ];
    const filter = ref({
      orderNo: "",
      formatedDateOrder: "",
      externalRef: "",
      orderAmount: "",
    });

    return {
      returnStore,
      pendingStore,
      rows,
      columns,
      family,
      filter,
      ServiceTable,
    };
  },
  methods: {
    openModalDetailOrder(row: ModelCustomerOrder) {
      const modalDetailOrder = this.$refs.ModalDetailOrder as any;
      if (modalDetailOrder) {
        modalDetailOrder.modalOpen(row.orderNo, this.customer.id);
      }
    },
  },
});
