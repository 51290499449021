import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row text-center items-baseline" }
const _hoisted_5 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_6 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_7 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_8 = { class: "row justify-center" }
const _hoisted_9 = { class: "col-md-12" }
const _hoisted_10 = {
  key: 0,
  class: "row"
}
const _hoisted_11 = { class: "row justify-center" }
const _hoisted_12 = { class: "col-md-12" }
const _hoisted_13 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_InputDateSite = _resolveComponent("InputDateSite")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.gestion_force_update_item")), 1)
      ])
    ]),
    _createVNode(_component_Separator),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputDateSite, {
          onUpdateDate: _ctx.updateDateStart,
          ref: "inputeDateStart"
        }, null, 8, ["onUpdateDate"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_InputDateSite, {
          onUpdateDate: _ctx.updateDateEnd,
          ref: "inputeDateEnd"
        }, null, 8, ["onUpdateDate"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ButtonSite, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.forceUpdateItem())),
          disable: _ctx.loading,
          loading: _ctx.loading,
          typeButton: 'INFO'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.my_garage.force_update_item.btn_update")), 1)
          ]),
          _: 1
        }, 8, ["disable", "loading"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_BlockMsgError)
      ])
    ]),
    _createVNode(_component_Separator),
    (_ctx.refItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("message.my_garage.force_update_item.count_updated", {
              count: _ctx.refItems.length,
            })), 1)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_q_table, {
              ref: 'tableRefItemForcedUpdated',
              rows: _ctx.refItems,
              "rows-per-page-options": [10, 20, 50, 100],
              columns: _ctx.columns,
              "row-key": "idErp",
              class: "tableQuasar",
              "wrap-cells": ""
            }, {
              "top-left": _withCtx((props) => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  round: "",
                  icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
                  onClick: props.toggleFullscreen,
                  class: "q-ml-md"
                }, null, 8, ["icon", "onClick"])
              ]),
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_th, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              loading: _withCtx(() => [
                _createVNode(_component_q_inner_loading, {
                  showing: "",
                  color: "primary"
                })
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      key: "idErp",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.idErp), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "libelle",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.libelle), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "brand",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.idRefBrand.libelle), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}