
import { defineComponent } from "vue";

export default defineComponent({
  name: "ButtonSite",
  props: {
    classIcon: {
      required: false,
      type: String,
      default: "",
    },
    addClass: {
      type: String,
      required: false,
      default: "",
    },
    typeButton: {
      type: String,
      required: false,
      default: "",
      validator: (value: string) =>
        [
          "BASE",
          "ACTION",
          "WARNING",
          "REDIRECT",
          "VALID",
          "INFO",
          "BUSINESS",
          "PASTEL_VALIDATE",
          "PASTEL_DANGER",
          "",
        ].includes(value),
    },
  },
  computed: {
    affIcon() {
      return !(0 === this.classIcon.trim().length);
    },
    classBtnSuppl() {
      return this.typeButton + " " + this.addClass;
    },
  },
});
