
import ApiGestionCap from "@/services/ApiGestionCap";
import { usePendingStore } from "@/store/pendingStore";
import {
  CustomerGroup,
  ModelCreateOrUpdateCustomerGroup,
} from "@/type/CustomerGroupType";
import { isTokenReady } from "@baloise/vue-keycloak";
import { defineComponent, ref } from "vue";
import BinariStatus from "@/components/global/BinariStatus.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import { useI18n } from "vue-i18n";
import { useGlobalStore } from "@/store/globalStore";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import QForm from 'quasar/src/components/form/QForm.js';;
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import InputTextSite from "@/components/customInput/InputTextSite.vue";

export default defineComponent({
  name: "GestionRefTypeCustomerGroup",
  components: {
    BinariStatus,
    TemplateModaleSite,
    ButtonSite,
    BlockMsgError,
    InputTextSite,
  },
  setup() {
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const pendingStore = usePendingStore();
    const customergroups = ref([] as CustomerGroup[]);

    const columns = [
      {
        name: "idErp",
        required: true,
        label: "message.customer_groups.table.idErp",
        align: "left",
        field: (row: CustomerGroup) => row.idErp,
        sortable: true,
      },
      {
        name: "libelle",
        required: true,
        label: "message.customer_groups.table.libelle",
        align: "left",
        field: (row: CustomerGroup) => row.libelle,
        sortable: true,
      },
      {
        name: "logo",
        required: true,
        label: "message.customer_groups.table.logo",
        align: "center",
        sortable: false,
      },
      {
        name: "blocked",
        required: true,
        label: "message.customer_groups.table.blocked",
        align: "center",
        field: (row: CustomerGroup) => row.blocked,
        sortable: true,
      },
    ];
    const rows = ref([] as CustomerGroup[]);
    const customerGroupSelected = ref(
      null as null | ModelCreateOrUpdateCustomerGroup
    );
    const saveButton = ref({
      label: "message.modalUpdateCustomerGroup.btn",
      affBtn: true,
    } as ModalBtnType);
    return {
      globalStore,
      t,
      customergroups,
      pendingStore,
      columns,
      rows,
      filter: ref(""),
      isOpen: ref(false),
      saveButton,
      customerGroupSelected,
    };
  },
  async mounted() {
    await isTokenReady();
    this.setCustomerGroup();
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  methods: {
    /**
     * Fonction de récupération des enseignes
     */
    async setCustomerGroup() {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        this.customergroups = await ApiGestionCap.getCustomerGroup();
        this.rows = this.customergroups;
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Initialiser le model d'enseigne de la modal pour permettre la création d'une nouvelle enseigne
     */
    createCustomerGroup() {
      this.globalStore.$patch({ errorMessage: null });
      this.customerGroupSelected = {
        id: null,
        idErp: "",
        libelle: "",
        logo: null,
        blocked: false,
      };
      this.isOpen = true;
    },
    /**
     * Initialiser le model d'enseigne de la modal avec une enseigne donnée pour permettre la mise à jour de l'enseigne
     */
    updateCustomerGroup(customerGroup: CustomerGroup) {
      this.globalStore.$patch({ errorMessage: null });
      this.customerGroupSelected = {
        id: customerGroup.id,
        idErp: customerGroup.idErp,
        libelle: customerGroup.libelle,
        logo: customerGroup.logo,
        blocked: customerGroup.blocked,
      };
      this.isOpen = true;
    },
    /**
     * Permet la création ou la mise à jours d'une enseigne
     */
    async modalSave() {
      this.globalStore.$patch({ errorMessage: null });
      const customerGroupForm = this.$refs.customerGroupForm as QForm;
      if (this.customerGroupSelected) {
        //mise à jours d'enseigne
        if (this.customerGroupSelected.id) {
          if (
            !(
              this.customerGroupSelected.idErp ||
              this.customerGroupSelected.libelle ||
              this.customerGroupSelected.blocked ||
              this.customerGroupSelected.logo
            )
          ) {
            const errorSite = {
              message: this.t("message.modalUpdateCustomerGroup.error.update"),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
            return;
          }
        }
        //création d'enseigne
        else {
          if (
            !(
              this.customerGroupSelected.idErp &&
              this.customerGroupSelected.libelle
            )
          ) {
            customerGroupForm.validate();
            const errorSite = {
              message: this.t("message.modalUpdateCustomerGroup.error.create"),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
            return;
          }
        }
        this.pendingStore.$patch({ routerIsPending: true });
        try {
          await ApiGestionCap.createOrUpdateCustomerGroup(
            this.customerGroupSelected
          );
          await this.setCustomerGroup();
          this.modalClose();
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Supprime une enseigne
     */
    async deleteCustomerGroup(id: number) {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        await ApiGestionCap.deleteCustomerGroup(id);
        await this.setCustomerGroup();
        this.modalClose();
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    modalClose() {
      this.isOpen = false;
    },
  },
});
