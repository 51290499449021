
import { defineComponent, ref } from "vue";
import Separator from "@/components/global/Separator.vue";
import { usePendingStore } from "@/store/pendingStore";
import ApiGestionCap from "@/services/ApiGestionCap";
import { isTokenReady } from "@baloise/vue-keycloak";
import { useGlobalStore } from "@/store/globalStore";
import { useI18n } from "vue-i18n";
import {
  MGUsers,
  ModelSearchUser,
  PaginateMGUsers,
} from "@/type/MyGarage/MGUsersType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import UserLog from "@/components/mygarage/UserLog.vue";
import { ErrorGlobal, ErrorSite, ERROR_TYPE } from "@/type/Error";
import type { QTableProps } from "quasar";
import { CustomerGroup } from "@/type/CustomerGroupType";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { MGRefTypeCustomerGroup } from "@/type/MyGarage/MGRefTypeCustomerGroupType";

export default defineComponent({
  name: "MGUsers",
  components: {
    Separator,
    UserLog,
    ButtonSite,
  },
  setup() {
    const pendingStore = usePendingStore();
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const columns = [
      {
        name: "username",
        required: true,
        label: "message.users_mg.table.username",
        align: "left",
        field: (row: MGUsers) => row.modelUserInfoKeycloak.username,
        sortable: true,
      },
      {
        name: "firstname",
        required: true,
        label: "message.users_mg.table.firstName",
        align: "left",
        field: (row: MGUsers) => row.modelUserInfoKeycloak.firstName,
        sortable: true,
      },
      {
        name: "lastname",
        required: true,
        label: "message.users_mg.table.lastName",
        align: "left",
        field: (row: MGUsers) => row.modelUserInfoKeycloak.lastName,
        sortable: true,
      },
      {
        name: "email",
        required: true,
        label: "message.users_mg.table.email",
        align: "left",
        field: (row: MGUsers) => row.modelUserInfoKeycloak.email,
        sortable: true,
      },
      {
        name: "idErpRefAutoCenter",
        required: true,
        label: "message.users_mg.table.idRefAutoCenterERP",
        align: "left",
        field: (row: MGUsers) => row.idRefAutoCenter.idErp,
        sortable: true,
      },
      {
        name: "nameRefAutoCenter",
        required: true,
        label: "message.users_mg.table.nameRefAutoCenter",
        align: "left",
        field: (row: MGUsers) => row.idRefAutoCenter.name,
        sortable: true,
      },
      {
        name: "nameRefTypeCustomerGroup",
        required: true,
        label: "message.users_mg.table.customerGroup",
        align: "left",
        field: (row: MGUsers) =>
          row.idRefAutoCenter.idRefTypeCustomerGroup.libelle,
        sortable: true,
      },
      {
        name: "dateLastLoginTimestamp",
        required: true,
        label: "message.users_mg.table.dateLastLogin",
        align: "left",
        field: (row: MGUsers) => row.formatedDateLastLogin,
        sortable: true,
      },
      {
        name: "accessLog",
        required: true,
        label: "message.users_mg.table.accessLog",
        align: "center",
      },
    ];
    const rows = ref([] as MGUsers[]);

    const actualPage = ref(1 as number);
    const actualLimit = ref(10 as number);
    const sortBy = ref("username" as string);
    const descending = ref(false as boolean);

    const modelSearchUser = ref(null as ModelSearchUser | null);
    const selectCustomerGroup = ref(null as null | MGRefTypeCustomerGroup);

    const pagination = ref({
      sortBy: "username",
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0,
    });

    const loading = ref(false);
    const userPaginate = ref(null as null | PaginateMGUsers);
    const customergroups = ref(null as null | MGRefTypeCustomerGroup[]);
    const idErpCustomer = ref("" as string);
    return {
      actualPage,
      actualLimit,
      sortBy,
      descending,
      modelSearchUser,
      pagination,
      loading,
      userPaginate,
      pendingStore,
      rows,
      columns,
      filter: ref(""),
      globalStore,
      customergroups,
      selectCustomerGroup,
      idErpCustomer,
      t,
    };
  },
  /**
   * Récupération des enseigne my garage à la création du components
   */
  async mounted() {
    this.pendingStore.$patch({ routerIsPending: true });
    await isTokenReady();
    try {
      this.customergroups = await ApiGestionCap.getRefCustomerGroupMG();
    } catch (error) {
      router.push({ name: RouteName.Error });
    }
    this.pendingStore.$patch({ routerIsPending: false });
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  methods: {
    /**
     * Fonction de recherche des customer my garage rafraichie par le bouton de recherche ou le tableau
     */
    async searchUsers(
      props: Parameters<NonNullable<QTableProps["onRequest"]>>[0]
    ) {
      this.globalStore.$patch({ errorMessage: null });
      if (props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination;
        this.actualPage = page;
        this.actualLimit = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      } else {
        this.actualPage = 1;
      }
      if (null === this.selectCustomerGroup && this.idErpCustomer === "") {
        const errorSite = {
          message: this.t("message.search.searchCustomer.error.emptySearch"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      } else {
        if (null === props) {
          this.pendingStore.$patch({ routerIsPending: true });
        }
        this.loading = true;
        this.modelSearchUser = {
          idErpRefAutoCenter: this.idErpCustomer,
          idRefTypeCustomerGroup: this.selectCustomerGroup
            ? this.selectCustomerGroup.id
            : null,
          page: this.actualPage,
          limit: this.actualLimit,
          sortBy: this.sortBy,
          descending: this.descending,
        };
        try {
          let response = await ApiGestionCap.findUserBy(this.modelSearchUser);
          if (this.isErrorGlobal(response)) {
            const errorSite = {
              message: this.t(response.message),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
          } else {
            this.rows = response.arrayPaginate;
            this.pagination.page = response.actualPage;
            this.pagination.rowsPerPage = response.nbItemsPerPage;
            this.pagination.rowsNumber = response.arraySize;
            this.pagination.sortBy = this.sortBy;
            this.pagination.descending = this.descending;
          }
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
        this.loading = false;
        if (null === props) {
          this.pendingStore.$patch({ routerIsPending: false });
        }
      }
    },
    /**
     * Controle si l'objet est bien de type ErrorGlobal
     */
    isErrorGlobal(error: PaginateMGUsers | ErrorGlobal): error is ErrorGlobal {
      return (error as ErrorGlobal).message !== undefined;
    },
  },
});
