import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createBlock(_component_TemplateModaleSite, {
    idModal: "modalRemoveOrder",
    labelModal: _ctx.$t('message.amazon.order.remove.modal.title'),
    isOpen: _ctx.isOpen,
    width: 'MEDIUM',
    saveButton: _ctx.saveButton,
    isPending: _ctx.isPending,
    onModal_close: _ctx.modalClose,
    onModal_save: _ctx.removeOrder
  }, _createSlots({
    modalTitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order.remove.modal.title")), 1)
    ]),
    _: 2
  }, [
    (null !== _ctx.idOrder)
      ? {
          name: "modalBody",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order.remove.modal.txt", {
          AmazonId: _ctx.idOrder.amazonId,
        })), 1)
          ])
        }
      : undefined
  ]), 1032, ["labelModal", "isOpen", "saveButton", "isPending", "onModal_close", "onModal_save"]))
}