import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_space = _resolveComponent("q-space")!
  const _component_TooltipSite = _resolveComponent("TooltipSite")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_bar = _resolveComponent("q-bar")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_PendingSpinner = _resolveComponent("PendingSpinner")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.modalIsOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalIsOpen) = $event)),
    persistent: _ctx.noCloseButton,
    maximized: _ctx.maximizedToggle,
    "transition-show": "slide-up",
    "transition-hide": "slide-down",
    id: _ctx.idModal,
    ref: _ctx.idModal,
    "full-width": _ctx.modalWidth.full,
    onShow: _ctx.onShow,
    onHide: _ctx.onHide
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        class: _normalizeClass(
        'bg-white text-back contModal ' +
        (_ctx.modalWidth.full || _ctx.maximizedToggle ? '' : _ctx.modalWidth.class) +
        ' ' +
        (_ctx.maximizedToggle ? 'maximized' : '')
      )
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_bar, {
            class: _normalizeClass([_ctx.colorClass] + ' cursor-move')
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "modalTitle"),
              _createVNode(_component_q_space),
              _createVNode(_component_q_btn, {
                dense: "",
                flat: "",
                icon: _ctx.maximizedToggle ? 'minimize' : 'crop_square',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.maximizedToggle = !_ctx.maximizedToggle))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_TooltipSite, { classToolTip: 'PRIMARY' }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default", {}, () => [
                        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.getLabelBtnSize)), 1)
                      ])
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }, 8, ["icon"]),
              (!_ctx.noCloseButton)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    dense: "",
                    flat: "",
                    icon: "close",
                    onClick: _ctx.actionClose
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TooltipSite, { classToolTip: 'PRIMARY' }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "default", {}, () => [
                            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.closeButton.label)), 1)
                          ])
                        ]),
                        _: 3
                      })
                    ]),
                    _: 3
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["class"]),
          _createVNode(_component_q_card_section, { class: "modal-body" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "modalBody")
            ]),
            _: 3
          }),
          _createVNode(_component_PendingSpinner, {
            isPending: _ctx.isPending,
            classSpinner: "spinner"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "modalPending", {}, () => [
                (_ctx.haveFooter)
                  ? (_openBlock(), _createBlock(_component_q_card_section, {
                      key: 0,
                      class: "row justify-between modal-footer"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.closeButton.affBtn)
                          ? (_openBlock(), _createBlock(_component_ButtonSite, {
                              key: 0,
                              onClick: _ctx.actionClose
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.closeButton.label)), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true),
                        (_ctx.saveButton.affBtn)
                          ? (_openBlock(), _createBlock(_component_ButtonSite, {
                              key: 1,
                              onClick: _ctx.actionSave
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.saveButton.label)), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 3
          }, 8, ["isPending"]),
          _renderSlot(_ctx.$slots, "modalAction")
        ]),
        _: 3
      }, 8, ["class"])
    ]),
    _: 3
  }, 8, ["modelValue", "persistent", "maximized", "id", "full-width", "onShow", "onHide"]))
}