
import { useReturnStore } from "@/store/returnStore";
import { ReturnItemRecommandation } from "@/type/Return/ReturnType";
import { computed, defineComponent, ref } from "vue";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import { useI18n } from "vue-i18n";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import ExpandGlobalReturn from "@/components/return/ExpandGlobalReturn.vue";
import ServiceTable from "@/services/ServiceTable";

export default defineComponent({
  name: "TableDetailItemPreco",
  components: {
    InputTextSite,
    ButtonSite,
    ExpandGlobalReturn,
  },
  props: {
    withCheckBox: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    const returnStore = useReturnStore();
    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const filter = ref({
      regroupementFamily: "" as string,
      idErpItem: "" as string,
      shortRef: "" as string,
      unitPriceHt: "" as string,
      qtySelected: "" as string,
      amount: "" as string,
    });

    const columns = [
      {
        name: "regroupementFamily",
        required: true,
        label:
          "message.return.check_return.detail_preco.table.regroupementFamily",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.regroupementFamily,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "idErpItem",
        required: true,
        label: "message.return.check_return.detail_preco.table.idErpItem",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.idErpItem,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "shortRef",
        required: true,
        label: "message.return.check_return.detail_preco.table.shortRef",
        align: "left",
        field: (row: ReturnItemRecommandation) => row.shortRef,
        sortable: true,
        classes: "fixedWidth",
      },
      {
        name: "unitPriceHt",
        required: true,
        label: "message.return.check_return.detail_preco.table.unitPriceHt",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.unitPriceHt,
        sortable: true,
      },
      {
        name: "qtySelected",
        required: true,
        label: "message.return.check_return.detail_preco.table.qtySelected",
        align: "center",
        field: (row: ReturnItemRecommandation) => row.qtySelected,
        sortable: true,
      },
      {
        name: "amount",
        required: true,
        label: "message.return.check_return.family_preco.table.amount",
        align: "center",
        field: (row: ReturnItemRecommandation) =>
          row.qtySelected * row.unitPriceHt,
        sortable: true,
      },
    ];

    const rows = computed(() => {
      return returnStore.getOnlyReturnItemRecoFilteredBySelected();
    });

    return {
      columns,
      rows,
      returnStore,
      getReturnPreco,
      filter,
      t,
      ServiceTable,
    };
  },
});
