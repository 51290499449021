
import { defineComponent, ref } from "vue";
import ApiGestionCap from "@/services/ApiGestionCap";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { MGRefFamilyItemErp } from "@/type/MyGarage/MGRefFamilyItemErp";
import { ADDMGRefFamilyItemErp } from "@/type/MyGarage/MGItemTypeErpExcluded";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";

export default defineComponent({
  name: "ModalAddItemTypeErpExcludedToCg",
  emits: ["refrechItemTypeErpExcludeds"],
  props: {
    idRefTypeCustomerGroup: {
      type: Number,
      required: true,
    },
  },
  components: {
    ButtonSite,
    TemplateModaleSite,
  },
  setup() {
    const pendingStore = usePendingStore();
    const refFamilyItemErp = ref([] as MGRefFamilyItemErp[]);
    const selectRefFamilyItemErp = ref(null as null | MGRefFamilyItemErp);
    const saveButton = ref({
      label:
        "message.gestion_customer_group_mg.item_type_erp_excluded.modal.add_send",
      affBtn: true,
    } as ModalBtnType);
    return {
      saveButton,
      refFamilyItemErp,
      selectRefFamilyItemErp,
      isOpen: ref(false),
      filter: ref(""),
      pendingStore,
    };
  },
  methods: {
    /**
     * Fonction qui va récupérer les famille de l'erp qu'il est possible d'ajouter en exlusion a l'enseigne envoyer en props idRefTypeCustomerGroup
     */
    async refrechRefFamilyItemErp() {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        this.refFamilyItemErp =
          await ApiGestionCap.getRefFamilyItemErpCanAddToCg(
            this.idRefTypeCustomerGroup
          );
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Fonction qui permet d'ajouter une famille de l'erp  d'exclusion a l'enseigne envoyer en props idRefTypeCustomerGroup
     */
    async addItemTypeErpExcluded() {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        if (this.selectRefFamilyItemErp) {
          const modelAddItemTypeErpExcluded = {
            idRefTypeCustomerGroup: this.idRefTypeCustomerGroup,
            idRefFamilyItemErp: this.selectRefFamilyItemErp.id,
          } as ADDMGRefFamilyItemErp;
          await ApiGestionCap.addItemTypeErpExcludeds(
            modelAddItemTypeErpExcluded
          );
          this.$emit("refrechItemTypeErpExcludeds");
          this.modalClose();
        }
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * fonction qui recupere les famille de l'erp disponible pour l'exclusion et qui ouvre la modal
     */
    async modalOpen() {
      await this.refrechRefFamilyItemErp();
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
  },
});
