
import { useReturnStore } from "@/store/returnStore";
import { computed, defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";

export default defineComponent({
  name: "ModalAuthorizedAmount",
  components: {
    TemplateModaleSite,
  },
  setup() {
    const returnStore = useReturnStore();
    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);

    const getMaxReturnAuthorized = computed(() => {
      return returnStore.getMaxReturnAuthorized();
    });

    const getBalanceReturnAuthorized = computed(() => {
      return returnStore.getBalanceReturnAuthorized();
    });
    return {
      isOpen,
      getMaxReturnAuthorized,
      getBalanceReturnAuthorized,
      saveButton,
    };
  },
  methods: {
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    modalOpen() {
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
  },
});
