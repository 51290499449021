
import { RouteName } from "@/constante/RouterConst";
import ApiGestionCap from "@/services/ApiGestionCap";
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { useReturnStore } from "@/store/returnStore";
import StepLineReturn from "@/components/return/StepLineReturn.vue";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import HeaderStepButton from "@/components/return/HeaderStepButton.vue";
import { usePendingStore } from "@/store/pendingStore";
import dialog from "@/services/DialogSite";
import { useI18n } from "vue-i18n";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";

export default defineComponent({
  name: "CreateReturnOrder",
  components: {
    ButtonSite,
    StepLineReturn,
    HeaderCustomerData,
    HeaderStepButton,
    BlockMsgError,
  },
  setup() {
    const { t } = useI18n();
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    const getHeaderStepReturn = computed(() => {
      return returnStore.getHeaderStepReturn;
    });

    const idCustomer = ref(null as string | null);
    const calcReturnPending = ref(false);
    const step1Status = ref(false);
    const step2Status = ref(false);
    const step3Status = ref(false);
    const step4Status = ref(false);
    const step5Status = ref(false);
    const step6Status = ref(false);
    const disable = ref(false);

    const stepLines = [
      {
        id: 1,
        title: "message.return.step1.title",
        desc: "message.return.step1.desc",
        pending: false,
        status: false,
      },
      {
        id: 2,
        title: "message.return.step2.title",
        desc: "message.return.step2.desc",
        pending: false,
        status: false,
      },
      {
        id: 3,
        title: "message.return.step3.title",
        desc: "message.return.step3.desc",
        pending: false,
        status: false,
      },
      {
        id: 4,
        title: "message.return.step4.title",
        desc: "message.return.step4.desc",
        pending: false,
        status: false,
      },
      {
        id: 5,
        title: "message.return.step5.title",
        desc: "message.return.step5.desc",
        pending: false,
        status: false,
      },
      {
        id: 6,
        title: "message.return.step6.title",
        desc: "message.return.step6.desc",
        pending: false,
        status: false,
      },
    ];

    const errors = ref([
      {
        id: 2,
        errorMsg: "message.return.step2.error",
        status: false,
      },
    ]);
    return {
      getCustomer,
      getReturnData,
      idCustomer,
      returnStore,
      calcReturnPending,
      globalStore,
      step1Status,
      step2Status,
      step3Status,
      step4Status,
      step5Status,
      step6Status,
      stepLines,
      getHeaderStepReturn,
      pendingStore,
      errors,
      t,
      disable,
    };
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  async mounted() {
    await isTokenReady();
    this.pendingStore.$patch({ routerIsPending: true });
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: RouteName.Customers });
    }
    await this.checkIsCustomerHaveStock(this.idCustomer);
    await this.returnStore.initReturn(this.idCustomer);
    this.refreshStatus();
    this.returnStore.initHeaderStepReturn();
    this.pendingStore.$patch({ routerIsPending: false });
    //redirection si les données du customer et du retour sont dans le store et pas de paramètre dans l'url force à true
    const force = this.$route.params.force as string;
    if (this.getCustomer && this.step6Status && force !== "true") {
      this.$router.push({
        name: RouteName.SelectReturnItem,
        params: { idCustomer: this.getCustomer.id },
      });
    }
  },
  methods: {
    async checkIsCustomerHaveStock(idCustomer: string) {
      try {
        await ApiGestionCap.checkIsCustomerHaveStock(idCustomer);
      } catch (error) {
        this.disable = true;
        const errorSite = {
          message: this.t("message.return.error.no_stock"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      }
    },
    refreshStatus() {
      if (this.getCustomer && this.getReturnData) {
        this.step1Status = true;
        this.step2Status = this.getReturnData.statusGetStock;
        this.step3Status = this.getReturnData.statusGetPurchase;
        this.step4Status = this.getReturnData.statusGetSales;
        this.step5Status = this.getReturnData.statusGetEtai;
        this.step6Status = this.getReturnData.statusCalcPreco;
      } else {
        this.step1Status = false;
        this.step2Status = false;
        this.step3Status = false;
        this.step4Status = false;
        this.step5Status = false;
        this.step6Status = false;
      }
      //mise à jours de component StepLineReturn
      this.updateComponentStepLineStatus(1, this.step1Status);
      this.updateComponentStepLineStatus(2, this.step2Status);
      this.updateComponentStepLineStatus(3, this.step3Status);
      this.updateComponentStepLineStatus(4, this.step4Status);
      this.updateComponentStepLineStatus(5, this.step5Status);
      this.updateComponentStepLineStatus(6, this.step6Status);
      this.returnStore.initHeaderStepReturn();
    },
    updateComponentStepLineStatus(id: number, status: boolean) {
      const stepLineReturn = this.getStepLineComponent(id);
      if (stepLineReturn) {
        stepLineReturn.updateStatus(status);
      }
    },
    updateComponentStepLinePending(id: number, pending: boolean) {
      const stepLineReturn = this.getStepLineComponent(id);
      if (stepLineReturn) {
        stepLineReturn.updatePending(pending);
      }
    },
    getStepLineComponent(id: number) {
      //récupération du component StepLineReturn
      const stepLineReturn = this.$refs["stepLine_" + id] as any;
      return stepLineReturn[0];
    },

    //calcReturn
    async calcReturn() {
      if (this.getCustomer) {
        this.calcReturnPending = true;
        await this.step1();
        const step2Success = await this.step2();
        if (step2Success) {
          await this.step3();
          await this.step4();
          await this.step5();
          await this.step6();
          if (
            this.step1Status &&
            this.step2Status &&
            this.step3Status &&
            this.step4Status &&
            this.step5Status &&
            this.step6Status
          ) {
            //récupération des données du retour + stockage dans le store + redirection
            await this.refreshReturnData(true);
            this.refreshStatus();
            this.returnStore.initHeaderStepReturn();
            this.calcReturnPending = false;
          }
        }
      }
    },
    //récupération des données du retour
    async refreshReturnData(redirect: boolean) {
      if (this.getCustomer) {
        await this.returnStore.refreshReturnData();
        if (this.getReturnData && redirect) {
          this.$router.push({
            name: RouteName.SelectReturnItem,
            params: { idCustomer: this.getCustomer.id },
          });
        }
      }
    },

    //création du retour
    async step1() {
      if (this.getCustomer && !this.getReturnData && !this.step1Status) {
        this.updateComponentStepLinePending(1, true);
        try {
          const ModelCreateReturn = {
            idErpCustomer: this.getCustomer.id,
            idRefTypeReturn: 2,
          };
          const returnOrder = await ApiGestionCap.step1CreateReturn(
            ModelCreateReturn
          );
          this.step1Status = returnOrder.success;
          //mise à jours de component StepLineReturn
          this.updateComponentStepLineStatus(1, this.step1Status);
          this.updateComponentStepLinePending(1, false);
        } catch (error) {
          this.$router.push({ name: RouteName.Error });
        }
      }
    },
    //récupération des stocks
    async step2() {
      if (
        this.getCustomer &&
        this.step1Status &&
        !this.getReturnData?.statusGetStock &&
        !this.step2Status
      ) {
        this.updateComponentStepLinePending(2, true);
        try {
          const returnOrder = await ApiGestionCap.step2GetStock(
            this.getCustomer.id
          );
          this.step2Status = returnOrder.success;
          //mise à jours de component StepLineReturn
          this.updateComponentStepLineStatus(2, this.step2Status);
          this.updateComponentStepLinePending(2, false);
        } catch (error) {
          this.updateComponentStepLineStatus(2, false);
          this.updateComponentStepLinePending(2, false);
          const step2Error = this.errors.find((e) => e.id == 2);
          if (step2Error) {
            step2Error.status = true;
            const errorSite = {
              message: this.t(step2Error.errorMsg),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
          }
          this.calcReturnPending = false;
        }
        return true;
      }
    },
    //récupération des achats
    async step3() {
      if (
        this.getCustomer &&
        this.step2Status &&
        !this.getReturnData?.statusGetPurchase &&
        !this.step3Status
      ) {
        this.updateComponentStepLinePending(3, true);
        try {
          const returnOrder = await ApiGestionCap.step3GetPurchase(
            this.getCustomer.id
          );
          this.step3Status = returnOrder.success;
          //mise à jours de component StepLineReturn
          this.updateComponentStepLineStatus(3, this.step3Status);
          this.updateComponentStepLinePending(3, false);
        } catch (error) {
          this.$router.push({ name: RouteName.Error });
        }
      }
    },
    //récupération des ventes
    async step4() {
      if (
        this.getCustomer &&
        this.step3Status &&
        !this.getReturnData?.statusGetSales &&
        !this.step4Status
      ) {
        this.updateComponentStepLinePending(4, true);
        try {
          const returnOrder = await ApiGestionCap.step4GetSales(
            this.getCustomer.id
          );
          this.step4Status = returnOrder.success;
          //mise à jours de component StepLineReturn
          this.updateComponentStepLineStatus(4, this.step4Status);
          this.updateComponentStepLinePending(4, false);
        } catch (error) {
          this.$router.push({ name: RouteName.Error });
        }
      }
    },
    //récupération des info etai
    async step5() {
      if (
        this.getCustomer &&
        this.step4Status &&
        !this.getReturnData?.statusGetEtai &&
        !this.step5Status
      ) {
        this.updateComponentStepLinePending(5, true);
        try {
          const returnOrder = await ApiGestionCap.step5GetEtai(
            this.getCustomer.id
          );
          this.step5Status = returnOrder.success;
          //mise à jours de component StepLineReturn
          this.updateComponentStepLineStatus(5, this.step5Status);
          this.updateComponentStepLinePending(5, false);
        } catch (error) {
          this.$router.push({ name: RouteName.Error });
        }
      }
    },
    //génération de la préconisation
    async step6() {
      if (
        this.getCustomer &&
        this.step5Status &&
        !this.getReturnData?.statusCalcPreco &&
        !this.step6Status
      ) {
        this.updateComponentStepLinePending(6, true);
        try {
          const returnOrder = await ApiGestionCap.step6CalcPreco(
            this.getCustomer.id
          );
          this.step6Status = returnOrder.success;
          //mise à jours de component StepLineReturn
          this.updateComponentStepLineStatus(6, this.step6Status);
          this.updateComponentStepLinePending(6, false);
        } catch (error) {
          this.$router.push({ name: RouteName.Error });
        }
      }
    },
    async resetReturn() {
      dialog
        .comfirm(this.t(`message.return.ask_refresh_return`), true)
        .onOk(async () => {
          await this.returnStore.resetReturn(true);
          this.refreshStatus();
          if (this.getCustomer) {
            await this.calcReturn();
          }
        });
    },
    isReturnStarted() {
      return this.step1Status;
    },
    isFinalStep() {
      return (
        this.step1Status &&
        this.step2Status &&
        this.step3Status &&
        this.step4Status &&
        this.step5Status &&
        this.step6Status
      );
    },
    getLabelBtnCalc() {
      return "message.return.btn.calc_return";
    },
    getLabelBtnReset() {
      return "message.return.btn.reset_return";
    },
    getStatusError(id: number) {
      const error = this.errors.find((e) => e.id == id);
      if (error) {
        return error.status;
      }
      return false;
    },
  },
});
