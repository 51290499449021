import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-613eb544"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "isPending" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_spinner_hourglass = _resolveComponent("q-spinner-hourglass")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isPending)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.getshowSpinner)
            ? (_openBlock(), _createBlock(_component_q_spinner_hourglass, {
                key: 0,
                class: _normalizeClass([_ctx.classSpinner ? _ctx.classSpinner : '', "spinner"]),
                color: _ctx.color,
                size: _ctx.size
              }, null, 8, ["class", "color", "size"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
  ]))
}