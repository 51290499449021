import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { i18n } from './translations'
import { vueKeycloak } from '@baloise/vue-keycloak'
import "./registerServiceWorker"

import { createPinia } from 'pinia'
const pinia = createPinia()

// import du bundle assets cap performance
import "../CAP_VUE_BUNDLE/capAssetsBundle/main.scss"

import "./assets/scss/main.scss"
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';

import 'quasar/dist/quasar.css'
import lang from 'quasar/lang/fr.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'

const app = createApp(App).use(Quasar, {
    config: {
        brand: {
            primary: '#005691',
            secondary: '#008ECF',
            accent: '#d0e0ee',
            dark: '#333333',
            positive: '#33cc33',
            negative: '#ff0033',
            info: '#008ECF',
            warning: '#ffcc33'
        }
    },
    plugins: {
        Notify,
        Dialog
    },
    extras: [
        'material-icons',
        'material-icons-outlined',
        'material-icons-round',
        'material-icons-sharp'
    ],
    lang: lang
})

app.use(vueKeycloak, async () => {
    return {
        config: {
            url: process.env.VUE_APP_KEYCLOAK_URL,
            realm: process.env.VUE_APP_KEYCLOAK_REALM,
            clientId: process.env.VUE_APP_KEYCLOAK_CLIENT,
        },
        initOptions: {
            onLoad: 'login-required',
        },
    }
})
export default app

i18n.global.warnHtmlInMessage = 'off';


app.use(router).use(VueAxios, axios).use(i18n).use(pinia).mount('#app')
