
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import HeaderStepButton from "@/components/return/HeaderStepButton.vue";
import { useReturnStore } from "@/store/returnStore";
import { RouteName } from "@/constante/RouterConst";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import { useI18n } from "vue-i18n";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import TableModelDetailByFamily from "@/components/return/TableModelDetailByFamily.vue";
import TableDetailItemPreco from "@/components/return/TableDetailItemPreco.vue";
import CustomerSheet from "@/components/customers/CustomerSheet.vue";
import ApiGestionCap from "@/services/ApiGestionCap";
import { router } from "@/router";
import dialog from "@/services/DialogSite";

export default defineComponent({
  name: "ReturnValidation",
  components: {
    HeaderCustomerData,
    HeaderStepButton,
    TableModelDetailByFamily,
    TableDetailItemPreco,
    CustomerSheet,
    ButtonSite,
  },
  setup() {
    const { t } = useI18n();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    const getHeaderStepReturn = computed(() => {
      return returnStore.getHeaderStepReturn;
    });

    const idCustomer = ref(null as string | null);

    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const getTotalQtySelected = computed(() => {
      return returnStore.getTotalQtySelected();
    });

    const isPending = ref(false);

    return {
      getCustomer,
      getReturnData,
      getHeaderStepReturn,
      getReturnPreco,
      idCustomer,
      returnStore,
      pendingStore,
      t,
      ServiceFormValidation,
      isPending,
      getTotalQtySelected,
    };
  },
  async mounted() {
    await isTokenReady();
    this.pendingStore.$patch({ routerIsPending: true });
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: RouteName.Customers });
    }
    await this.returnStore.initReturn(this.idCustomer);

    if (!this.getReturnData) {
      this.$router.push({
        name: RouteName.CreateReturnOrder,
        params: { idCustomer: this.idCustomer },
      });
    }

    if (
      this.getReturnData &&
      this.getReturnData.allStatusStepOk
    ) {
      //récupération de la préco dans le store
      await this.returnStore.refreshReturnPreco();
    }

    this.pendingStore.$patch({ routerIsPending: false });
    this.returnStore.initHeaderStepReturn();
    if(this.getCustomer && this.getTotalQtySelected === 0){
      this.$router.push({
        name: RouteName.SelectReturnItem,
        params: { idCustomer: this.getCustomer.id },
      });
    }
  },
  methods: {
    async validateReturn() {
      if (this.getReturnData && this.getCustomer) {
        this.isPending = true;
        try {
          const modelReturnOrderErp = await ApiGestionCap.validReturn(
            this.getReturnData.id,
            this.getCustomer.id
          );
          dialog.infoReturn(
            this.t(
              "message.return.return_validation.message_return_validation_success", {
                returnOrderNumber: modelReturnOrderErp.idErpReturnOrder,
              }
            )
          ).onOk(() => {
            this.returnStore.resetReturn(false);
            this.$router.push({
              name: RouteName.Customers
            });
          });
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
        this.isPending = false;
      }
    },
    redirectToSelectShipping() {
      this.$router.push({
        name: RouteName.SelectShipping,
        params: { idCustomer: this.idCustomer },
      });
    },
  },
});
