import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "libelle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createBlock(_component_TemplateModaleSite, {
    idModal: "ModalReturnItemError",
    saveButton: _ctx.saveButton,
    labelModal: 
      _ctx.$t('message.return.select_return.modal_return_item_error.title')
    ,
    isOpen: _ctx.isOpen,
    onModal_close: _ctx.modalClose,
    class: "modalReturnItemError",
    isPending: _ctx.isPending
  }, {
    modalTitle: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_return.modal_return_item_error.title")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createVNode(_component_q_table, {
        ref: 'tableBusiness',
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        class: "tableBusiness",
        "hide-pagination": "",
        "hide-bottom": "",
        "wrap-cells": "",
        "rows-per-page-options": [0],
        filter: _ctx.filter,
        "filter-method": _ctx.filterData,
        separator: 'cell'
      }, {
        "top-left": _withCtx((props) => [
          _createVNode(_component_ButtonSite, {
            flat: "",
            round: "",
            typeButton: 'BASE',
            icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
            onClick: props.toggleFullscreen
          }, null, 8, ["icon", "onClick"])
        ]),
        header: _withCtx((props) => [
          _createVNode(_component_q_tr, { props: props }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                return (_openBlock(), _createBlock(_component_q_th, {
                  key: col.name,
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(col.label)), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_tr, {
            props: props,
            class: "searchHeader"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                return (_openBlock(), _createBlock(_component_q_td, {
                  key: col.name,
                  props: props
                }, {
                  default: _withCtx(() => [
                    (_ctx.filter.hasOwnProperty(col.name))
                      ? (_openBlock(), _createBlock(_component_InputTextSite, {
                          key: 0,
                          modelValue: _ctx.filter[col.name],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                          dense: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_td, {
                key: "customerItemRef",
                class: "customerItemRef",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.customerItemRef), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "brandItemRef",
                class: "brandItemRef",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.idErpItem), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "idErpItem",
                class: "idErpItem",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.idErpItemFormated), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "codeEan",
                class: "codeEan",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.codeEan), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "customerStockQty",
                class: "customerStockQty",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.customerStockQty), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "errorEan",
                class: "errorEan",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.errorEan
                  ? _ctx.$t(
                      "message.return.select_return.modal_return_item_error.table.errorEan"
                    )
                  : _ctx.$t(
                      "message.return.select_return.modal_return_item_error.table.errorRef"
                    )), 1)
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "filter-method"])
    ]),
    _: 1
  }, 8, ["saveButton", "labelModal", "isOpen", "onModal_close", "isPending"]))
}