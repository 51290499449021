import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 col-xs-12 blockInfoOrderBach" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-md-6 col-sm-12 col-xs-12 text-right blockActionOrderBatch" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = {
  key: 0,
  class: "row blockAlertProcessing"
}
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "alertProcessing" }
const _hoisted_17 = {
  key: 1,
  class: "row blockAlertFinalCheck justify-center"
}
const _hoisted_18 = { class: "alertFinalCheckTitle" }
const _hoisted_19 = { class: "alertFinalCheck" }
const _hoisted_20 = {
  key: 2,
  class: "row blockBtnRelaunchFinalCheck"
}
const _hoisted_21 = { class: "col-12 text-center" }
const _hoisted_22 = { class: "row blockProgressBar" }
const _hoisted_23 = { class: "col-12" }
const _hoisted_24 = { class: "absolute-full flex flex-center" }
const _hoisted_25 = {
  key: 3,
  class: "row text-center"
}
const _hoisted_26 = { class: "col-12" }
const _hoisted_27 = { class: "progressInfo" }
const _hoisted_28 = { key: 4 }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-12" }
const _hoisted_31 = {
  key: 2,
  class: "row"
}
const _hoisted_32 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_q_spinner_dots = _resolveComponent("q-spinner-dots")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_TableOrder = _resolveComponent("TableOrder")!
  const _component_ModelUpdateOrder = _resolveComponent("ModelUpdateOrder")!
  const _component_ModalRemoveOrder = _resolveComponent("ModalRemoveOrder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.amazon_orders_detail")), 1)
        ])
      ]),
      _createVNode(_component_Separator),
      (_ctx.orderBatch && 0 < _ctx.orders.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("message.amazon.order_batch.number", {
                  idOrderBatch: _ctx.orderBatch.id,
                })), 1)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("message.amazon.order_batch.import_info", {
                  date: _ctx.$d(_ctx.orderBatch.formatedDateImport, "long", "fr"),
                  name: _ctx.orderBatch.idUserSite.usernameKeycloak,
                })), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                (_ctx.countIntegratedOrder !== _ctx.orderBatch.ordersCount)
                  ? (_openBlock(), _createBlock(_component_ButtonSite, {
                      key: 0,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.importOrders())),
                      loading: _ctx.importInProgress || _ctx.disableOrder(),
                      disable: _ctx.importInProgress || _ctx.disableOrder(),
                      typeButton: 'ACTION'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order_batch.import_erp")), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disable"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", {
                    class: "col-12 blockInfoTable",
                    innerHTML: _ctx.$t('message.amazon.order_batch.info_table')
                  }, null, 8, _hoisted_13)
                ])
              ])
            ]),
            (_ctx.disableOrder())
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("message.amazon.order_batch.alert_processing", {
                username: _ctx.orderBatch.idUserSiteProcessing.usernameKeycloak,
              })), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.arrayModelDoubleWebOrderId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("message.amazon.order_batch.alert_final_check.title")), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrayModelDoubleWebOrderId, (modelDoubleWebOrderId, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-12 text-center",
                      key: index
                    }, [
                      _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("message.amazon.order_batch.alert_final_check.content", {
                erpOrderNo: modelDoubleWebOrderId.erpOrderNo,
                webOrderId: modelDoubleWebOrderId.webOrderId,
                nbIdenticalOrder: modelDoubleWebOrderId.nbIdenticalOrder,
              })), 1)
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.countIntegratedOrder === _ctx.orderBatch.ordersCount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_ButtonSite, {
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.sendFinalCheck())),
                      loading: _ctx.sendFinalCheckPending,
                      disable: _ctx.sendFinalCheckPending,
                      typeButton: 'ACTION'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order_batch.alert_final_check.btn")), 1)
                      ]),
                      _: 1
                    }, 8, ["loading", "disable"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_q_linear_progress, {
                  color: 
              _ctx.countIntegratedOrder === _ctx.orderBatch.ordersCount
                ? 'positive'
                : 'warning'
            ,
                  size: "30px",
                  value: _ctx.countIntegratedOrder / _ctx.orderBatch.ordersCount
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_24, [
                      _createVNode(_component_q_badge, {
                        color: "white",
                        "text-color": "primary",
                        label: _ctx.countIntegratedOrder + '/' + _ctx.orderBatch.ordersCount
                      }, null, 8, ["label"])
                    ])
                  ]),
                  _: 1
                }, 8, ["color", "value"])
              ])
            ]),
            (_ctx.importInProgress)
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order_batch.import_in_progess")) + " ", 1),
                      _createVNode(_component_q_spinner_dots)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            (0 < _ctx.orderBatch.orderDuplicates.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createVNode(_component_q_expansion_item, {
                    "expand-separator": "",
                    label: 
            _ctx.$t('message.amazon.order_batch.order_duplicate', {
              count: _ctx.orderBatch.duplicateOrdersCount,
            })
          ,
                    class: "orderDuplicate"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderBatch.orderDuplicates, (orderDuplicate, index) => {
                        return (_openBlock(), _createBlock(_component_q_card, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_card_section, { class: "card" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(orderDuplicate.amazonId), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (0 < _ctx.orderBatchError.length)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_TableOrder, {
                  orders: _ctx.orderBatchError,
                  onRefreshOrderBatch: _ctx.refreshOrderBatch,
                  onOpenModal: _ctx.openModalUpdateOrder,
                  onOpenModalRemoveOrder: _ctx.openModalRemoveOrder
                }, null, 8, ["orders", "onRefreshOrderBatch", "onOpenModal", "onOpenModalRemoveOrder"])
              ])
            ]),
            _createVNode(_component_Separator)
          ], 64))
        : _createCommentVNode("", true),
      (0 < _ctx.orderBatchValid.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createVNode(_component_TableOrder, {
                orders: _ctx.orderBatchValid,
                onRefreshOrderBatch: _ctx.refreshOrderBatch,
                onOpenModal: _ctx.openModalUpdateOrder,
                onOpenModalRemoveOrder: _ctx.openModalRemoveOrder,
                ref: "TableOrderBatchValid"
              }, null, 8, ["orders", "onRefreshOrderBatch", "onOpenModal", "onOpenModalRemoveOrder"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ModelUpdateOrder, {
      onUpdateOrder: _ctx.updateArrayOrders,
      ref: "ModalUpdateOrder"
    }, null, 8, ["onUpdateOrder"]),
    _createVNode(_component_ModalRemoveOrder, {
      onRemoveOrder: _ctx.removeFromArrayOrders,
      ref: "ModalRemoveOrder"
    }, null, 8, ["onRemoveOrder"])
  ], 64))
}