
import { usePendingStore } from "@/store/pendingStore";
import { ExcludedGlobal } from "@/type/MyGarage/MGExcludedItemType";
import { defineComponent, ref } from "vue";
import ExcludedTable from "@/components/mygarage/ExcludedTable.vue";
import Separator from "@/components/global/Separator.vue";
import { CODE_ITC, CODE_PGC, CODE_RF } from "@/constante/ExcludedTypeConst";
import { useGlobalStore } from "@/store/globalStore";

export default defineComponent({
  name: "MGExcludedItem",
  components: {
    ExcludedTable,
    Separator,
  },
  setup() {
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const excludedItc = ref([] as ExcludedGlobal[]);
    const excludedPgc = ref([] as ExcludedGlobal[]);
    const excludedRf = ref([] as ExcludedGlobal[]);
    const itcName = CODE_ITC;
    const pgcName = CODE_PGC;
    const rfName = CODE_RF;
    const tab = ref(itcName as string);
    return {
      globalStore,
      itcName,
      pgcName,
      rfName,
      pendingStore,
      excludedItc,
      excludedPgc,
      excludedRf,
      tab,
      splitterModel: ref(20),
    };
  },
  unmounted(){
    this.globalStore.$patch({ errorMessage: null });
  },
});
