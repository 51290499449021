import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "libelle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_ModalDetailOrder = _resolveComponent("ModalDetailOrder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_table, {
      ref: 'tableCustomerOrders',
      rows: _ctx.rows,
      columns: _ctx.columns,
      "row-key": "id",
      class: "tableBusiness",
      "hide-pagination": "",
      "hide-bottom": "",
      "wrap-cells": "",
      "rows-per-page-options": [0],
      filter: _ctx.filter,
      "filter-method": _ctx.ServiceTable.filterData,
      separator: 'cell'
    }, {
      "top-left": _withCtx((props) => [
        _createVNode(_component_q_btn, {
          flat: "",
          round: "",
          icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
          onClick: props.toggleFullscreen,
          class: "q-ml-md"
        }, null, 8, ["icon", "onClick"])
      ]),
      header: _withCtx((props) => [
        _createVNode(_component_q_tr, { props: props }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
              return (_openBlock(), _createBlock(_component_q_th, {
                key: col.name,
                props: props
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(col.noTrans ? col.label : _ctx.$t(col.label)), 1)
                ]),
                _: 2
              }, 1032, ["props"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["props"]),
        _createVNode(_component_q_tr, { props: props }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
              return (_openBlock(), _createBlock(_component_q_th, {
                key: col.name,
                props: props
              }, {
                default: _withCtx(() => [
                  (_ctx.filter.hasOwnProperty(col.name))
                    ? (_openBlock(), _createBlock(_component_InputTextSite, {
                        key: 0,
                        modelValue: _ctx.filter[col.name],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                        dense: ""
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["props"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, {
          props: props,
          onClick: ($event: any) => (_ctx.openModalDetailOrder(props.row)),
          class: "selectedRow"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
              return (_openBlock(), _createBlock(_component_q_td, {
                key: col.name,
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(col.field(props.row)), 1)
                ]),
                _: 2
              }, 1032, ["props"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["props", "onClick"])
      ]),
      _: 1
    }, 8, ["rows", "columns", "filter", "filter-method"]),
    _createVNode(_component_ModalDetailOrder, { ref: "ModalDetailOrder" }, null, 512)
  ], 64))
}