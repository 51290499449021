import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row justify-center headerStepButton text-center"
}
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_btn_group = _resolveComponent("q-btn-group")!

  return (_ctx.btns)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_btn_group, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btns, (btn) => {
              return (_openBlock(), _createBlock(_component_q_btn, {
                key: btn.id,
                class: _normalizeClass(_ctx.isActualRoute(btn) ? 'selected' : ''),
                onClick: ($event: any) => (_ctx.redirect(btn)),
                ripple: false
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_badge, {
                    class: "badge",
                    rounded: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(btn.id), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(btn.label)), 1)
                ]),
                _: 2
              }, 1032, ["class", "onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}