import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_file = _resolveComponent("q-file")!

  return (_openBlock(), _createBlock(_component_q_file, {
    square: "",
    outline: "",
    clearable: ""
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, { name: "cloud_upload" })
    ]),
    _: 1
  }))
}