
import ApiGestionCap from "@/services/ApiGestionCap";
import { Customer } from "@/type/CustomerType";
import { ModelCustomerOrderCa } from "@/type/Return/ReturnType";
import { defineComponent, ref } from "vue";
import BlockCustomerOrderCa from "@/components/return/BlockCustomerOrderCa.vue";

export default defineComponent({
  name: "SearchCustomers",
  props: {
    customer: {
      type: Object as () => Customer,
      required: true,
    },
  },
  components: {
    BlockCustomerOrderCa,
  },
  setup() {
    const modelCustomerOrderCa = ref(null as null | ModelCustomerOrderCa);
    return { modelCustomerOrderCa };
  },
  async mounted() {
    const customer = this.customer;
    const modelCustomerOrderCa = await ApiGestionCap.getOrderCa(customer.id);
    this.modelCustomerOrderCa = modelCustomerOrderCa;

  },
});
