
import { RouteName } from "@/constante/RouterConst";
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import CustomerSheet from "@/components/customers/CustomerSheet.vue";
import { useReturnStore } from "@/store/returnStore";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import { usePendingStore } from "@/store/pendingStore";

export default defineComponent({
  name: "Customer",
  components: {
    CustomerSheet,
    HeaderCustomerData,
  },
  setup() {
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const idCustomer = ref(null as string | null);

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    return {
      returnStore,
      idCustomer,
      pendingStore,
      getCustomer,
    };
  },
  async mounted() {
    this.pendingStore.$patch({ routerIsPending: true });
    await isTokenReady();
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: "Customers" });
    }
    //récupération des données du customer
    try {
      this.returnStore.refreshCustomer(this.idCustomer);
    } catch (error) {
      this.pendingStore.$patch({ routerIsPending: false });
      this.$router.push({ name: RouteName.Error });
    }
    this.pendingStore.$patch({ routerIsPending: false });
  },
  methods: {
    redirectToCreateReturnOrder() {
      this.$router.push({
        name: RouteName.CreateReturnOrder,
        params: { idCustomer: this.idCustomer },
      });
    },
  },
});
