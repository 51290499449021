
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import HeaderStepButton from "@/components/return/HeaderStepButton.vue";
import { useReturnStore } from "@/store/returnStore";
import { RouteName } from "@/constante/RouterConst";
import ExpandReturnItemFamily from "@/components/return/ExpandReturnItemFamily.vue";
import HeaderSelectReturnItem from "@/components/return/HeaderSelectReturnItem.vue";
import ModalStatsDetailReturnItem from "@/components/return/ModalStatsDetailReturnItem.vue";
import ModalReturnItemByFamily from "@/components/return/ModalReturnItemByFamily.vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import ApiGestionCap from "@/services/ApiGestionCap";
import dialog from "@/services/DialogSite";
import { useI18n } from "vue-i18n";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";
import BlockMsgError from "@/components/global/BlockMsgError.vue";

export default defineComponent({
  name: "CreateReturnOrder",
  components: {
    HeaderCustomerData,
    HeaderStepButton,
    ExpandReturnItemFamily,
    HeaderSelectReturnItem,
    ModalStatsDetailReturnItem,
    ModalReturnItemByFamily,
    ButtonSite,
    BlockMsgError,
  },
  setup() {
    const { t } = useI18n();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const globalStore = useGlobalStore();
    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    const getHeaderStepReturn = computed(() => {
      return returnStore.getHeaderStepReturn;
    });

    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const getTotalQtySelected = computed(() => {
      return returnStore.getTotalQtySelected();
    });

    const idCustomer = ref(null as string | null);
    return {
      t,
      getCustomer,
      getReturnData,
      getHeaderStepReturn,
      idCustomer,
      returnStore,
      globalStore,
      getReturnPreco,
      pendingStore,
      getTotalQtySelected,
    };
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  async mounted() {
    await isTokenReady();
    this.pendingStore.$patch({ routerIsPending: true });
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: RouteName.Customers });
    }
    await this.returnStore.initReturn(this.idCustomer);
    if (!this.getReturnData) {
      this.$router.push({
        name: RouteName.CreateReturnOrder,
        params: { idCustomer: this.idCustomer },
      });
    }
    if (this.getReturnData && this.getReturnData.allStatusStepOk) {
      //récupération de la préco dans le store
      await this.returnStore.refreshReturnPreco();
      if (this.getReturnPreco === null) {
        const errorSite = {
          message: this.t("message.return.select_return.error.no_preco"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    }
    this.returnStore.initHeaderStepReturn();
  },
  methods: {
    openModalStatsDetailReturnItem(idReturnItemRecomandation: number) {
      const modalStatsDetailReturnItem = this.$refs
        .ModalStatsDetailReturnItem as any;
      if (modalStatsDetailReturnItem) {
        modalStatsDetailReturnItem.modalOpen(idReturnItemRecomandation);
      }
    },
    openModalReturnItemByFamily(regroupementFamily: string) {
      const modalReturnItemByFamily = this.$refs.ModalReturnItemByFamily as any;
      if (modalReturnItemByFamily) {
        modalReturnItemByFamily.modalOpen(regroupementFamily);
      }
    },
    async nextStep() {
      if (this.getTotalQtySelected > 0) {
        this.pendingStore.$patch({ routerIsPending: true });
        if (this.getCustomer && this.getReturnData) {
          const modelItemsSelected =
            this.returnStore.createModelItemsSelected();
          if (modelItemsSelected) {
            const response = await ApiGestionCap.validItemsSelected(
              modelItemsSelected
            );
            if (response.success) {
              const response = await ApiGestionCap.updateReturnItemsSelected(
                this.getReturnData.id,
                this.getCustomer.id,
                1
              );
              if (response.success) {
                await this.returnStore.refreshReturnData();
                await this.returnStore.refreshReturnPreco();
                this.pendingStore.$patch({ routerIsPending: false });
                this.$router.push({
                  name: RouteName.CheckReturnOrder,
                  params: { idCustomer: this.getCustomer.id },
                });
              }
            }
          }
        }
        this.pendingStore.$patch({ routerIsPending: false });
      } else {
        dialog.alertReturn(
          this.t("message.return.select_return.alert_no_return_item_selected")
        );
      }
    },
  },
});
