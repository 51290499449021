
import { useReturnStore } from "@/store/returnStore";
import { computed, defineComponent, ref, toRaw } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { useI18n } from "vue-i18n";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import { RefCarrier } from "@/type/Return/ReturnType";

export default defineComponent({
  name: "ModalSelectCarrier",
  emits: ["updateCarrier"],
  components: {
    TemplateModaleSite,
  },
  props: {
    refCarriers: {
      type: Array as () => RefCarrier[],
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const returnStore = useReturnStore();

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);

    const isPending = ref(false as boolean);

    const options = ref(
      [] as {
        label: string;
        value: number;
      }[]
    );

    const selected = ref(0);

    props.refCarriers.forEach((carrier) => {
      if (carrier.isDefault) {
        selected.value = carrier.id;
      }
      options.value.push({
        label: carrier.name,
        value: carrier.id,
      });
    });

    return {
      isOpen,
      saveButton,
      getCustomer,
      ServiceFormValidation,
      isPending,
      t,
      options,
      selected,
    };
  },

  methods: {
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    modalOpen() {
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
    updateCarrier() {
      this.$emit("updateCarrier", this.selected);
    },
    setCarrier(idRefCarrier: number) {
      this.selected = idRefCarrier;
    },
  },
});
