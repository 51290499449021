
import { defineComponent } from "vue";

export default defineComponent({
  name: "InputTextSite",
  props: {
    hint: {
      type: String,
      required: false,
    },
  }
});
