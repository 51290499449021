import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_DetailLog = _resolveComponent("DetailLog")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ButtonSite, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchLogs(null))),
      typeButton: 'ACTION'
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("message.users_mg.logs.btn_open")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TemplateModaleSite, {
      idModal: "modalFamilyExcluded",
      labelModal: _ctx.$t('message.users_mg.logs.modal.title'),
      isOpen: _ctx.isOpen,
      width: 'LARGE',
      saveButton: _ctx.saveButton,
      onModal_close: _ctx.modalClose
    }, {
      modalTitle: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("message.users_mg.logs.modal.title")), 1)
      ]),
      modalBody: _withCtx(() => [
        _createVNode(_component_q_table, {
          ref: 'tableLogs',
          rows: _ctx.rows,
          "rows-per-page-options": [10, 20, 50, 100],
          columns: _ctx.columns,
          "row-key": "id",
          class: "tableQuasar",
          "wrap-cells": "",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagination) = $event)),
          "binary-state-sort": "",
          loading: _ctx.loading,
          onRequest: _ctx.searchLogs
        }, {
          "top-left": _withCtx((props) => [
            _createVNode(_component_q_btn, {
              flat: "",
              round: "",
              icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
              onClick: props.toggleFullscreen,
              class: "q-ml-md"
            }, null, 8, ["icon", "onClick"])
          ]),
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "timestamp",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$d(props.row.formatedDate, "long", "fr")), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "libelle",
                  class: _normalizeClass(props.row.idRefTypeLog.code),
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.idRefTypeLog.libelle), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "props"]),
                _createVNode(_component_q_td, {
                  key: "detail",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DetailLog, {
                      log: props.row
                    }, null, 8, ["log"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
      ]),
      _: 1
    }, 8, ["labelModal", "isOpen", "saveButton", "onModal_close"])
  ], 64))
}