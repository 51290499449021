
import { RouteName } from "@/constante/RouterConst";
import { router } from "@/router";
import ApiGestionCap from "@/services/ApiGestionCap";
import { useGlobalStore } from "@/store/globalStore";
import { usePendingStore } from "@/store/pendingStore";
import {
  AmazonOrderBatch,
  ModelDoubleWebOrderId,
  ModelOrderBatchProcessing,
} from "@/type/Amazon/AmazonOrderBatchType";
import {
  AmazonOrder,
  ModelImportOrderErp,
} from "@/type/Amazon/AmazonOrderType";
import { computed, defineComponent, ref } from "vue";
import Separator from "@/components/global/Separator.vue";
import ServiceOrder from "@/services/Amazon/ServiceOrder";
import TableOrder from "@/components/amazon/TableOrder.vue";
import { isTokenReady } from "@baloise/vue-keycloak";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import ModelUpdateOrder from "@/components/amazon/ModalUpdateOrder.vue";
import ModalRemoveOrder from "@/components/amazon/ModalRemoveOrder.vue";
import { useUserStore } from "@/store/userStore";
import dialog from "@/services/DialogSite";
import { useI18n } from "vue-i18n";
import ServiceMercure from "@/services/ServiceMercure";
import { MercureOrderBatchProcessing } from "@/type/Amazon/MercureOrderBatchProcessingType";
import notify from "@/services/NotifySite";
import { UserSite } from "@/type/UserType";

export default defineComponent({
  name: "AmazonDetailOrderBatch",
  components: {
    Separator,
    TableOrder,
    ButtonSite,
    ModelUpdateOrder,
    ModalRemoveOrder,
  },
  setup() {
    const { t } = useI18n();
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const idOrderBatch = ref(null as number | null);
    const orderBatch = ref(null as null | AmazonOrderBatch);
    const userStore = useUserStore();
    const orders = ref([] as AmazonOrder[]);

    const orderBatchError = ref([] as AmazonOrder[]);

    const orderBatchValid = ref([] as AmazonOrder[]);

    const orderBatchValidNotIntegrated = ref([] as AmazonOrder[]);

    const countIntegratedOrder = ref(0 as number);

    const importInProgress = ref(false as boolean);

    const sendFinalCheckPending = ref(false as boolean);

    const getUser = computed(() => {
      return userStore.getUser;
    });
    const eventOrderBatchProcessing = ref(null as null | EventSource);
    const arrayModelDoubleWebOrderId = ref(
      null as ModelDoubleWebOrderId[] | null
    );
    return {
      t,
      globalStore,
      pendingStore,
      idOrderBatch,
      orderBatch,
      orderBatchError,
      orderBatchValid,
      orderBatchValidNotIntegrated,
      orders,
      countIntegratedOrder,
      importInProgress,
      getUser,
      eventOrderBatchProcessing,
      arrayModelDoubleWebOrderId,
      sendFinalCheckPending,
    };
  },
  async mounted() {
    await isTokenReady();
    this.idOrderBatch = parseInt(this.$route.params.idOrderbatch as string);
    await this.refreshOrderBatch();
    if (this.orderBatch?.enableDelete) {
      router.push({ name: RouteName.AmazonOrderBatchs });
    }
    this.subscribToOrderBatchMessage();
    if (!this.disableOrder()) {
      const modelOrderBatchProcessing = {
        idOrderBatch: this.orderBatch?.id,
        processing: true,
      } as ModelOrderBatchProcessing;
      await ApiGestionCap.sendOrderBatchProcessing(modelOrderBatchProcessing);
    }
  },
  async unmounted() {
    if (this.eventOrderBatchProcessing) {
      this.eventOrderBatchProcessing.close();
    }
    if (!this.disableOrder()) {
      const modelOrderBatchProcessing = {
        idOrderBatch: this.orderBatch?.id,
        processing: false,
      } as ModelOrderBatchProcessing;
      await ApiGestionCap.sendOrderBatchProcessing(modelOrderBatchProcessing);
    }
  },
  methods: {
    subscribToOrderBatchMessage() {
      if (this.orderBatch) {
        this.eventOrderBatchProcessing =
          ServiceMercure.subscribeOrderToValidate([this.orderBatch.id]);
        this.eventOrderBatchProcessing.onmessage = async (e) => {
          const mercureOrderToValidate = JSON.parse(
            e.data
          ) as MercureOrderBatchProcessing;
          if (this.disableOrder()) {
            notify.mercure(
              this.t(mercureOrderToValidate.message, {
                idOrderBatch: mercureOrderToValidate.idOrderBatch,
                username: mercureOrderToValidate.username,
              })
            );
            if (
              this.orderBatch &&
              this.orderBatch.id === mercureOrderToValidate.idOrderBatch
            ) {
              this.orderBatch.processing = mercureOrderToValidate.processing;
              if (mercureOrderToValidate.username) {
                const userProcessing = {
                  usernameKeycloak: mercureOrderToValidate.username,
                } as UserSite;
                this.orderBatch.idUserSiteProcessing = userProcessing;
              }
            }
          }
        };
      }
    },
    disableOrder() {
      if (
        this.orderBatch &&
        this.orderBatch.processing &&
        this.orderBatch.idUserSiteProcessing &&
        this.getUser
      ) {
        return (
          this.orderBatch.idUserSiteProcessing.usernameKeycloak !==
          this.getUser.userName
        );
      }
      return false;
    },
    openModalUpdateOrder(order: AmazonOrder) {
      const ModalUpdateOrder = this.$refs.ModalUpdateOrder as any;
      if (ModalUpdateOrder) {
        ModalUpdateOrder.modalOpen(order);
      }
    },

    openModalRemoveOrder(order: AmazonOrder) {
      const ModalRemoveOrder = this.$refs.ModalRemoveOrder as any;
      if (ModalRemoveOrder) {
        ModalRemoveOrder.modalOpen(order);
      }
    },

    /**
     * Envoie des commande amazon dans l'erp
     */
    async importOrders() {
      if (this.orderBatch && !this.disableOrder()) {
        this.importInProgress = true;
        for (let i = 0; i < this.orderBatchValidNotIntegrated.length; i++) {
          let order = this.orderBatchValidNotIntegrated[i];

          const modelImportOrderErp = {
            idOrder: order.id,
          } as ModelImportOrderErp;
          try {
            const tempOrder = await ApiGestionCap.sendOrderToErp(
              modelImportOrderErp
            );
            if (tempOrder && this.orderBatch) {
              this.orderBatchValidNotIntegrated[i] = tempOrder;
              this.updateOrders(tempOrder);
              this.refrechCountOrders();
            }
          } catch (error) {
            dialog.alert(
              this.t("message.amazon.order_batch.error_import", {
                amazonId: order.amazonId,
              })
            );
            break;
          }
        }
        await this.sendFinalCheck();
        this.importInProgress = false;
      }
    },
    async sendFinalCheck() {
      if (this.orderBatch) {
        this.arrayModelDoubleWebOrderId = null;
        this.sendFinalCheckPending = true;
        try {
          const arrayModelDoubleWebOrderId =
            await ApiGestionCap.sendFinalCheckImportOrderAmaz(
              this.orderBatch.id
            );
          if (arrayModelDoubleWebOrderId) {
            dialog.alert(
              this.t("message.amazon.order_batch.error_final_check")
            );
            this.arrayModelDoubleWebOrderId = arrayModelDoubleWebOrderId;
          }
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
        this.sendFinalCheckPending = false;
      }
    },
    /**
     * Fonction qui permet de mettres à jours les commandes
     */
    updateOrders(newOrder: AmazonOrder) {
      let tempOrder = [] as AmazonOrder[];
      this.orders.forEach((order) => {
        if (order.id === newOrder.id) {
          order = newOrder;
        }
        tempOrder.push(order);
      });
      this.orders = tempOrder;
      const TableOrderBatchValid = this.$refs.TableOrderBatchValid as any;
      if (TableOrderBatchValid) {
        TableOrderBatchValid.updateOrder(newOrder);
      }
    },
    /**
     * Fonction qui permet de mettre à jours le compteur des commandes déjà intégré
     */
    refrechCountOrders() {
      this.countIntegratedOrder = this.orders.filter(function (
        order: AmazonOrder
      ) {
        return order.isSendToErp;
      }).length;
    },

    /**
     * Fonction qui permet de mettre à jours le tableau des commandes
     *
     */
    updateArrayOrders(order: AmazonOrder) {
      if (!ServiceOrder.checkErrOrder(order)) {
        /** supression de l **/
        this.removeFromArrayOrders(order);
        this.orderBatchValidNotIntegrated.push(order);
        this.orderBatchValid.push(order);
      } else {
        this.orderBatchError.filter(function (
          orderNotValid: AmazonOrder,
          index: number,
          ar: AmazonOrder[]
        ) {
          if (orderNotValid.id === order.id) {
            ar[index] = order;
          }
          return true;
        });
      }
    },

    /**
     * fonction qui permet de supprimer une commande du tableau d'erreur
     */
    removeFromArrayOrders(order: AmazonOrder) {
      this.orderBatchError.filter(function (
        orderNotValid: AmazonOrder,
        index: number,
        ar: AmazonOrder[]
      ) {
        if (orderNotValid.id === order.id) {
          ar.splice(index, 1);
          return false;
        }
        return true;
      });
    },

    /**
     * Refresh du order batch
     */
    async refreshOrderBatch() {
      this.pendingStore.$patch({ routerIsPending: true });

      if (this.idOrderBatch) {
        this.orderBatch = null;
        this.orders = [];
        this.orderBatchError = [];
        this.orderBatchValid = [];
        this.orderBatch = await ApiGestionCap.getOrderBatch(this.idOrderBatch);

        if (this.orderBatch.orders) {
          this.orders = this.orderBatch.orders;
          this.orderBatchError = this.filterOrderBatchError(this.orders);
          this.orderBatchValid = this.filterOrderBatchValid(this.orders);
          this.orderBatchValidNotIntegrated =
            this.filterOrderBatchValidNotIntegrated(this.orders);
          this.refrechCountOrders();
        }
      } else {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Fonction qui retourne un tableau filtrer de commande en erreur
     */
    filterOrderBatchError(orders: AmazonOrder[]): AmazonOrder[] {
      return orders.filter(function (order: AmazonOrder) {
        return ServiceOrder.checkErrOrder(order);
      });
    },
    /**
     * Fonction qui retourne un tableau filtrer de commande valide
     */
    filterOrderBatchValid(orders: AmazonOrder[]): AmazonOrder[] {
      return orders.filter(function (order: AmazonOrder) {
        return !ServiceOrder.checkErrOrder(order);
      });
    },
    /**
     * Fonction qui retourne un tableau filtrer de commande valide non intégré
     */
    filterOrderBatchValidNotIntegrated(orders: AmazonOrder[]): AmazonOrder[] {
      return orders.filter(function (order: AmazonOrder) {
        return !ServiceOrder.checkErrOrder(order) && !order.isSendToErp;
      });
    },
  },
});
