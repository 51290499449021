
import type { QTableProps } from "quasar";
import ApiGestionCap from "@/services/ApiGestionCap";
import { usePendingStore } from "@/store/pendingStore";
import { CustomerGroup } from "@/type/CustomerGroupType";
import {
  Customer,
  ModelSearchCustomer,
  PaginateCustomers,
} from "@/type/CustomerType";
import { isTokenReady } from "@baloise/vue-keycloak";
import { defineComponent, ref } from "vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import Separator from "@/components/global/Separator.vue";
import BlockAddress from "@/components/user/BlockAddress.vue";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import { useI18n } from "vue-i18n";
import { useGlobalStore } from "@/store/globalStore";
import { ErrorGlobal, ErrorSite, ERROR_TYPE } from "@/type/Error";
import {
  MGCustomer,
  ModelUpateRefAutoCenter,
  PaginateMGCustomers,
  RefAutoCenter,
} from "@/type/MyGarage/MGCustomerType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import dialog from "@/services/DialogSite";

export default defineComponent({
  name: "SearchCustomers",
  props: {
    isMyGarage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ButtonSite,
    Separator,
    BlockAddress,
    BlockMsgError,
    InputTextSite,
  },
  setup(props) {
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const pendingStore = usePendingStore();
    const customergroups = ref(null as null | CustomerGroup[]);
    const customersPaginate = ref(
      null as null | PaginateCustomers | PaginateMGCustomers
    );
    const modelSearchCustomer = ref(null as null | ModelSearchCustomer);
    const selectCustomerGroup = ref(null as null | CustomerGroup);
    const postCode = ref("" as string);
    const idErpCustomer = ref("" as string);
    const actualPage = ref(1 as number);
    const actualLimit = ref(10 as number);
    const sortBy = ref("id" as string);
    const descending = ref(false as boolean);
    const columns = [
      {
        name: "id",
        required: true,
        label: "message.customers.table.idErp",
        align: "left",
        field: (row: Customer | MGCustomer) => row.id,
        sortable: true,
      },
      {
        name: "name",
        required: true,
        label: "message.customers.table.name",
        align: "left",
        field: (row: Customer | MGCustomer) => row.name,
        sortable: true,
      },
      {
        name: "address",
        required: true,
        label: "message.customers.table.address",
        align: "left",
        field: (row: Customer | MGCustomer) => row.address,
        sortable: true,
      },
      {
        name: "phoneNo",
        required: true,
        label: "message.customers.table.phone",
        align: "left",
        field: (row: Customer | MGCustomer) => row.phoneNo,
        sortable: true,
      },
    ];

    if (props.isMyGarage) {
      columns.push({
        name: "orderBlocked",
        required: true,
        label: "message.customers.table.mg.order_blocked",
        align: "center",
        field: (row: Customer | MGCustomer) => {
          if (isMGCustomer(row)) {
            return row.idRefAutoCenter.orderBlocked ? "A" : "B";
          } else {
            return "";
          }
        },
        sortable: true,
      });
    }

    /**
     *fonction qui controle si un object est de type Customer ou MGCustomer
     */
    function isMGCustomer(row: Customer | MGCustomer): row is MGCustomer {
      return (row as MGCustomer).idRefAutoCenter !== undefined;
    }

    const rows = ref([] as Customer[] | MGCustomer[]);
    const pagination = ref({
      sortBy: "idErp",
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0,
    });
    const loading = ref(false);

    return {
      globalStore,
      t,
      pendingStore,
      customergroups,
      customersPaginate,
      modelSearchCustomer,
      selectCustomerGroup,
      postCode,
      idErpCustomer,
      actualPage,
      actualLimit,
      columns,
      rows,
      pagination,
      loading,
      sortBy,
      descending,
    };
  },
  /**
   * Pendant la création du component récupération des enseigne en fonction du props isMyGarage
   */
  async mounted() {
    this.pendingStore.$patch({ routerIsPending: true });
    await isTokenReady();
    try {
      if (this.isMyGarage) {
        this.customergroups = await ApiGestionCap.getCustomerGroup();
      } else {
        this.customergroups = await ApiGestionCap.getCustomerGroupNotBlocked();
      }
    } catch (error) {
      router.push({ name: RouteName.Error });
    }
    this.pendingStore.$patch({ routerIsPending: false });
  },
  /**
   * Pendant la destuction du components remet la variable global errorMessage à null
   */
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  methods: {
    /**
     * Fonction qui permet de blocker les commande sur un centre auto my garage
     */
    async updateOrderBlockedRefAutoCenter(refAutoCenter: RefAutoCenter) {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        const modelUpdateRefAutoCenter = {
          idRefAutoCenter: refAutoCenter.id,
          orderBlocked: refAutoCenter.orderBlocked,
        } as ModelUpateRefAutoCenter;
        refAutoCenter = await ApiGestionCap.updateRefAutoCenter(
          modelUpdateRefAutoCenter
        );
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Fonction qui permet de recharger le tableau de customer avec le recherche ou les champs de trie du tableau
     */
    async searchCustomer(
      props: Parameters<NonNullable<QTableProps["onRequest"]>>[0]
    ) {
      this.globalStore.$patch({ errorMessage: null });
      /**
       * Si la requete vient du tableau
       */
      if (props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination;
        this.actualPage = page;
        this.actualLimit = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      } else {
        this.actualPage = 1;
      }
      /**
       * Si tous les champs de recherche sont vide affichage de l'erreur
       */
      if (
        null === this.selectCustomerGroup &&
        this.postCode === "" &&
        this.idErpCustomer === ""
      ) {
        const errorSite = {
          message: this.t("message.search.searchCustomer.error.emptySearch"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      } else {
        if (null === props) {
          this.pendingStore.$patch({ routerIsPending: true });
        }
        this.loading = true;
        this.modelSearchCustomer = {
          idErp: this.idErpCustomer ? this.idErpCustomer : null,
          postCode: this.postCode ? this.postCode : null,
          idRefTypeCustomerGroup: this.selectCustomerGroup
            ? this.selectCustomerGroup.id
            : null,
          page: this.actualPage,
          limit: this.actualLimit,
          sortBy: this.sortBy,
          descending: this.descending,
        };
        try {
          let response = null;
          /**
           * Si props isMyGarage appele de endpoint different pour la recherche de customer
           */
          if (this.isMyGarage) {
            response = await ApiGestionCap.findRefAutoCenter(
              this.modelSearchCustomer
            );
          } else {
            response = await ApiGestionCap.findCustomer(
              this.modelSearchCustomer
            );
          }
          if (this.isErrorGlobal(response)) {
            const errorSite = {
              message: this.t(response.message),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.rows = [];
            this.globalStore.$patch({ errorMessage: errorSite });
          } else {
            this.rows = response.arrayPaginate;
            this.pagination.page = response.actualPage;
            this.pagination.rowsPerPage = response.nbItemsPerPage;
            this.pagination.rowsNumber = response.arraySize;
            this.pagination.sortBy = this.sortBy;
            this.pagination.descending = this.descending;
          }
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
        this.loading = false;
        if (null === props) {
          this.pendingStore.$patch({ routerIsPending: false });
        }
      }
    },
    /**
     * Fonction qui controle si un objet est de type ErroGlobal
     */
    isErrorGlobal(
      error: PaginateCustomers | PaginateMGCustomers | ErrorGlobal
    ): error is ErrorGlobal {
      return (error as ErrorGlobal).message !== undefined;
    },
    /**
     * Fonction qui permet de rediriger vers la page customer
     */
    redirectToCustomer(customer: Customer) {
      if (customer.canAccess) {
        router.push({
          name: RouteName.Customer,
          params: { idCustomer: customer.id },
        });
      } else {
        dialog.alertReturn(
          this.t("message.search.searchCustomer.error.noAccess")
        );
      }
    },
    getClassStyle(row: Customer | MGCustomer): string {
      let classStyle = "";
      if (this.isMyGarage) {
        classStyle += " cursor-default ";
      } else {
        classStyle += " cursor-pointer ";
      }
      if (!row.canAccess) {
        classStyle += "forbidden";
      }
      return classStyle;
    },
  },
});
