import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

//components
import Home from '../views/Home.vue'
import Customers from '../views/Customers.vue'
import Customer from '../views/Customer.vue'
import CreateReturnOrder from '../views/Return/CreateReturnOrder.vue'
import SelectReturnItem from '../views/Return/SelectReturnItem.vue'
import CheckReturnOrder from '../views/Return/CheckReturnOrder.vue'
import SelectShipping from '../views/Return/SelectShipping.vue'
import ReturnValidation from '../views/Return/ReturnValidation.vue'
import CustomerPurchase from '../views/Customer/CustomerPurchase.vue'
import CustomerStock from '../views/Customer/CustomerStock.vue'
import CustomerOrders from '../views/Customer/CustomerOrders.vue'
import CreateUser from '../views/CreateUser.vue'
import MGExcludedItem from "../views/MyGarage/MGExcludedItem.vue"
import MGRefTypeCustomerGroup from "../views/MyGarage/MGRefTypeCustomerGroup.vue"
import MGUsers from "../views/MyGarage/MGUsers.vue"
import MGForceUpdateItem from "../views/MyGarage/MGForceUpdateItem.vue"
import MGRefAutoCenter from "../views/MyGarage/MGRefAutoCenter.vue"
import AmazonOrderBatchs from "../views/Amazon/AmazonOrderBatchs.vue"
import AmazonDetailOrderBatch from "../views/Amazon/AmazonDetailOrderBatch.vue"
import AmazonShippingStats from "../views/Amazon/AmazonShippingStats.vue"
import AmazonManageShipStatusRemainder from "../views/Amazon/AmazonManageShipStatusRemainder.vue"

import GestionRefTypeCustomerGroup from '../views/GestionRefTypeCustomerGroup.vue'
import Error from '../views/Error.vue'
import Unauthorized from '../views/Unauthorized.vue'
import { useKeycloak, getKeycloak, isTokenReady } from "@baloise/vue-keycloak";
import { RouteName, RouterRoles } from '@/constante/RouterConst'
import { useGlobalStore } from '@/store/globalStore'
import { ListIdClientSso } from '@/type/MenuSSOType'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: RouteName.Home,
    component: Home,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: false,
      libelle: 'message.router.title.home',
      position: 1
    }
  },
  {
    path: '/customers',
    name: RouteName.Customers,
    component: Customers,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.customers',
      position: 2
    }
  },
  {
    path: '/customer/:idCustomer',
    name: RouteName.Customer,
    component: Customer,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: true,
      libelle: 'message.router.title.customer',
      parent: RouteName.Customers
    }
  },
  {
    path: '/create_return_order/:idCustomer',
    name: RouteName.CreateReturnOrder,
    component: CreateReturnOrder,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.create_return_order',
    }
  },
  {
    path: '/select_return_item/:idCustomer',
    name: RouteName.SelectReturnItem,
    component: SelectReturnItem,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.select_return_item',
    }
  },
  {
    path: '/check_return_item/:idCustomer',
    name: RouteName.CheckReturnOrder,
    component: CheckReturnOrder,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.check_return_item',
    }
  },
  {
    path: '/select_shipping/:idCustomer',
    name: RouteName.SelectShipping,
    component: SelectShipping,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.select_shipping',
    }
  },
  {
    path: '/return_validation/:idCustomer',
    name: RouteName.ReturnValidation,
    component: ReturnValidation,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.return_validation',
    }
  },
  {
    path: '/customer_purchase/:idCustomer',
    name: RouteName.CustomerPurchase,
    component: CustomerPurchase,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.customer_purchase',
    }
  },
  {
    path: '/customer_stock/:idCustomer',
    name: RouteName.CustomerStock,
    component: CustomerStock,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.customer_stock',
    }
  },
  {
    path: '/customer_orders/:idCustomer',
    name: RouteName.CustomerOrders,
    component: CustomerOrders,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
      libelle: 'message.router.title.customer_orders',
    }
  },
  {
    path: '/amazon_orders',
    name: RouteName.AmazonOrderBatchs,
    component: AmazonOrderBatchs,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.amazon_orders',
      position: 2
    }
  },
  {
    path: '/amazon_manage_shipping_remainder',
    name: RouteName.AmazonManageShppingStatusRemainder,
    component: AmazonManageShipStatusRemainder,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: true,
      libelle: 'message.router.title.amazon_manage_shipping_remainder',
      parent: RouteName.AmazonOrderBatchs
    }
  },

  {
    path: '/amazon_orders/detail/:idOrderbatch(\\d+)',
    name: RouteName.AmazonDetailOrderBatch,
    component: AmazonDetailOrderBatch,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: true,
      libelle: 'message.router.title.amazon_orders_detail',
      parent: RouteName.AmazonOrderBatchs
    }
  },
  {
    path: '/amazon_shipping_stats',
    name: RouteName.AmazonShippingStats,
    component: AmazonShippingStats,
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: true,
      libelle: 'message.router.title.amazon_shipping_stats',
      parent: RouteName.AmazonOrderBatchs
    }
  },
  {
    path: '/gestion_customer_group',
    name: RouteName.GestionRefTypeCustomerGroup,
    component: GestionRefTypeCustomerGroup,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.gestion_customer_group',
      position: 1,
      parent: 'message.router.title.admin',
      parentPosition: 3
    },
  },
  {
    path: '/create_user',
    name: RouteName.CreateUser,
    component: CreateUser,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.create_user',
      position: 2,
      parent: 'message.router.title.admin',
      parentPosition: 3
    },
  },
  {
    path: '/excluded_item',
    name: RouteName.ItemExcluded,
    component: MGExcludedItem,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.excluded_item',
      position: 1,
      parent: 'message.router.title.my_garage',
      parentPosition: 4
    },
  },
  {
    path: '/gestion_customer_group_mg',
    name: RouteName.RefTypeCustomerGroupMG,
    component: MGRefTypeCustomerGroup,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.gestion_customer_group_mg',
      position: 2,
      parent: 'message.router.title.my_garage',
      parentPosition: 4
    },
  },
  {
    path: '/mg_users',
    name: RouteName.MGUsers,
    component: MGUsers,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.gestion_users_mg',
      position: 3,
      parent: 'message.router.title.my_garage',
      parentPosition: 4
    },
  },
  {
    path: '/mg_rac',
    name: RouteName.MGRefAutoCenter,
    component: MGRefAutoCenter,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.gestion_rac',
      position: 4,
      parent: 'message.router.title.my_garage',
      parentPosition: 4
    },
  },
  {
    path: '/mg_force_update_item',
    name: RouteName.MGForceUpdateItem,
    component: MGForceUpdateItem,
    meta: {
      isAuthenticated: true,
      showInMenu: true,
      showInBreadCrumb: true,
      libelle: 'message.router.title.gestion_force_update_item',
      position: 5,
      parent: 'message.router.title.my_garage',
      parentPosition: 4
    },
  },
  {
    // Route to redirect to if user is not logged authorized
    path: '/Unauthorized',
    name: RouteName.Unauthorized,
    component: Unauthorized,
    meta: {
      isAuthenticated: false,
      showInMenu: false,
      showInBreadCrumb: false,
    }
  },
  {
    // Si Route inexistante retour sur la page Home
    path: '/error',
    name: RouteName.Error,
    component: Error,
    meta: {
      isAuthenticated: false,
      showInMenu: false,
      showInBreadCrumb: false,
    }
  },
  {
    // Si Route inexistante retour sur la page Home
    path: '/:pathMatch(.*)',
    redirect: { name: RouteName.Home },
    meta: {
      isAuthenticated: true,
      showInMenu: false,
      showInBreadCrumb: false,
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  await isTokenReady();
  if (to.meta.isAuthenticated) {
    // Récupère l'URL réelle de l'application, elle est nécessaire pour Keycloak
    const basePath = window.location.toString()
    const { isAuthenticated } = useKeycloak();
    if (!isAuthenticated.value) {
      // La page est protégée et l'utilisateur n'est pas authentifié. Forcez une connexion.     
      getKeycloak().login({ redirectUri: basePath })
        .catch((err: string) => {
          next({ name: RouteName.offline })
        })

    } else if (hasAccessTo(to.name as string)) {
      // L'utilisateur a été authentifié et a le rôle d'application
      getKeycloak().updateToken(70)
        .then(() => {
          next()
        })
        .catch((err: string) => {
          next({ name: RouteName.Error })
        })

    } else {
      // L'utilisateur a été authentifié, mais n'avait pas le bon rôle 
      // Rediriger vers une page d'erreur 
      if (hasAccessToExternal(process.env.VUE_APP_KEYCLOAK_CLIENT)) {
        next({ name: RouteName.Unauthorized })
      } else {
        let singleUnivers = '';
        let universAccessCount = 0;

        const keycloakInstance = useKeycloak().keycloak;
        const globalStore = useGlobalStore();
        const listIdClientSsos = {
          listIdClientSso: Object.keys(keycloakInstance.resourceAccess as any),
        } as ListIdClientSso;
        await globalStore.setMenuSSO(listIdClientSsos);

        const getMenuSso = globalStore.getMenuSSO;
        if (getMenuSso && getMenuSso.ssoSiteInfoMenus !== undefined) {
          getMenuSso.ssoSiteInfoMenus.forEach(site => {
            if (hasAccessToExternal(site.idClientSso)) {
              singleUnivers = site.idClientSso;
              universAccessCount++;
            }
          });
        }
        const googleUrl = 'https://www.google.com/';
        if (0 === universAccessCount) {
          location.replace(googleUrl);
        }
        if (1 === universAccessCount) {
          next({ name: singleUnivers })
        }
        if (1 < universAccessCount) {
          location.replace(getMenuSso?.defaultSiteSso.urlSite ? getMenuSso?.defaultSiteSso.urlSite : googleUrl);
        }
      }
    }
  } else {
    // Cette page n'a pas ne nécessite pas d'authentification 
    next();
  }
})

function hasAccessTo(routeName: string): boolean {
  let hasAccess = false
  RouterRoles[routeName]?.forEach(role => {
    if (getKeycloak().hasResourceRole(role)) {
      hasAccess = true;
    }
  })
  return hasAccess;
}

function hasAccessToExternal(externalName: string): boolean {
  let hasAccess = false;
  const resourceAccess = getKeycloak().resourceAccess;
  if (resourceAccess && externalName in resourceAccess) {
    hasAccess = true;
  }
  return hasAccess;
}


export { router, hasAccessTo, hasAccessToExternal } 
