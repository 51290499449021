
import { Log } from "@/type/MyGarage/MGLogType";
import { defineComponent, PropType } from "vue";
import {
  CODE_CONNECT,
  CODE_ITEM,
  CODE_IMMAT,
  CODE_ORDER,
} from "@/constante/RefTypeLogConst";

export default defineComponent({
  name: "DetailLog",
  props: {
    log: {
      type: Object as PropType<Log>,
      required: true,
    },
  },
  setup() {
    return {
      CODE_CONNECT,
      CODE_ITEM,
      CODE_IMMAT,
      CODE_ORDER,
    };
  },
});
