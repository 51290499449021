
import { defineComponent, computed } from "vue";
import { usePendingStore } from "@/store/pendingStore";

export default defineComponent({
  name: "PendingSpinner",
  props: {
    classSpinner: String,
    isPending: Boolean,
    color: {
      type: String,
      required: false,
      default: "white",
    },
    size: {
      type: String,
      required: false,
      default: "4em",
    },
  },
  setup(props) {
    const pendingStore = usePendingStore();
    const getshowSpinner = computed(() => {
      return pendingStore.getRouterIsPending ? false : props.isPending;
    });
    return { getshowSpinner };
  },
});
