/**
 * Service qui permet de créer des route pour les menu SSO
 */

import { SiteSSO } from '@/type/MenuSSOType';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

export default {
    createRoute(siteSSO: SiteSSO): RouteRecordRaw {
        const newRoute = {
            path: "/" + siteSSO.idClientSso,
            name: siteSSO.idClientSso,
            component: Home,
            meta: {
                isAuthenticated: false,
                showInMenu: true,
                showInBreadCrumb: false,
                libelle: siteSSO.libelleMenu,
                rawLibelle: true,
                position: siteSSO.positionMenu,
                parent: "message.router.title.other_univers",
                parentPosition: 99,
                external: true,
            },
            beforeEnter: (to, from, next) => {
                location.replace(siteSSO.urlSite);
            },
        } as RouteRecordRaw;
        return newRoute;
    }
}