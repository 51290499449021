
import { useUserStore } from "@/store/userStore";
import { computed, defineComponent} from "vue";

export default defineComponent({
  name: "Home",
  setup(){
    const userStore = useUserStore();
    const getUser =  computed(() => {
      return userStore.getUser;
    });
    return{
      getUser
    }
  }
});
