
import { defineComponent, ref } from "vue";
import ApiGestionCap from "@/services/ApiGestionCap";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import { Log, ModelSearchLogs, PaginateMGLog } from "@/type/MyGarage/MGLogType";
import { ErrorGlobal, ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";
import type { QTableProps } from "quasar";
import { useI18n } from "vue-i18n";
import DetailLog from "@/components/mygarage/DetailLog.vue";

export default defineComponent({
  name: "UserLog",
  props: {
    idUserSite: {
      type: Number,
      required: true,
    },
  },
  components: {
    ButtonSite,
    TemplateModaleSite,
    DetailLog,
  },
  setup(props) {
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const { t } = useI18n();
    const rows = ref([] as Log[]);
    const columns = [
      {
        name: "timestamp",
        required: true,
        label: "message.users_mg.logs.table.date",
        align: "left",
        field: (row: Log) => row.formatedDate,
        sortable: true,
      },
      {
        name: "libelle",
        required: true,
        label: "message.users_mg.logs.table.libelle",
        align: "left",
        field: (row: Log) => row.idRefTypeLog.libelle,
        sortable: true,
      },
      {
        name: "detail",
        required: true,
        label: "message.users_mg.logs.table.detail",
        align: "left",
      },
    ];
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);

    const actualPage = ref(1 as number);
    const actualLimit = ref(10 as number);
    const sortBy = ref("timestamp" as string);
    const descending = ref(false as boolean);

    const modelSearchLogs = ref({
      idUserSite: props.idUserSite,
      page: 1,
      limit: 10,
      sortBy: "timestamp",
      descending: false,
    } as ModelSearchLogs);

    const pagination = ref({
      sortBy: "timestamp",
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 0,
    });

    const loading = ref(false);
    const logsPaginate = ref(null as null | PaginateMGLog);
    return {
      actualPage,
      actualLimit,
      globalStore,
      descending,
      sortBy,
      saveButton,
      loading,
      rows,
      columns,
      filter: ref(""),
      isOpen: ref(false),
      pendingStore,
      modelSearchLogs,
      logsPaginate,
      pagination,
      t,
    };
  },
  methods: {
    /**
     * Fonction qui recharge le tableau de log, peut etre appeler par la recherche ou par les trie du tableau
     */
    async searchLogs(
      props: Parameters<NonNullable<QTableProps["onRequest"]>>[0]
    ) {
      this.globalStore.$patch({ errorMessage: null });
      if (props) {
        const { page, rowsPerPage, sortBy, descending } = props.pagination;
        this.actualPage = page;
        this.actualLimit = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      } else {
        this.actualPage = 1;
      }

      if (null === props) {
        this.pendingStore.$patch({ routerIsPending: true });
      }
      this.loading = true;
      this.modelSearchLogs = {
        idUserSite: this.idUserSite,
        page: this.actualPage,
        limit: this.actualLimit,
        sortBy: this.sortBy,
        descending: this.descending,
      };
      try {
        let response = await ApiGestionCap.findLogsForUser(
          this.modelSearchLogs
        );
        if (this.isErrorGlobal(response)) {
          const errorSite = {
            message: this.t(response.message),
            type: ERROR_TYPE.GLOBAL,
          } as ErrorSite;
          this.globalStore.$patch({ errorMessage: errorSite });
        } else {
          this.rows = response.arrayPaginate;
          this.pagination.page = response.actualPage;
          this.pagination.rowsPerPage = response.nbItemsPerPage;
          this.pagination.rowsNumber = response.arraySize;
          this.pagination.sortBy = this.sortBy;
          this.pagination.descending = this.descending;
          this.modalOpen();
        }
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.loading = false;
      if (null === props) {
        this.modalOpen();
        this.pendingStore.$patch({ routerIsPending: false });
      }
    },
    /**
     * Fonction qui controle si un objet est de type errorGlobal
     */
    isErrorGlobal(error: PaginateMGLog | ErrorGlobal): error is ErrorGlobal {
      return (error as ErrorGlobal).message !== undefined;
    },
    /**
     * Fonction qui ouvre la modal
     */
    async modalOpen() {
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
  },
});
