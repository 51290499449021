import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row justify-center" }
const _hoisted_5 = { class: "col-md-3 col-sm-3 col-xs-12" }
const _hoisted_6 = { class: "row justify-center text-center" }
const _hoisted_7 = { class: "col-md-6 col-sm-6 col-xs-1" }
const _hoisted_8 = { class: "row justify-center" }
const _hoisted_9 = { class: "col-md-12" }
const _hoisted_10 = {
  key: 0,
  class: "row"
}
const _hoisted_11 = { class: "col-md-12" }
const _hoisted_12 = { class: "blockRapport" }
const _hoisted_13 = {
  key: 1,
  class: "row"
}
const _hoisted_14 = { class: "col-md-12" }
const _hoisted_15 = { class: "blockRapport" }
const _hoisted_16 = {
  key: 2,
  class: "row"
}
const _hoisted_17 = { class: "col-md-12" }
const _hoisted_18 = { class: "blockRapport" }
const _hoisted_19 = {
  key: 3,
  class: "row"
}
const _hoisted_20 = { class: "col-md-12" }
const _hoisted_21 = { class: "blockRapport" }
const _hoisted_22 = { class: "row blockTable" }
const _hoisted_23 = {
  key: 0,
  class: "col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputFileSite = _resolveComponent("InputFileSite")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_BinariStatus = _resolveComponent("BinariStatus")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.create_user")), 1)
      ])
    ]),
    _createVNode(_component_q_form, {
      class: "q-gutter-md formImportUser",
      ref: "formImportUser"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_InputFileSite, {
              modelValue: _ctx.csv,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.csv) = $event)),
              label: _ctx.$t('message.create_user.form.fileCsv')
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ButtonSite, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.uploadUsers())),
              typeButton: 'INFO'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("message.create_user.form.upload")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_Separator),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_BlockMsgError)
      ])
    ]),
    (_ctx.csvUsers)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("message.create_user.report.csvUsers", { qty: _ctx.csvUsers })), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.csvManagerMyGarage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("message.create_user.report.csvManagerMyGarage", {
              qty: _ctx.csvManagerMyGarage,
            })), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.csvUsersImportedKeycloak)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("message.create_user.report.csvUsersImportedKeycloak", {
              qty: _ctx.csvUsersImportedKeycloak,
            })), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.csvUsersImportedMyGarage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("message.create_user.report.csvUsersImportedMyGarage", {
              qty: _ctx.csvUsersImportedMyGarage,
            })), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_22, [
      (_ctx.rows)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createVNode(_component_q_table, {
              ref: 'tableImportedUser',
              filter: _ctx.filter,
              rows: _ctx.rows,
              "rows-per-page-options": [10, 20, 50, 100],
              columns: _ctx.columns,
              "row-key": "id",
              class: "tableQuasar",
              "wrap-cells": ""
            }, {
              "top-left": _withCtx((props) => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  round: "",
                  icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
                  onClick: props.toggleFullscreen,
                  class: "q-ml-md"
                }, null, 8, ["icon", "onClick"])
              ]),
              "top-right": _withCtx(() => [
                _createVNode(_component_q_input, {
                  outlined: "",
                  modelValue: _ctx.filter,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter) = $event)),
                  label: _ctx.$t('message.search.searchGlobal')
                }, {
                  append: _withCtx(() => [
                    (_ctx.filter !== '')
                      ? (_openBlock(), _createBlock(_component_q_icon, {
                          key: 0,
                          name: "close",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter = '')),
                          class: "cursor-pointer"
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_icon, { name: "search" })
                  ]),
                  _: 1
                }, 8, ["modelValue", "label"])
              ]),
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_th, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      key: "username",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.username), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "firstName",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.firstName), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "lastName",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.lastName), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "email",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.email), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "erpId",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.erpId), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "isManager",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BinariStatus, {
                          status: props.row.isManager
                        }, null, 8, ["status"])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "idKeycloak",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.idKeycloak), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "createdInKeycloak",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BinariStatus, {
                          status: props.row.createdInKeycloak
                        }, null, 8, ["status"])
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "createdInMyGarage",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BinariStatus, {
                          status: props.row.createdInMyGarage
                        }, null, 8, ["status"])
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["filter", "rows", "columns"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}