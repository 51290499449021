import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_ctx.status)
    ? (_openBlock(), _createBlock(_component_q_icon, {
        key: 0,
        name: "verified",
        color: "positive",
        class: "binariIcon"
      }))
    : (_openBlock(), _createBlock(_component_q_icon, {
        key: 1,
        name: "dangerous",
        color: "negative",
        class: "binariIcon"
      }))
}