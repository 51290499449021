
import { ExcludedGlobal } from "@/type/MyGarage/MGExcludedItemType";
import { computed, defineComponent, ref } from "vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import { CODE_ITC, CODE_PGC, CODE_RF } from "@/constante/ExcludedTypeConst";
import ApiGestionCap from "@/services/ApiGestionCap";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { isTokenReady } from "@baloise/vue-keycloak";
import InputTextSite from "@/components/customInput/InputTextSite.vue";

export default defineComponent({
  name: "ExcludedTable",
  components: {
    ButtonSite,
    BlockMsgError,
    TemplateModaleSite,
    InputTextSite,
  },
  props: {
    excludedType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const pendingStore = usePendingStore();
    const getRouterIsPending = computed(() => {
      return pendingStore.getRouterIsPending;
    });
    const rows = ref([] as ExcludedGlobal[]);
    const columns = [
      {
        name: "id",
        required: true,
        label: "message.table_excluded_global.id",
        align: "left",
        field: (row: ExcludedGlobal) => row.id,
        sortable: true,
      },
      {
        name: "code",
        required: true,
        label: "message.table_excluded_global.code",
        align: "left",
        field: (row: ExcludedGlobal) => row.code,
        sortable: true,
      },
    ];

    const excludedSelected = ref(null as null | ExcludedGlobal);

    const saveButton = ref({
      label: "message.modal_create_update_excluded.btn_save",
      affBtn: true,
    } as ModalBtnType);

    return {
      rows,
      columns,
      filter: ref(""),
      getRouterIsPending,
      pendingStore,
      isOpen: ref(false),
      excludedSelected,
      saveButton,
    };
  },
  /**
   * A la création du component mise à jours de la table des exclusion
   */
  async mounted() {
    await isTokenReady();
    this.refreshExcluded();
  },
  methods: {
    /**
     * Fonction qui permet de faire le retrait d'un exclusion en fonction du props excludedType
     */
    async deleteExcluded(idExcluded: number) {
      this.pendingStore.$patch({ routerIsPending: true });
      let message = "";
      switch (this.excludedType) {
        case CODE_ITC:
          message = await ApiGestionCap.deleteItc(idExcluded);
          break;
        case CODE_PGC:
          message = await ApiGestionCap.deletePgc(idExcluded);
          break;
        case CODE_RF:
          message = await ApiGestionCap.deleteRf(idExcluded);
          break;
        default:
          message = "CODE INCONNUE";
          break;
      }
      this.pendingStore.$patch({ routerIsPending: false });
      this.modalClose();
      this.refreshExcluded();
    },
    /**
     * Fonction qui appel le enpoint pour la récupération des exclusion en fonction du props excludedType
     */
    async refreshExcluded() {
      this.pendingStore.$patch({ routerIsPending: true });
      switch (this.excludedType) {
        case CODE_ITC:
          this.rows = await ApiGestionCap.getExcludedItc();
          break;
        case CODE_PGC:
          this.rows = await ApiGestionCap.getExcludedPgc();
          break;
        case CODE_RF:
          this.rows = await ApiGestionCap.getExcludedRf();
          break;
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Fonction qui initialise un nouvelle exclusion et ouvre la modal
     */
    createExcluded() {
      this.excludedSelected = {
        id: null,
        code: "",
      };
      this.isOpen = true;
    },
    /**
     * fonction qui ouvre la modal avec les parametre d'une exclusion pour pouvoir en faire la mise à jours
     */
    updateExcluded(excluded: ExcludedGlobal) {
      this.excludedSelected = {
        id: excluded.id,
        code: excluded.code,
      };
      this.isOpen = true;
    },
    /**
     * Fonction qui permet la création ou la mise à jours d'une exclusion
     */
    async modalSave() {
      if (this.excludedSelected && this.excludedSelected.code) {
        this.pendingStore.$patch({ routerIsPending: true });
        switch (this.excludedType) {
          case CODE_ITC:
            await ApiGestionCap.cuExcludedItc(this.excludedSelected);
            break;
          case CODE_PGC:
            await ApiGestionCap.cuExcludedPgc(this.excludedSelected);
            break;
          case CODE_RF:
            await ApiGestionCap.cuExcludedRf(this.excludedSelected);
            break;
        }
        this.pendingStore.$patch({ routerIsPending: false });
        this.modalClose();
        this.refreshExcluded();
      }
    },
    modalClose() {
      this.isOpen = false;
    },
  },
});
