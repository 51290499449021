
import { useReturnStore } from "@/store/returnStore";
import { ModelDetailByFamily } from "@/type/Return/ReturnType";
import { computed, defineComponent, ref, toRaw } from "vue";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import { useI18n } from "vue-i18n";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import ExpandGlobalReturn from "@/components/return/ExpandGlobalReturn.vue";
import ServiceTable from "@/services/ServiceTable";

export default defineComponent({
  name: "TableModelDetailByFamily",
  components: {
    InputTextSite,
    ButtonSite,
    ExpandGlobalReturn,
  },
  props: {
    withCheckBox: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const returnStore = useReturnStore();
    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const filter = ref({
      regroupementFamily: "" as string,
      countIdErpItem: "" as string,
      qty: "" as string,
      amount: "" as string,
    });

    if (props.withCheckBox) {
      returnStore.getModelDetailByFamilys();
    }
    let columns = [] as any[];

    if (props.withCheckBox) {
      columns.push({
        name: "checked",
        label: "message.return.check_return.family_preco.table.checked",
        align: "center",
      });
    }

    columns.push(
      {
        name: "regroupementFamily",
        required: true,
        label:
          "message.return.check_return.family_preco.table.regroupementFamily",
        align: "left",
        field: (row: ModelDetailByFamily) => row.regroupementFamily,
        sortable: true,
      },
      {
        name: "countIdErpItem",
        required: true,
        label: "message.return.check_return.family_preco.table.countIdErpItem",
        align: "center",
        field: (row: ModelDetailByFamily) => row.countIdErpItem,
        sortable: true,
      },
      {
        name: "qty",
        required: true,
        label: "message.return.check_return.family_preco.table.qty",
        align: "center",
        field: (row: ModelDetailByFamily) => row.qty,
        sortable: true,
      },
      {
        name: "amount",
        required: true,
        label: "message.return.check_return.family_preco.table.amount",
        align: "center",
        field: (row: ModelDetailByFamily) => row.amount,
        sortable: true,
      }
    );

    const rows = computed(() => {
      return returnStore.getModelDetailByFamilys();
    });

    const checkeds = ref({} as { [key: string]: boolean });

    return {
      columns,
      rows,
      returnStore,
      getReturnPreco,
      filter,
      t,
      checkeds,
      ServiceTable,
    };
  },
  watch: {
    rows(newValue: ModelDetailByFamily[]) {
      this.initCheckeds();
    },
  },
  methods: {
    initCheckeds() {
      this.checkeds = {};
      this.rows.forEach((row) => {
        this.checkeds[row.regroupementFamily] = false;
      });
    },
    checkAllCheckBoxAreChecked() {
      let allChecked = true;
      this.rows.forEach((row) => {
        if (!this.checkeds[row.regroupementFamily]) {
          allChecked = false;
        }
      });
      return allChecked;
    },
  },
});
