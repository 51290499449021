
import { defineComponent, ref } from "vue";
import Separator from "@/components/global/Separator.vue";
import { usePendingStore } from "@/store/pendingStore";
import { useGlobalStore } from "@/store/globalStore";
import { useI18n } from "vue-i18n";
import { ErrorGlobal, ErrorSite, ERROR_TYPE } from "@/type/Error";
import ApiGestionCap from "@/services/ApiGestionCap";
import { router } from "@/router";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { RouteName } from "@/constante/RouterConst";
import { isTokenReady } from "@baloise/vue-keycloak";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import InputDateSite from "@/components/customInput/InputDateSite.vue";
import date from 'quasar/src/utils/date.js';;
import ServiceFormValidation from "@/services/ServiceFormValidation";
import {
  AmazonStatsType,
  ModelGenerateAmazonStats,
} from "@/type/Amazon/AmazonStatsType";
import {
  AMAZ_STATUS_TYPE_FINAL,
  AMAZ_STATUS_TYPE_NOT_FINAL,
  AMAZ_STATUS_DELIVERY,
  AMAZ_STATUS_IMPOSSIBLE_DELIVERY,
  AMAZ_STATUS_LOST,
  AMAZ_STATUS_REFUSED,
  AMAZ_STATUS_DELIVERY_IN_PROGRESS,
  AMAZ_STATUS_TRANSIT,
  AMAZ_STATUS_SHIPPED,
  AMAZ_STATUS_DELAYED,
} from "@/constante/ConstAmazonStatus";
import { InputDateSiteUpdateType } from "@/type/InputDateSiteUpdateType";
import ServiceFormatDate from "@/services/ServiceFormatDate";

export default defineComponent({
  name: "AmazonShippingStats",
  components: {
    ButtonSite,
    Separator,
    BlockMsgError,
    InputDateSite,
  },
  setup() {
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const { t } = useI18n();
    const today = new Date();
    const startDate = new Date().setMonth(today.getMonth() - 1);
    const start = ref(date.formatDate(startDate, "DD/MM/YYYY") as string);
    const end = ref(date.formatDate(today, "DD/MM/YYYY") as string);
    const AmazonStats = ref(null as null | AmazonStatsType[]);
    const loading = ref(false);
    return {
      start,
      end,
      pendingStore,
      globalStore,
      t,
      AmazonStats,
      loading,
      AMAZ_STATUS_TYPE_FINAL,
      AMAZ_STATUS_TYPE_NOT_FINAL,
      AMAZ_STATUS_DELIVERY,
      AMAZ_STATUS_IMPOSSIBLE_DELIVERY,
      AMAZ_STATUS_LOST,
      AMAZ_STATUS_REFUSED,
      AMAZ_STATUS_DELIVERY_IN_PROGRESS,
      AMAZ_STATUS_TRANSIT,
      AMAZ_STATUS_SHIPPED,
      AMAZ_STATUS_DELAYED,
    };
  },
  async mounted() {
    const inputeDateStart = this.$refs.inputeDateStart as any;
    const inputeDateEnd = this.$refs.inputeDateEnd as any;
    inputeDateStart.setDate(this.start);
    inputeDateEnd.setDate(this.end);
    this.pendingStore.$patch({ routerIsPending: true });
    await isTokenReady();
    this.pendingStore.$patch({ routerIsPending: false });
  },
  methods: {
    /**
     * fonction de maj de l'input date start
     */
    updateDateStart(newDate: InputDateSiteUpdateType) {
      this.start = newDate.newDate;
    },
    /**
     * fonction de maj de l'input date end
     */
    updateDateEnd(newDate: InputDateSiteUpdateType) {
      this.end = newDate.newDate;
    },

    checkDates() {
      return (
        ServiceFormValidation.checkIsDateIsFormatedFr(this.start) &&
        ServiceFormValidation.checkIsDateIsFormatedFr(this.end)
      );
    },

    getQuantityByStatusAndTyp(status: string, day: number) {
      if (this.AmazonStats) {
        let data = this.AmazonStats.filter(
          (stat) => stat.nbjJourOuvres === day && stat.status === status
        );
        if (1 === data.length) {
          return data[0];
        }
      }
      return null;
    },

    getFullQuantity() {
      if (this.AmazonStats) {
        let fullQty = 0 as number;
        this.AmazonStats.forEach((stat) => {
          fullQty += stat.nb;
        });
        return fullQty;
      }
      return 0;
    },
    getQuantityPresJ1() {
      if (this.AmazonStats) {
        let fullQty = 0 as number;
        this.AmazonStats.forEach((stat) => {
          fullQty += stat.nbpresj1;
        });
        return fullQty;
      }
      return 0;
    },

    generatePercent(value1: any, value2: any) {
      if (!value1 || !value2) {
        return 0;
      }
      let percent = (value1 * 100) / value2;
      return Number.isInteger(percent) ? percent : Number(percent.toFixed(1));
    },

    async generateStats() {
      this.AmazonStats = null;
      this.loading = true;
      this.globalStore.$patch({ errorMessage: null });
      if (this.checkDates()) {
        try {
          const modelGenerateAmazonStats = {
            start: ServiceFormatDate.formatDateToGb(this.start),
            end: ServiceFormatDate.formatDateToGb(this.end),
          } as ModelGenerateAmazonStats;
          this.AmazonStats = await ApiGestionCap.generateStatsTNTAmazon(
            modelGenerateAmazonStats
          );
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
      } else {
        const errorSite = {
          message: this.t("message.amazon.shipping_stats.error.dates"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
        return;
      }
      this.loading = false;
    },

    async generateStatsFile() {
      this.loading = true;
      this.globalStore.$patch({ errorMessage: null });
      if (this.checkDates()) {
        try {
          const modelGenerateAmazonStats = {
            start: ServiceFormatDate.formatDateToGb(this.start),
            end: ServiceFormatDate.formatDateToGb(this.end),
          } as ModelGenerateAmazonStats;
          await ApiGestionCap.generateFileStatsTNTAmazon(
            modelGenerateAmazonStats
          );
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
      } else {
        const errorSite = {
          message: this.t("message.amazon.shipping_stats.error.dates"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
        return;
      }
      this.loading = false;
    },
  },
});
