/**
 * Service qui contient des fonction de controle de formulaire
 */
export default {
  /**
   * Fonction de controle d'un numero de tel fr au format XX XX XX XX XX
   * @param phoneNumber 
   * @returns 
   */
  isValidePhone(phoneNumber: string): boolean {
    const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    return phoneRegex.test(phoneNumber);
  },

  /**
   * Fonction de controle d'une date au format DD/MM/YYYY
   * @param date 
   * @returns 
   */
  checkIsDateIsFormatedFr(date: string): boolean {
    const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    return dateRegex.test(date);
  },

  /**
   * Fonction de controle d'un code postal au format france métro hors corse mais avec monaco
   * @param zipCode 
   * @returns 
   */
  checkZipCodeIsFranceMetro(zipCode: string): boolean {
    const zipCodeRegex = /^((0[1-9]|[1][0-9]|[2][1-9]|[3-8][0-9]|9[0-5]|)[0-9]{3})$|^98000$/;
    return zipCodeRegex.test(zipCode);
  },

  /**
   * Fonction de controle si le code pays est FR
   * @param country 
   * @returns 
   */
  checkIsCountryFR(country: string) {

    return 'FR' === country;
  },

  /**
  * Fonction si l'email est valide
  * @param email 
  * @returns 
  */
  isValidEmail(email: string) {
    const emailPattern =
      /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    return (
      emailPattern.test(email)
    );
  },
}

