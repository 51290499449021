/**
 * Service qui permet des faire des controle sur les commande amazon
 */

import { AmazonOrderError } from "@/type/Amazon/AmazonDataErrorType";
import { AmazonOrderItem } from "@/type/Amazon/AmazonOrderItemType";
import { AmazonOrder } from "@/type/Amazon/AmazonOrderType";
import {  AMAZ_CODE_ERR_STOCK } from "@/constante/ConstAmazonError";

export default {

    /**
     * Fonction qui controle si l'article à un stock ok dans navision
     * @param Order
     * @param orderItem
     * 
     * @returns boolean
     */
    isStockOrderItemOk(order:AmazonOrder, orderItem: AmazonOrderItem): boolean {
    
        /*** recherche erreur stock ****/
        const ErrorStock = order.orderErrors.filter((orderError : AmazonOrderError) => {
            return (!orderError.isFixed 
                   && null !== orderError.idOrderItem 
                   && orderError.idOrderItem.id == orderItem.id 
                   && AMAZ_CODE_ERR_STOCK == orderError.idRefTypeError.codeSite)
        });
        
        return  ErrorStock.length > 0 ? false : true;
    },

    /**
     * order have adr Error
     * @param Order 
     * @returns 
     */
    haveErrorAdr(order:AmazonOrder) : boolean
    {
       
        const ErrorAdr = order.orderErrors.filter((orderError : AmazonOrderError) => {
            return  !orderError.isFixed && orderError.idRefTypeError.isErrorPostalAdr
        });
        return ErrorAdr.length > 0 ? true : false;
    },

    /**
     * Test Commande haveError by code
     * @param order 
     * @param codeError 
     * @returns 
     */
    haveErrorFromCodeError(order:AmazonOrder, codeError : string) : boolean
    {
        const errorCode = order.orderErrors.filter((orderError : AmazonOrderError) => {
            return !orderError.isFixed && codeError ==  orderError.idRefTypeError.codeSite
        });
        return errorCode.length > 0 ? true : false;
    },

    
    /**
     * Fronction contrôle si la commande va tomber en échec pour l'integration navision
     * @param order 
     * @returns boolean
     */
    checkErrOrder(order: AmazonOrder): boolean {
       const errorBlocks =  order.orderErrors.filter((orderError : AmazonOrderError) => {
            return !orderError.isFixed && orderError.idRefTypeError.blockSendToErp
        });
        return errorBlocks.length > 0 ? true : false ;

    },
}

