
import { defineComponent, ref } from "vue";
import Separator from "@/components/global/Separator.vue";
import { MGRefTypeCustomerGroup } from "@/type/MyGarage/MGRefTypeCustomerGroupType";
import { usePendingStore } from "@/store/pendingStore";
import ApiGestionCap from "@/services/ApiGestionCap";
import { isTokenReady } from "@baloise/vue-keycloak";
import { ModalBtnType } from "@/type/Modal/ModalType";
import BinariStatus from "@/components/global/BinariStatus.vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import InputNumberSite from "@/components/customInput/InputNumberSite.vue";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import { useGlobalStore } from "@/store/globalStore";
import { useI18n } from "vue-i18n";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import QForm from 'quasar/src/components/form/QForm.js';;
import ModalFamilyExcluded from "@/components/mygarage/ModalFamilyExcluded.vue";
import ModalItemTypeErpExcluded from "@/components/mygarage/ModalItemTypeErpExcluded.vue";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import InputTextSite from "@/components/customInput/InputTextSite.vue";

export default defineComponent({
  name: "MGRefTypeCustomerGroup",
  components: {
    Separator,
    BinariStatus,
    TemplateModaleSite,
    ButtonSite,
    BlockMsgError,
    InputNumberSite,
    ModalFamilyExcluded,
    ModalItemTypeErpExcluded,
    InputTextSite,
  },
  setup() {
    const pendingStore = usePendingStore();
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const columns = [
      {
        name: "idErp",
        required: true,
        label: "message.gestion_customer_group_mg.table.idErp",
        align: "left",
        field: (row: MGRefTypeCustomerGroup) => row.idErp,
        sortable: true,
      },
      {
        name: "libelle",
        required: true,
        label: "message.gestion_customer_group_mg.table.libelle",
        align: "left",
        field: (row: MGRefTypeCustomerGroup) => row.libelle,
        sortable: true,
      },
      {
        name: "logo",
        required: true,
        label: "message.gestion_customer_group_mg.table.logo",
        align: "center",
        sortable: false,
      },
      {
        name: "accessExternalDocumentNo",
        required: true,
        label:
          "message.gestion_customer_group_mg.table.accessExternalDocumentNo",
        align: "center",
        field: (row: MGRefTypeCustomerGroup) => row.accessExternalDocumentNo,
        sortable: true,
      },
      {
        name: "limitOrderPriceNeedValidation",
        required: true,
        label:
          "message.gestion_customer_group_mg.table.limitOrderPriceNeedValidation",
        align: "center",
        field: (row: MGRefTypeCustomerGroup) =>
          row.limitOrderPriceNeedValidation,
        sortable: true,
      },
      {
        name: "limitPriceItemCanBuy",
        required: true,
        label: "message.gestion_customer_group_mg.table.limitPriceItemCanBuy",
        align: "center",
        field: (row: MGRefTypeCustomerGroup) => row.limitPriceItemCanBuy,
        sortable: true,
      },
    ];
    const rows = ref([] as MGRefTypeCustomerGroup[]);
    const refTypeCustomerGroupSelected = ref(
      null as null | MGRefTypeCustomerGroup
    );
    const saveButton = ref({
      label: "message.gestion_customer_group_mg.modal.save_btn",
      affBtn: true,
    } as ModalBtnType);
    return {
      pendingStore,
      rows,
      columns,
      refTypeCustomerGroupSelected,
      saveButton,
      filter: ref(""),
      isOpen: ref(false),
      globalStore,
      t,
    };
  },
  /**
   * A la création de la vue recupération des enseigne
   */
  async mounted() {
    await isTokenReady();
    await this.refrechRefTypeCustomerGroup();
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  methods: {
    /**
     * Récupération des enseigne
     */
    async refrechRefTypeCustomerGroup() {
      this.rows = await ApiGestionCap.getRefCustomerGroupMG();
    },
    /**
     * Création d'un model d'enseigne vide pour la modal
     */
    createCustomerGroup() {
      this.globalStore.$patch({ errorMessage: null });
      this.refTypeCustomerGroupSelected = {
        id: null,
        idErp: "",
        libelle: "",
        logo: null,
        accessExternalDocumentNo: false,
        limitOrderPriceNeedValidation: 0,
        limitPriceItemCanBuy: 0,
      };
      this.isOpen = true;
    },
    /**
     * Création d'un model d'enseigne avec une enseigne séléctionné pour la modal
     */
    updateCustomerGroup(refTypeCustomerGroupSelected: MGRefTypeCustomerGroup) {
      this.globalStore.$patch({ errorMessage: null });
      this.refTypeCustomerGroupSelected = {
        id: refTypeCustomerGroupSelected.id,
        idErp: refTypeCustomerGroupSelected.idErp,
        libelle: refTypeCustomerGroupSelected.libelle,
        logo: refTypeCustomerGroupSelected.logo,
        accessExternalDocumentNo:
          refTypeCustomerGroupSelected.accessExternalDocumentNo,
        limitOrderPriceNeedValidation:
          refTypeCustomerGroupSelected.limitOrderPriceNeedValidation,
        limitPriceItemCanBuy: refTypeCustomerGroupSelected.limitPriceItemCanBuy,
      };
      this.isOpen = true;
    },
    /**
     * Création ou maj d'une enseigne a l'action save de la modal
     */
    async modalSave() {
      this.globalStore.$patch({ errorMessage: null });
      const customerGroupForm = this.$refs.customerGroupForm as QForm;
      if (this.refTypeCustomerGroupSelected) {
        //mise à jours d'enseigne
        if (this.refTypeCustomerGroupSelected.id) {
          if (
            !(
              this.refTypeCustomerGroupSelected.idErp ||
              this.refTypeCustomerGroupSelected.libelle ||
              this.refTypeCustomerGroupSelected.accessExternalDocumentNo ||
              this.refTypeCustomerGroupSelected.logo
            )
          ) {
            const errorSite = {
              message: this.t(
                "message.gestion_customer_group_mg.modal.error.update"
              ),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
            return;
          }
        }
        //création d'enseigne
        else {
          if (
            !(
              this.refTypeCustomerGroupSelected.idErp &&
              this.refTypeCustomerGroupSelected.libelle
            )
          ) {
            customerGroupForm.validate();
            const errorSite = {
              message: this.t(
                "message.gestion_customer_group_mg.modal.error.create"
              ),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
            return;
          }
        }
        this.pendingStore.$patch({ routerIsPending: true });
        try {
          await ApiGestionCap.cuRefTypeCustomerGroupMG(
            this.refTypeCustomerGroupSelected
          );
          await this.refrechRefTypeCustomerGroup();
          this.modalClose();
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Suppresion d'une enseigne
     */
    async deleteCustomerGroup(id: number) {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        await ApiGestionCap.deleteRefTypeCustomerGroupMG(id);
        await this.refrechRefTypeCustomerGroup();
        this.modalClose();
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    modalClose() {
      this.isOpen = false;
    },
    /**
     * mise à jours de la limite de prix nécessitant une validation
     */
    updateLimitOrderPriceNeedValidation(newQty: number) {
      if (null !== this.refTypeCustomerGroupSelected) {
        this.refTypeCustomerGroupSelected.limitOrderPriceNeedValidation =
          newQty;
      }
    },
    /**
     * mise à jours de la limite de prix autoriser en achat
     */
    updateLimitPriceItemCanBuy(newQty: number) {
      if (null !== this.refTypeCustomerGroupSelected) {
        this.refTypeCustomerGroupSelected.limitPriceItemCanBuy = newQty;
      }
    },
  },
});
