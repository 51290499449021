import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-4 blockSheets" }
const _hoisted_6 = { class: "row Sheet" }
const _hoisted_7 = { class: "col-md-12 titleSheet" }
const _hoisted_8 = { class: "col-md-12 sheet" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = {
  key: 0,
  class: "col-md-12"
}
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-md-6" }
const _hoisted_13 = { class: "col-md-6" }
const _hoisted_14 = { class: "right" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-md-6" }
const _hoisted_17 = { class: "col-md-6" }
const _hoisted_18 = { class: "right" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-md-6" }
const _hoisted_21 = { class: "col-md-6" }
const _hoisted_22 = { class: "right" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col-md-6" }
const _hoisted_25 = { class: "col-md-6" }
const _hoisted_26 = { class: "right" }
const _hoisted_27 = { class: "row justify-center" }
const _hoisted_28 = { class: "col-8 blockLeft" }
const _hoisted_29 = { class: "row" }
const _hoisted_30 = { class: "col-12" }
const _hoisted_31 = { class: "row" }
const _hoisted_32 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCustomerData = _resolveComponent("HeaderCustomerData")!
  const _component_HeaderStepButton = _resolveComponent("HeaderStepButton")!
  const _component_CustomerSheet = _resolveComponent("CustomerSheet")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_TableModelDetailByFamily = _resolveComponent("TableModelDetailByFamily")!
  const _component_TableDetailItemPreco = _resolveComponent("TableDetailItemPreco")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderCustomerData),
    (_ctx.getHeaderStepReturn)
      ? (_openBlock(), _createBlock(_component_HeaderStepButton, {
          key: 0,
          btns: _ctx.getHeaderStepReturn
        }, null, 8, ["btns"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.return_validation")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.getCustomer)
          ? (_openBlock(), _createBlock(_component_CustomerSheet, {
              key: 0,
              customer: _ctx.getCustomer
            }, null, 8, ["customer"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("message.return.return_validation.shipping_sheet.title")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.getReturnData?.idRefCarrier?.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                          "message.return.return_validation.shipping_sheet.carrier_name"
                        )), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.getReturnData.idRefCarrier.name), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                          "message.return.return_validation.shipping_sheet.qty_package"
                        )), 1)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.getReturnData.numberOfPackages), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                          "message.return.return_validation.shipping_sheet.contact"
                        )), 1)
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.getReturnData.contactName), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                          "message.return.return_validation.shipping_sheet.phone"
                        )), 1)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.getReturnData.contactTel), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_27, [
              (_ctx.getReturnData?.idRefCarrier?.id)
                ? (_openBlock(), _createBlock(_component_ButtonSite, {
                    key: 0,
                    onClick: _ctx.validateReturn,
                    typeButton: 'PASTEL_VALIDATE',
                    disable: _ctx.isPending,
                    loading: _ctx.isPending
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(
                    "message.return.return_validation.shipping_sheet.btn_validate"
                  )), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disable", "loading"]))
                : (_openBlock(), _createBlock(_component_ButtonSite, {
                    key: 1,
                    onClick: _ctx.redirectToSelectShipping,
                    typeButton: 'PASTEL_DANGER',
                    disable: _ctx.isPending
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(
                    "message.return.return_validation.shipping_sheet.btn_set_shipping"
                  )), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick", "disable"]))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createVNode(_component_TableModelDetailByFamily, {
              ref: "TableModelDetailByFamily",
              withCheckBox: false,
              isOpened: true
            }, null, 512)
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_component_TableDetailItemPreco)
          ])
        ])
      ])
    ])
  ]))
}