import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row expandGlobalReturn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_expansion_item, {
      "expand-separator": "",
      "default-opened": _ctx.isOpened
    }, {
      header: _withCtx(() => [
        _createVNode(_component_q_item_section, {
          class: _normalizeClass([_ctx.addHeaderLeft ? 'col-md-2' : 'col-md-11', "headerRight"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }, 8, ["class"]),
        (_ctx.addHeaderLeft)
          ? (_openBlock(), _createBlock(_component_q_item_section, {
              key: 0,
              class: "col-md-9 headerLeft"
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "headerLeft")
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["default-opened"])
  ]))
}