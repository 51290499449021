import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row blockCa" }
const _hoisted_2 = { class: "col-md-4 text-center" }
const _hoisted_3 = { class: "row justify-center text-center" }
const _hoisted_4 = { class: "row justify-center text-center value" }
const _hoisted_5 = { class: "row justify-center text-center" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "row justify-center text-center" }
const _hoisted_8 = { class: "row justify-center text-center value" }
const _hoisted_9 = { class: "row justify-center text-center" }
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "row justify-center text-center" }
const _hoisted_12 = { class: "row justify-center text-center value" }
const _hoisted_13 = { class: "row justify-center text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_q_icon, { name: "shopping_cart" })
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.modelCustomerOrderCa.orderCount), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("message.customer.customer_orders.orderCount", {
            year: _ctx.actualYear,
          })), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_q_icon, { name: "euro_symbol" })
      ]),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$n(_ctx.modelCustomerOrderCa.ca, "price")), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("message.customer.customer_orders.ca", {
            year: _ctx.actualYear,
          })), 1)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_q_icon, { name: "shopping_basket" })
      ]),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$n(_ctx.getAverageBasketPrice(), "price")), 1),
      _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("message.customer.customer_orders.averageOrder", {
            year: _ctx.actualYear,
          })), 1)
    ])
  ]))
}