import { User } from '@/type/UserType'
import { defineStore } from 'pinia'

export const useUserStore = defineStore({
    id: 'userStore',
    state: () => ({
        user: null as User | null,
    }),
    getters: {
        getUser(state) {
            return state.user;
        },
    },
    actions: {
    }
})