import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "containerSite" }
const _hoisted_2 = { class: "containerPage" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "row header_site" }
const _hoisted_5 = { class: "containerFooter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PendingSpinnerFullCover = _resolveComponent("PendingSpinnerFullCover")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PendingSpinnerFullCover, { isPending: _ctx.getRouterIsPending }, null, 8, ["isPending"]),
    _createVNode(_component_PendingSpinnerFullCover, {
      isPending: _ctx.getFirstLoading,
      isFirstLoading: true
    }, null, 8, ["isPending"]),
    (_ctx.initIsEnded)
      ? (_openBlock(), _createBlock(_component_q_layout, {
          key: 0,
          view: "hHh lpR fFf"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_Header, {
                      username: _ctx.email,
                      title: _ctx.title,
                      onLogoutEvent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
                    }, {
                      part_right: _withCtx(() => []),
                      _: 1
                    }, 8, ["username", "title"])
                  ]),
                  _createVNode(_component_q_page_container, null, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_router_view, {
                        key: _ctx.$route.path
                      }))
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Footer)
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}