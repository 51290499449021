import { resolveComponent as _resolveComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    modelValue: _ctx.qty,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.qty) = $event)),
    modelModifiers: { number: true },
    dense: "",
    square: "",
    outline: "",
    type: "number",
    min: "0",
    filled: "",
    class: "inputNumber",
    onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateQty())),
    onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.updateQty()), ["enter"])),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addFocus())),
    ref: 'quantity_' + _ctx.id
  }, null, 8, ["modelValue"]))
}