
import { defineComponent, ref } from "vue";
import ApiGestionCap from "@/services/ApiGestionCap";
import Separator from "@/components/global/Separator.vue";
import { RefAmazonShippingStatus } from "@/type/Amazon/RefAmazonShippingStatus";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import InputDateSite from "@/components/customInput/InputDateSite.vue";
import { AmazonOrder } from "@/type/Amazon/AmazonOrderType";
import { RouteName } from "@/constante/RouterConst";
import { router } from "@/router";
import { InputDateSiteUpdateType } from "@/type/InputDateSiteUpdateType";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import {
  AmazonUpdateStatusShipRemainder,
  AmamzonUpdateGroupOrderCarrierStatusShips,
} from "@/type/Amazon/AmazonUpdateStatusShipRemainder";
import { usePendingStore } from "@/store/pendingStore";
import ServiceFormatDate from "@/services/ServiceFormatDate";

export default defineComponent({
  name: "AmazonManageShipStatusRemainder",
  components: {
    ButtonSite,
    InputDateSite,
    Separator,
  },
  setup() {
    const orders = ref([] as AmazonOrder[]);
    const amazonFinalStatus = ref([] as RefAmazonShippingStatus[]);
    const formError = ref(false as boolean);
    const disableBtnForm = ref(true as boolean);
    const pendingStore = usePendingStore();

    /*** array columns ****/
    const columns = [
      {
        name: "Type",
        required: true,
        label: "message.amazon.manage_shipping_status_remainder.table.type",
        align: "left",
        field: (row: AmazonOrder) => row.idRefClientErp.libelle,
        sortable: true,
      },
      {
        name: "ShippingDate",
        required: true,
        label:
          "message.amazon.manage_shipping_status_remainder.table.shipping_date",
        align: "left",
        field: (row: AmazonOrder) =>
          row.formatedErpShippingDate /**** TODO shipping date  *****/,
        sortable: true,
      },
      {
        name: "AmazonOrderId",
        required: true,
        label:
          "message.amazon.manage_shipping_status_remainder.table.amazon_order_id",
        align: "left",
        field: (row: AmazonOrder) => row.amazonId,
        sortable: true,
      },
      {
        name: "Carrier",
        required: true,
        label: "message.amazon.manage_shipping_status_remainder.table.carrier",
        align: "left",
        field: (row: AmazonOrder) => row.idRefTypeCarrier.libelle,
        sortable: true,
      },
      {
        name: "ShippingCode",
        required: true,
        label:
          "message.amazon.manage_shipping_status_remainder.table.full_packing_no",
        align: "left",
        field: (row: AmazonOrder) => row.erpFullPackingNo,
        sortable: true,
      },
      {
        name: "newStatus",
        required: true,
        label:
          "message.amazon.manage_shipping_status_remainder.table.new_status",
        align: "left",
        sortable: false,
      },
      {
        name: "newDateStatus",
        required: true,
        label:
          "message.amazon.manage_shipping_status_remainder.table.new_date_status",
        align: "left",
        sortable: false,
      },
    ];

    return {
      orders,
      amazonFinalStatus,
      columns,
      formError,
      disableBtnForm,
      pendingStore,
      filter: ref(""),
    };
  },
  async mounted() {
    /*** recupération des commandes */
    this.pendingStore.$patch({ routerIsPending: true });
    await this.initAmazonOrderShipRemainder();
    await this.initAmazonFinalStatus();
    this.pendingStore.$patch({ routerIsPending: false });
  },
  methods: {
    checkInputDate(order: AmazonOrder) {
      const inputDateSite = this.getInputDate(order.id);
      if (order.idRefAmazonShippingStatus) {
        inputDateSite.validate();
      } else {
        inputDateSite.resetValidation();
      }
      this.formIsValid();
    },

    /*** control all form ***/
    formIsValid(): boolean {
      let formIsOk = true;
      const btnForm = this.$refs.btnForm as any;

      let nbNotStatusEmpty = 0;

      for (let i = 0; i < this.orders.length; i++) {
        const order = this.orders[i];
        if (order.idRefAmazonShippingStatus) {
          nbNotStatusEmpty++;
          if (
            !order.formatedAmazonDateStatus ||
            (order.formatedAmazonDateStatus &&
              !ServiceFormValidation.checkIsDateIsFormatedFr(
                order.formatedAmazonDateStatus
              ))
          ) {
            formIsOk = false;
          }
        }
      }

      formIsOk = formIsOk ? 0 != nbNotStatusEmpty : formIsOk;
      this.disableBtnForm = !formIsOk;

      return formIsOk;
    },
    /**** RECUPERATION element date  ****/
    getInputDate(id: number): any {
      return this.$refs["date_" + id] as any;
    },

    updateDateStatus(inputDateSiteUpdateType: InputDateSiteUpdateType) {
      const order = this.orders.filter(function (order: AmazonOrder) {
        return order.id === inputDateSiteUpdateType.idElement;
      });

      if (order.length > 0) {
        order[0].formatedAmazonDateStatus = inputDateSiteUpdateType.newDate;
      }
      this.formIsValid();
    },

    /**
     * recupération des commandes
     */
    async initAmazonOrderShipRemainder() {
      try {
        this.orders =
          (await ApiGestionCap.getOrderRemainderCarrier()) as AmazonOrder[];
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
    },
    /*** recuperation des status de transport finaux d'Amazon */
    async initAmazonFinalStatus() {
      try {
        this.amazonFinalStatus =
          (await ApiGestionCap.getAmazonShippingFinalStatus()) as RefAmazonShippingStatus[];
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
    },

    async saveManageShipStatusRemainder() {
      if (this.formIsValid()) {
        this.pendingStore.$patch({ routerIsPending: true });
        this.formError = false;
        const amamzonUpdateGroupOrderCarrierStatusShips = {
          updateOrderCarrierStatusShips: [],
        } as AmamzonUpdateGroupOrderCarrierStatusShips;

        this.orders.forEach((order: AmazonOrder) => {
          if (
            order.idRefAmazonShippingStatus &&
            order.formatedAmazonDateStatus
          ) {
            const amazonUpdateStatusShipRemainder = {
              idOrder: order.id,
              idRefAmazonShippingStatus: order.idRefAmazonShippingStatus.id,
              dateStatus: ServiceFormatDate.formatDateToGb(
                order.formatedAmazonDateStatus
              ),
            } as AmazonUpdateStatusShipRemainder;

            amamzonUpdateGroupOrderCarrierStatusShips.updateOrderCarrierStatusShips.push(
              amazonUpdateStatusShipRemainder
            );
          }
        });

        this.orders = [];
        this.orders = (await ApiGestionCap.updateOrderRemainderShipStatus(
          amamzonUpdateGroupOrderCarrierStatusShips
        )) as AmazonOrder[];
        this.disableBtnForm = true;
        this.pendingStore.$patch({ routerIsPending: false });
      } else {
        this.formError = true;
      }
    },
  },
});
