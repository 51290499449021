export const ERROR_TYPE: { [key: string]: string } = {
    GLOBAL: 'GLOBAL',
}


export type ErrorSite = {
    message: string,
    type: string,
}

export type ErrorGlobal = {
    message: string
}


