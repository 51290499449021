
import { defineComponent, ref } from "vue";
import ApiGestionCap from "@/services/ApiGestionCap";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import { MGRefFamilyItem } from "@/type/MyGarage/MGRefFamilyItem";
import { ADDMGRefFamilyItem } from "@/type/MyGarage/MGFamilyExcluded";

export default defineComponent({
  name: "ModalAddFamilyExcludedToCg",
  emits: ["refrechFamilyExcludeds"],
  props: {
    idRefTypeCustomerGroup: {
      type: Number,
      required: true,
    },
  },
  components: {
    ButtonSite,
    TemplateModaleSite,
  },
  setup() {
    const pendingStore = usePendingStore();
    const refFamilyItem = ref([] as MGRefFamilyItem[]);
    const selectRefFamilyItem = ref(null as null | MGRefFamilyItem);
    const saveButton = ref({
      label: "message.gestion_customer_group_mg.family_excluded.modal.add_send",
      affBtn: true,
    } as ModalBtnType);
    return {
      saveButton,
      refFamilyItem,
      selectRefFamilyItem,
      isOpen: ref(false),
      filter: ref(""),
      pendingStore,
    };
  },
  methods: {
    /**
     * Fonction qui va récupérer les famille qu'il est possible d'ajouter en exclusion a l'enseigne envoyer en props idRefTypeCustomerGroup
     */
    async refrechRefFamilyItem() {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        this.refFamilyItem = await ApiGestionCap.getRefFamilyItemCanAddToCg(
          this.idRefTypeCustomerGroup
        );
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Fonction qui permet d'ajouter une famille d'exclusion a l'enseigne envoyer en props idRefTypeCustomerGroup
     */
    async addItemTypeErpExcluded() {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        if (this.selectRefFamilyItem) {
          const modelAddItemTypeExcluded = {
            idRefTypeCustomerGroup: this.idRefTypeCustomerGroup,
            idRefFamilyItem: this.selectRefFamilyItem.id,
          } as ADDMGRefFamilyItem;
          await ApiGestionCap.addFamilyExcludeds(modelAddItemTypeExcluded);
          this.$emit("refrechFamilyExcludeds");
          this.modalClose();
        }
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    async modalOpen() {
      await this.refrechRefFamilyItem();
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
  },
});
