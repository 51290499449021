
import { defineComponent, computed, ref } from "vue";
import { ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";

export default defineComponent({
  name: "BlockMsgError",
  setup() {
    const globalStore = useGlobalStore();
    const getErrorMessage = computed(() => {
      return globalStore.getErrorMessage;
    });
    const errorType = ref(ERROR_TYPE);
    return {
      getErrorMessage,
      errorType,
    };
  },
});
