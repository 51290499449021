/**
 * Fichier des constante des code d'info adresse dans l'erp et qui sert à recupéré les longueurs max des champs de la base
 */


const CODE_RS = "rs";
const CODE_CONTACT = "contact";
const CODE_ADDR1 = "adr1";
const CODE_ADDR2 = "adr2";
const CODE_ADDR3 = "adr3";
const CODE_ZIPCODE = "cp";
const CODE_CITY = "ville";
const CODE_PHONE = "tel";
const CODE_EMAIL = "email";


export {
    CODE_RS,
    CODE_CONTACT,
    CODE_ADDR1,
    CODE_ADDR2,
    CODE_ADDR3,
    CODE_ZIPCODE,
    CODE_CITY,
    CODE_PHONE,
    CODE_EMAIL
};