
import { defineComponent } from "vue";

export default defineComponent({
  name: "BlockAddress",
  props: {
    name: String,
    address: String,
    address2: String,
    address3: String,
    postCode: String,
    city: String,
  },
});
