/**
 * Service qui permet de faire des alert quasar
 */

import Dialog from 'quasar/src/plugins/Dialog.js';;
import { i18n } from '@/translations';
import { toRaw } from 'vue';
const { t } = i18n.global;

const prompt = (message: string, title: string) => {
  return Dialog.create({
    title,
    class: 'PROMPT',
    message,
    prompt: {
      model: '',
      type: "text",
    },
    cancel: true,
    persistent: true,
  });
};

const alert = (message: string) => {
  return Dialog.create({
    title: t('message.dialog.alert'),
    class: 'DANGER',
    message,
    cancel: false,
    persistent: true,
  });
}

const comfirm = (message: string,  html?: boolean) => {
  return Dialog.create({
    title: t('message.dialog.comfirm'),
    class: 'PRIMARY',
    message,
    html: html ?? false,
    cancel: true,
    persistent: true,
  });
}

const alertReturn = (message: string, title?: string , html?: boolean) => {
  return Dialog.create({
    title: title ?? t('message.dialog.alert'),
    class: 'BUSINESS',
    message,
    html: html ?? false,
    cancel: false,
    persistent: true,
  });
}

const infoReturn = (message: string, title?: string) => {
  return Dialog.create({
    title: title ?? t('message.dialog.info'),
    class: 'INFO',
    message,
    cancel: false,
    persistent: true,
  });
}




export default {
  prompt,
  alert,
  comfirm,
  alertReturn,
  infoReturn,
};