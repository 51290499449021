import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row blockCheckReturnOrder" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "col-8 blockLeft" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-12 blockTable" }
const _hoisted_10 = { class: "col-12 blockTable" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12 blockNextStep" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCustomerData = _resolveComponent("HeaderCustomerData")!
  const _component_HeaderStepButton = _resolveComponent("HeaderStepButton")!
  const _component_TableModelDetailByFamily = _resolveComponent("TableModelDetailByFamily")!
  const _component_TableDetailItemPreco = _resolveComponent("TableDetailItemPreco")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderCustomerData),
    (_ctx.getHeaderStepReturn)
      ? (_openBlock(), _createBlock(_component_HeaderStepButton, {
          key: 0,
          btns: _ctx.getHeaderStepReturn
        }, null, 8, ["btns"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.check_return_order")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "blockGeneralPackingInstruction",
          innerHTML: _ctx.$t('message.return.check_return.general_packing_instruction')
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_TableModelDetailByFamily, {
              ref: "TableModelDetailByFamily",
              withCheckBox: true,
              isOpened: true
            }, null, 512)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_TableDetailItemPreco)
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ButtonSite, {
              onClick: _ctx.nextStep,
              typeButton: 'PASTEL_VALIDATE',
              "icon-right": 'navigate_next'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_return.btn.next_step")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ])
    ])
  ]))
}