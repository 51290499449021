
import {
  AmazonOrder,
  ResponseRemoveOrder,
} from "@/type/Amazon/AmazonOrderType";
import { defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { usePendingStore } from "@/store/pendingStore";
import ApiGestionCap from "@/services/ApiGestionCap";
import { RouteName } from "@/constante/RouterConst";
import { router } from "@/router";

export default defineComponent({
  name: "ModalRemoveOrder.vue",
  components: {
    TemplateModaleSite,
  },
  emits: ["removeOrder"],
  setup() {
    const idOrder = ref(null as AmazonOrder | null);
    const isOpen = ref(false as boolean);
    const isPending = ref(false as boolean);
    const saveButton = ref({
      label: "message.amazon.order.remove.modal.btn_save",
      affBtn: true,
    } as ModalBtnType);

    const pendingStore = usePendingStore();

    return {
      idOrder,
      isOpen,
      saveButton,
      pendingStore,
      isPending,
    };
  },
  methods: {
    /** ouverure de la Modal **/
    modalOpen(idOrder: AmazonOrder) {
      this.idOrder = idOrder;
      this.isOpen = true;
    },
    async removeOrder() {
      this.isPending = true;
      let reponseRemoveOrder = {
        orderRemove: false,
      } as ResponseRemoveOrder;
      try {
        if (this.idOrder) {
          reponseRemoveOrder = await ApiGestionCap.removeOrderAmaz(
            this.idOrder.id
          );
        }
        if (!reponseRemoveOrder.orderRemove) {
          throw new Error("Erreur lors de la suppresion de la commande");
        }
        this.$emit("removeOrder", this.idOrder);
        this.idOrder = null;
        this.isPending = false;
        this.modalClose();
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
    },

    modalClose() {
      this.isOpen = false;
    },
  },
});
