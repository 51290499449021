
import { defineComponent } from "vue";

export default defineComponent({
  name: "PendingSpinnerFullCover",
  props: {
    classSpinner: String,
    isPending: {
      type: Boolean,
      required: true,
    },
    isFirstLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
