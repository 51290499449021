import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row text-center items-baseline" }
const _hoisted_5 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_6 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_7 = { class: "col-md-2 col-sm-12 col-xs-12 blockForm" }
const _hoisted_8 = { class: "col-md-2 col-sm-12 col-xs-12 blockForm" }
const _hoisted_9 = { class: "col-md-2 col-sm-12 col-xs-12 blockForm" }
const _hoisted_10 = { class: "row text-center items-baseline" }
const _hoisted_11 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_12 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_13 = { class: "col-md-3 col-sm-12 col-xs-12 blockForm" }
const _hoisted_14 = { class: "row justify-center" }
const _hoisted_15 = { class: "col-md-12" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 0 }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 0,
  class: "delete"
}
const _hoisted_24 = { class: "label" }
const _hoisted_25 = {
  key: 1,
  class: "row justify-between status"
}
const _hoisted_26 = { class: "col-12 blockLinearProgressBar" }
const _hoisted_27 = { class: "absolute-full flex flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_InputFileSite = _resolveComponent("InputFileSite")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_ExportsAmazon = _resolveComponent("ExportsAmazon")!
  const _component_InputDateSite = _resolveComponent("InputDateSite")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.amazon_orders")), 1)
      ])
    ]),
    _createVNode(_component_Separator),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_InputFileSite, {
          modelValue: _ctx.csv,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.csv) = $event)),
          label: _ctx.$t('message.amazon.order_batch.import_file_csv')
        }, null, 8, ["modelValue", "label"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ButtonSite, {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.uploadOrders())),
          typeButton: 'INFO'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order_batch.import_btn")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ExportsAmazon)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ButtonSite, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.redirectToAmazonShippingStats())),
          typeButton: 'INFO'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order_batch.shipping_stats")), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_ButtonSite, {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openManageShipStatusRemainder())),
          typeButton: 'INFO'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.router.title.amazon_manage_shipping_remainder")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_InputDateSite, {
          onUpdateDate: _ctx.updateDateStart,
          ref: "inputeDateStart"
        }, null, 8, ["onUpdateDate"])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_InputDateSite, {
          onUpdateDate: _ctx.updateDateEnd,
          ref: "inputeDateEnd"
        }, null, 8, ["onUpdateDate"])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_ButtonSite, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchOrderBatchs(null))),
          typeButton: 'INFO'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.btn.search")), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_BlockMsgError)
      ])
    ]),
    _createVNode(_component_Separator),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_q_table, {
          ref: 'tableOrderBatch',
          rows: _ctx.rows,
          "rows-per-page-options": [10, 20, 50, 100],
          columns: _ctx.columns,
          "row-key": "id",
          class: "tableQuasar",
          "wrap-cells": "",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pagination) = $event)),
          "binary-state-sort": "",
          loading: _ctx.loading,
          onRequest: _ctx.onRequest
        }, {
          "top-left": _withCtx((props) => [
            _createVNode(_component_q_btn, {
              flat: "",
              round: "",
              icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
              onClick: props.toggleFullscreen,
              class: "q-ml-md"
            }, null, 8, ["icon", "onClick"])
          ]),
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          loading: _withCtx(() => [
            _createVNode(_component_q_inner_loading, {
              showing: "",
              color: "primary"
            })
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, {
              props: props,
              onClick: ($event: any) => (
                props.row.enableDelete || _ctx.disableOrder(props.row)
                  ? null
                  : _ctx.redirectToAmazonDetailOrderBatch(props.row)
              ),
              class: _normalizeClass(_ctx.disableOrder(props.row) ? 'processing' : 'btn')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "id",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.id), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "nameUserSite",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.idUserSite.usernameKeycloak), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "timestampDateImport",
                  props: props
                }, {
                  default: _withCtx(() => [
                    (props.row.formatedDateImport)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.$d(props.row.formatedDateImport, "long", "fr")), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "timestampDateIntegrationStart",
                  props: props
                }, {
                  default: _withCtx(() => [
                    (props.row.formatedDateIntegrationStart)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.$d(props.row.formatedDateIntegrationStart, "long", "fr")), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "timestampDateExportPackageShipped",
                  props: props
                }, {
                  default: _withCtx(() => [
                    (props.row.formatedDateExportPackageShipped)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.$d(props.row.formatedDateExportPackageShipped, "long", "fr")), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "timestampDateExportPackageCurrentDelivery",
                  props: props
                }, {
                  default: _withCtx(() => [
                    (props.row.formatedDateExportPackageCurrentDelivery)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$d(
                      props.row.formatedDateExportPackageCurrentDelivery,
                      "long",
                      "fr"
                    )), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "timestampDateExportPackageTntStatus",
                  props: props
                }, {
                  default: _withCtx(() => [
                    (props.row.formatedDateExportPackageTntStatus)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$d(
                      props.row.formatedDateExportPackageTntStatus,
                      "long",
                      "fr"
                    )), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "status",
                  props: props,
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    (props.row.enableDelete)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                          _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t("message.amazon.order_batch.delete")), 1),
                          _createVNode(_component_ButtonSite, {
                            onClick: ($event: any) => (_ctx.deleteOrderBatch(props.row.id)),
                            classIcon: 'delete_forever',
                            typeButton: 'WARNING',
                            dense: ""
                          }, null, 8, ["onClick"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createVNode(_component_q_linear_progress, {
                              color: 
                        props.row.ordersIntegratedCount ===
                        props.row.ordersCount
                          ? 'positive'
                          : 'warning'
                      ,
                              size: "30px",
                              value: 
                        props.row.ordersIntegratedCount / props.row.ordersCount
                      
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_27, [
                                  _createVNode(_component_q_badge, {
                                    color: "white",
                                    "text-color": "primary",
                                    label: 
                            props.row.ordersIntegratedCount +
                            '/' +
                            props.row.ordersCount
                          
                                  }, null, 8, ["label"])
                                ])
                              ]),
                              _: 2
                            }, 1032, ["color", "value"])
                          ])
                        ]))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "duplicate",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_badge, {
                      color: "negative",
                      "text-color": "white",
                      label: props.row.duplicateOrdersCount
                    }, null, 8, ["label"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1032, ["props", "onClick", "class"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
      ])
    ])
  ]))
}