import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 blockForm" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "row text-center" }
const _hoisted_7 = {
  key: 0,
  class: "col-12 blockForm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_q_table, {
      ref: 'tableExcludedData',
      filter: _ctx.filter,
      rows: _ctx.rows,
      "rows-per-page-options": [10, 20, 50, 100],
      columns: _ctx.columns,
      "row-key": "id",
      class: "tableQuasar",
      "wrap-cells": ""
    }, {
      "top-left": _withCtx((props) => [
        _createVNode(_component_q_btn, {
          flat: "",
          round: "",
          icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
          onClick: props.toggleFullscreen,
          class: "q-ml-md"
        }, null, 8, ["icon", "onClick"]),
        _createVNode(_component_ButtonSite, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createExcluded())),
          typeButton: 'ACTION'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("message.modal_create_update_excluded.btn_create")), 1)
          ]),
          _: 1
        })
      ]),
      "top-right": _withCtx(() => [
        _createVNode(_component_q_input, {
          outlined: "",
          modelValue: _ctx.filter,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event)),
          label: _ctx.$t('message.search.searchGlobal')
        }, {
          append: _withCtx(() => [
            (_ctx.filter !== '')
              ? (_openBlock(), _createBlock(_component_q_icon, {
                  key: 0,
                  name: "close",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter = '')),
                  class: "cursor-pointer"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_q_icon, { name: "search" })
          ]),
          _: 1
        }, 8, ["modelValue", "label"])
      ]),
      header: _withCtx((props) => [
        _createVNode(_component_q_tr, { props: props }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
              return (_openBlock(), _createBlock(_component_q_th, {
                key: col.name,
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                ]),
                _: 2
              }, 1032, ["props"]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["props"])
      ]),
      body: _withCtx((props) => [
        _createVNode(_component_q_tr, {
          props: props,
          onClick: ($event: any) => (_ctx.updateExcluded(props.row)),
          class: "btn"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_td, {
              key: "id",
              props: props
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.id), 1)
              ]),
              _: 2
            }, 1032, ["props"]),
            _createVNode(_component_q_td, {
              key: "code",
              props: props
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(props.row.code), 1)
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 2
        }, 1032, ["props", "onClick"])
      ]),
      _: 1
    }, 8, ["filter", "rows", "columns"]),
    _createVNode(_component_TemplateModaleSite, {
      saveButton: _ctx.saveButton,
      idModal: "modalCreateUpdateExcluded",
      labelModal: _ctx.$t('message.modal_create_update_excluded.title'),
      isOpen: _ctx.isOpen,
      width: 'MEDIUM',
      onModal_close: _ctx.modalClose,
      onModal_save: _ctx.modalSave
    }, {
      modalTitle: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("message.modal_create_update_excluded.title")), 1)
      ]),
      modalBody: _withCtx(() => [
        _createVNode(_component_BlockMsgError),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_InputTextSite, {
                  modelValue: _ctx.excludedSelected.code,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.excludedSelected.code) = $event)),
                  label: _ctx.$t('message.modal_create_update_excluded.form.code'),
                  "lazy-rules": "",
                  rules: [
                  (val) =>
                    !!val ||
                    _ctx.$t(
                      'message.modal_create_update_excluded.form.validator.code.not_null'
                    ),
                ]
                }, null, 8, ["modelValue", "label", "rules"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.excludedSelected.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_ButtonSite, {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteExcluded(_ctx.excludedSelected.id))),
                      classIcon: 'delete_forever',
                      typeButton: 'WARNING',
                      disable: _ctx.getRouterIsPending
                    }, null, 8, ["disable"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["saveButton", "labelModal", "isOpen", "onModal_close", "onModal_save"])
  ], 64))
}