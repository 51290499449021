
import { useReturnStore } from "@/store/returnStore";
import { computed, defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import ApiGestionCap from "@/services/ApiGestionCap";
import { useI18n } from "vue-i18n";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import { ModelResponseStatus } from "@/type/Return/ReturnType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";
import InputTextSite from "@/components/customInput/InputTextSite.vue";

export default defineComponent({
  name: "ModalReturnFile",
  components: {
    TemplateModaleSite,
    BlockMsgError,
    InputTextSite,
  },
  props: {},
  setup() {
    const globalStore = useGlobalStore();
    const { t } = useI18n();
    const returnStore = useReturnStore();

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: true,
    } as ModalBtnType);

    const group = ref("pdf");
    const options = ref([
      {
        label: t("message.return.select_return.modal_return_file.format_pdf"),
        value: "pdf",
      },
      {
        label: t("message.return.select_return.modal_return_file.format_excel"),
        value: "xlsx",
      },
    ]);

    const email = ref("");
    const isEmail = ref(false);

    const isPending = ref(false as boolean);
    const disableElement = computed(() => {
      return isPending.value;
    });

    const typeOptions = [
      {
        label: t("message.return.select_return.modal_return_file.type.all"),
        value: 1,
      },
      {
        label: t("message.return.select_return.modal_return_file.type.preco"),
        value: 2,
      },
      {
        label: t("message.return.select_return.modal_return_file.type.selected"),
        value: 3,
      },
    ];

    const typeMode = ref(typeOptions[0]);

    return {
      globalStore,
      isOpen,
      saveButton,
      getReturnData,
      getCustomer,
      group,
      options,
      ServiceFormValidation,
      email,
      t,
      isEmail,
      isPending,
      disableElement,
      typeOptions,
      typeMode,
    };
  },

  methods: {
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    modalOpen(isEmail: boolean) {
      this.globalStore.$patch({ errorMessage: null });
      const btnLabel = isEmail
        ? "message.return.select_return.modal_return_file.send"
        : "message.return.select_return.modal_return_file.print";
      this.saveButton.label = btnLabel;
      this.isEmail = isEmail;
      if (isEmail) {
        this.email = this.getCustomer?.email ?? "";
      }
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
    /**
     * fonction qui genere le fichier de retour
     */
    async generateReturnFile() {
      this.isPending = true;
      this.globalStore.$patch({ errorMessage: null });
      if (this.getCustomer && this.getReturnData) {
        try {
          const formatFile = this.group === "pdf" ? 1 : 0;
          let modelResponseStatus = {} as ModelResponseStatus;
          if (this.isEmail) {
            modelResponseStatus = await ApiGestionCap.sendFileReturnItemReco(
              this.getReturnData.id,
              this.email,
              formatFile,
              this.typeMode.value
            );
            if (modelResponseStatus.success) {
              this.isPending = false;
              this.modalClose();
            } else {
              const errorSite = {
                message: this.t(
                  "message.return.select_return.modal_return_file.error_global"
                ),
                type: ERROR_TYPE.GLOBAL,
              } as ErrorSite;
              this.globalStore.$patch({
                errorMessage: errorSite,
              });
            }
          } else {
            await ApiGestionCap.generateFileReturnItemReco(
              this.getReturnData.id,
              formatFile,
              this.typeMode.value
            );
            this.isPending = false;
            this.modalClose();
          }
        } catch (error) {
          this.isPending = false;
          router.push({ name: RouteName.Error });
        }
      }
    },
  },
});
