import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = {
  key: 0,
  class: "row"
}
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "libelle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCustomerData = _resolveComponent("HeaderCustomerData")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderCustomerData),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.customer_purchase")), 1)
      ])
    ]),
    _createVNode(_component_BlockMsgError),
    (_ctx.rows.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_q_table, {
              ref: 'tableCustomerPurchase',
              rows: _ctx.rows,
              columns: _ctx.columns,
              "row-key": "id",
              class: "tableBusiness",
              "hide-pagination": "",
              "hide-bottom": "",
              "wrap-cells": "",
              "rows-per-page-options": [0],
              filter: _ctx.filter,
              "filter-method": _ctx.filterData,
              separator: 'cell'
            }, {
              "top-left": _withCtx((props) => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  round: "",
                  icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
                  onClick: props.toggleFullscreen,
                  class: "q-ml-md"
                }, null, 8, ["icon", "onClick"])
              ]),
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_th, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(col.noTrans ? col.label : _ctx.$t(col.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_tr, {
                  props: props,
                  class: "searchHeader"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_td, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          (_ctx.filter.hasOwnProperty(col.name))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                (col.name === 'regroupementFamily')
                                  ? (_openBlock(), _createBlock(_component_q_select, {
                                      key: 0,
                                      dense: "",
                                      denseOpts: "",
                                      clearable: "",
                                      filled: "",
                                      modelValue: _ctx.filter[col.name],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                                      options: _ctx.family
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]))
                                  : (_openBlock(), _createBlock(_component_InputTextSite, {
                                      key: 1,
                                      modelValue: _ctx.filter[col.name],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                                      dense: ""
                                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "filter", "filter-method"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}