import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_ModalAddFamilyExcludedToCg = _resolveComponent("ModalAddFamilyExcludedToCg")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ButtonSite, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalOpen())),
      typeButton: 'ACTION'
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.family_excluded.btn_open")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TemplateModaleSite, {
      idModal: "modalFamilyExcluded",
      labelModal: 
      _ctx.$t('message.gestion_customer_group_mg.family_excluded.modal.title')
    ,
      isOpen: _ctx.isOpen,
      width: 'LARGE',
      saveButton: _ctx.saveButton,
      onModal_close: _ctx.modalClose
    }, {
      modalTitle: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.family_excluded.modal.title")), 1)
      ]),
      modalBody: _withCtx(() => [
        _createVNode(_component_q_table, {
          ref: 'tableFamilyExcludeds',
          filter: _ctx.filter,
          rows: _ctx.familyExcludeds,
          "rows-per-page-options": [10, 20, 50, 100],
          columns: _ctx.columns,
          "row-key": "id",
          class: "tableQuasar",
          "wrap-cells": ""
        }, {
          "top-left": _withCtx((props) => [
            _createVNode(_component_q_btn, {
              flat: "",
              round: "",
              icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
              onClick: props.toggleFullscreen,
              class: "q-ml-md"
            }, null, 8, ["icon", "onClick"]),
            _createVNode(_component_ModalAddFamilyExcludedToCg, {
              idRefTypeCustomerGroup: _ctx.idRefTypeCustomerGroup,
              onRefrechFamilyExcludeds: _ctx.refrechFamilyExcludeds
            }, null, 8, ["idRefTypeCustomerGroup", "onRefrechFamilyExcludeds"])
          ]),
          "top-right": _withCtx(() => [
            _createVNode(_component_q_input, {
              outlined: "",
              modelValue: _ctx.filter,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event)),
              label: _ctx.$t('message.search.searchGlobal')
            }, {
              append: _withCtx(() => [
                (_ctx.filter !== '')
                  ? (_openBlock(), _createBlock(_component_q_icon, {
                      key: 0,
                      name: "close",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter = '')),
                      class: "cursor-pointer"
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_q_icon, { name: "search" })
              ]),
              _: 1
            }, 8, ["modelValue", "label"])
          ]),
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "libelle",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.idRefFamilyItem.libelle), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "fullLibelle",
                  props: props
                }, {
                  default: _withCtx(() => [
                    (
                  props.row.idRefFamilyItem.fullLibelle !==
                  props.row.idRefFamilyItem.libelle
                )
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(props.row.idRefFamilyItem.fullLibelle), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "delete",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ButtonSite, {
                      onClick: ($event: any) => (_ctx.deleteFamilyExcluded(props.row.id)),
                      typeButton: 'WARNING'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(
                    "message.gestion_customer_group_mg.family_excluded.modal.btn_delete"
                  )), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          _: 1
        }, 8, ["filter", "rows", "columns"])
      ]),
      _: 1
    }, 8, ["labelModal", "isOpen", "saveButton", "onModal_close"])
  ], 64))
}