import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row expandReturnItemFamily" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-md-2 col-sm-12 col-xs-12" }
const _hoisted_4 = { class: "col-md-3 col-sm-12 col-xs-12" }
const _hoisted_5 = { class: "col-md-3 col-sm-12 col-xs-12" }
const _hoisted_6 = { class: "col-md-2 col-sm-12 col-xs-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_TableReturnItem = _resolveComponent("TableReturnItem")!
  const _component_ExpandGlobalReturn = _resolveComponent("ExpandGlobalReturn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ExpandGlobalReturn, {
      title: _ctx.tempFamilyReturnItemRecommandations.regroupementFamily,
      addHeaderLeft: true
    }, {
      headerLeft: _withCtx(() => [
        _createVNode(_component_q_item_section, { class: "col-md-9 headerLeft" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    "message.return.select_return.family_preco.item_available",
                    {
                      count:
                        _ctx.tempFamilyReturnItemRecommandations
                          .arrayReturnItemRecommandations.length,
                    }
                  )), 1)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_return.family_preco.item_preco") +
                    " ") + " ", 1),
                  _createElementVNode("span", {
                    class: _normalizeClass(_ctx.countPreco > 0 ? 'preco' : '')
                  }, _toDisplayString(_ctx.countPreco), 3)
                ])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t(
                    "message.return.select_return.family_preco.item_selected",
                    {
                      count: _ctx.countSelected,
                    }
                  )), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(_ctx.$t("message.return.select_return.family_preco.amount", {
                    amount: _ctx.$n(_ctx.amount, "price"),
                  })), 1)
              ])
            ])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_TableReturnItem, {
          familyReturnItemRecommandations: _ctx.tempFamilyReturnItemRecommandations,
          onUpdateItemRecommandation: _ctx.updateItemRecommandation,
          onOpenModalStatsDetailReturnItem: _ctx.openModalStatsDetailReturnItem,
          onOpenModalReturnItemByFamily: _ctx.openModalReturnItemByFamily
        }, null, 8, ["familyReturnItemRecommandations", "onUpdateItemRecommandation", "onOpenModalStatsDetailReturnItem", "onOpenModalReturnItemByFamily"])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}