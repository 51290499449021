import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { defineStore } from 'pinia'
import { i18n } from '@/translations/index'
import { ListIdClientSso, MenuSSO } from "@/type/MenuSSOType";
import ApiGestionCap from "@/services/ApiGestionCap";

export const useGlobalStore = defineStore({
    id: 'globalStore',
    state: () => ({
        errorMessage: null as ErrorSite | null,
        menuSSO: null as MenuSSO | null,
    }),
    getters: {
        getErrorMessage(state) {
            return state.errorMessage;
        },
        getMenuSSO(state) {
            return state.menuSSO;
        },
    },
    actions: {
        /**
         * Récupération des menu du SSO
         * @param listIdClientSsos 
         */
        async setMenuSSO(listIdClientSsos: ListIdClientSso) {
            this.menuSSO = await ApiGestionCap.getMenuSso(listIdClientSsos);
        }
    }
})