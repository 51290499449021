
import { defineComponent, ref } from "vue";
import Separator from "@/components/global/Separator.vue";
import { usePendingStore } from "@/store/pendingStore";
import { useGlobalStore } from "@/store/globalStore";
import { useI18n } from "vue-i18n";
import { ErrorGlobal, ErrorSite, ERROR_TYPE } from "@/type/Error";
import ApiGestionCap from "@/services/ApiGestionCap";
import { router } from "@/router";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { RouteName } from "@/constante/RouterConst";
import { isTokenReady } from "@baloise/vue-keycloak";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import InputDateSite from "@/components/customInput/InputDateSite.vue";
import date from 'quasar/src/utils/date.js';;
import { InputDateSiteUpdateType } from "@/type/InputDateSiteUpdateType";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import { MGRefItem, ForceUpdateItem } from "@/type/MyGarage/MGRefItem";
import ServiceFormatDate from "@/services/ServiceFormatDate";

export default defineComponent({
  name: "MGForceUpdateItem",
  components: {
    ButtonSite,
    Separator,
    BlockMsgError,
    InputDateSite,
  },
  setup() {
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const { t } = useI18n();

    const start = ref("" as string);
    const end = ref("" as string);

    const loading = ref(false);

    const refItems = ref(null as null | MGRefItem[]);

    const columns = [
      {
        name: "idErp",
        required: true,
        label: "message.my_garage.force_update_item.table.idErp",
        align: "left",
        field: (row: MGRefItem) => row.idErp,
        sortable: true,
      },
      {
        name: "libelle",
        required: true,
        label: "message.my_garage.force_update_item.table.libelle",
        align: "left",
        field: (row: MGRefItem) => row.libelle,
        sortable: true,
      },
      {
        name: "brand",
        required: true,
        label: "message.my_garage.force_update_item.table.brand",
        align: "left",
        field: (row: MGRefItem) => row.idRefBrand.libelle,
        sortable: true,
      },
    ];

    return {
      filter: ref(""),
      start,
      end,
      pendingStore,
      globalStore,
      t,
      loading,
      refItems,
      columns,
    };
  },
  unmounted() {
    this.globalStore.$patch({ errorMessage: null });
  },
  methods: {
    async forceUpdateItem() {
      this.loading = true;
      this.globalStore.$patch({ errorMessage: null });
      if (
        ServiceFormValidation.checkIsDateIsFormatedFr(this.start) &&
        ServiceFormValidation.checkIsDateIsFormatedFr(this.end)
      ) {
        const ForceUpdateItem = {
          start: ServiceFormatDate.formatDateToGb(this.start),
          end: ServiceFormatDate.formatDateToGb(this.end),
        } as ForceUpdateItem;

        try {
          const response = await ApiGestionCap.forceUpdateItem(ForceUpdateItem);
          if (this.isErrorGlobal(response)) {
            const errorSite = {
              message: this.t(response.message),
              type: ERROR_TYPE.GLOBAL,
            } as ErrorSite;
            this.globalStore.$patch({ errorMessage: errorSite });
          } else {
            this.refItems = response;
          }
        } catch (error) {
          router.push({ name: RouteName.Error });
        }
      } else {
        const errorSite = {
          message: this.t("message.my_garage.force_update_item.error_global"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      }
      this.loading = false;
    },
    /**
     * Controle si l'objet est de type error global
     */
    isErrorGlobal(error: MGRefItem[] | ErrorGlobal): error is ErrorGlobal {
      return (error as ErrorGlobal).message !== undefined;
    },
    /**
     * fonction de maj de l'input date start
     */
    updateDateStart(inputDateSiteUpdateType: InputDateSiteUpdateType) {
      this.start = inputDateSiteUpdateType.newDate;
    },
    /**
     * fonction de maj de l'input date end
     */
    updateDateEnd(inputDateSiteUpdateType: InputDateSiteUpdateType) {
      this.end = inputDateSiteUpdateType.newDate;
    },
  },
});
