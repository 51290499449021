/**
 * Fichier de constante des erreur amazon
 */
const AMAZ_CODE_ERR_DATE = "c_err_date";
const AMAZ_CODE_ERR_STATE = "c_err_state";
const AMAZ_CODE_ERR_CP = "c_err_cp";
const AMAZ_CODE_ERR_PHONE = "c_err_phone";
const AMAZ_CODE_ERR_SIZE_ADR = "c_err_size_Adr";
const AMAZ_CODE_ERR_PRICE = "c_err_price";
const AMAZ_CODE_ERR_STOCK = "c_err_stock";
const AMAZ_CODE_ERR_COUNTRY = "c_err_country";
const AMAZ_CODE_ERR_DOUBLE = "c_err_double";

export{
    AMAZ_CODE_ERR_DATE,
    AMAZ_CODE_ERR_STATE,
    AMAZ_CODE_ERR_CP,
    AMAZ_CODE_ERR_PHONE,
    AMAZ_CODE_ERR_SIZE_ADR,
    AMAZ_CODE_ERR_PRICE,
    AMAZ_CODE_ERR_STOCK,
    AMAZ_CODE_ERR_COUNTRY,
    AMAZ_CODE_ERR_DOUBLE,
}