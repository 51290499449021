/**
 * Service qui permet des s'inscrire à des écoute mercure
 */
import Keycloak from "keycloak-js";
export default {
    /*** messages OrderBatch r ****/
    subscribeOrderToValidate(idOrderBatchs: number[]): EventSource {
        const url = generateUrlMercure();
        idOrderBatchs.forEach(idOrderBatch => {
            url.searchParams.append("topic", `${process.env.VUE_APP_NAME_API_FOR_URL_MERCURE}/orderbatch/${idOrderBatch}`);
        });
        return new EventSource(url.toString());
    }, 
}

function generateUrlMercure() : URL{
    return  new URL(process.env.VUE_APP_MERCURE_URL);
}

