
import { computed, defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import { AmazonOrder, ModelUpdateOrder } from "@/type/Amazon/AmazonOrderType";
import { ErpMaxLenAddress } from "@/type/GlobalErpType";
import ApiGestionCap from "@/services/ApiGestionCap";
import ServiceFormValidation from "@/services/ServiceFormValidation";
import {
  CODE_RS,
  CODE_CONTACT,
  CODE_ADDR1,
  CODE_ADDR2,
  CODE_ADDR3,
  CODE_ZIPCODE,
  CODE_CITY,
  CODE_PHONE,
  CODE_EMAIL,
} from "@/constante/GlobalErpConst";
import { AMAZ_CODE_ERR_PHONE } from "@/constante/ConstAmazonError";

import QForm from 'quasar/src/components/form/QForm.js';;
import ServiceOrder from "@/services/Amazon/ServiceOrder";
import InputTextSite from "@/components/customInput/InputTextSite.vue";

export default defineComponent({
  name: "ModalUpdateOrder",
  emits: ["updateOrder"],
  components: {
    TemplateModaleSite,
    InputTextSite,
  },
  setup() {
    const order = ref(null as AmazonOrder | null);
    const isPending = ref(false as boolean);
    const erpMaxLenAddress = ref([] as ErpMaxLenAddress[]);
    const tempOrder = ref(null as ModelUpdateOrder | null);
    const pendingStore = usePendingStore();
    const saveButton = ref({
      label: "message.amazon.order.update.modal.btn_save",
      affBtn: true,
    } as ModalBtnType);
    const disableElement = computed(() => {
      return isPending.value;
    });

    return {
      erpMaxLenAddress,
      tempOrder,
      saveButton,
      filter: ref(""),
      isOpen: ref(false),
      pendingStore,
      CODE_RS,
      CODE_CONTACT,
      CODE_ADDR1,
      CODE_ADDR2,
      CODE_ADDR3,
      CODE_ZIPCODE,
      CODE_CITY,
      CODE_PHONE,
      CODE_EMAIL,
      AMAZ_CODE_ERR_PHONE,
      ServiceFormValidation,
      order,
      ServiceOrder,
      isPending,
      disableElement,
    };
  },
  methods: {
    /**
     * Fonction de mise à jour de la commande
     */
    async updateOrder() {
      this.isPending = true;
      try {
        if (this.tempOrder) {
          const order = await ApiGestionCap.updateOrder(this.tempOrder);
          this.$emit("updateOrder", order);
        }
        this.isPending = false;
        this.modalClose();
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
    },
    /**
     * Fonction de validation du formulaire quasar du formulaire d'addresse
     */
    validateformUpdateAddress() {
      const formUpdateAddress = this.$refs.formUpdateAddress as QForm;
      if (formUpdateAddress) {
        formUpdateAddress.validate();
      }
    },
    /**
     * Fonction de validation du formulaire quasar du formulaire de téléphone
     */
    validateformUpdatePhone() {
      const formUpdatePhone = this.$refs.formUpdatePhone as QForm;
      if (formUpdatePhone) {
        formUpdatePhone.validate();
      }
    },
    /**
     * Fonction de validation de tous les formulaire de la modal
     */
    validationGlobal() {
      this.validateformUpdateAddress();
      this.validateformUpdatePhone();
    },
    /**
     * Récupération des parametre des longueurs maximum des champs de l'erp
     */
    async getErpMaxLenAddress() {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        this.erpMaxLenAddress = await ApiGestionCap.getErpMaxLenAddress();
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Fonction qui récupère le nombre de caractère maximum en fonction d'un code type adresse ERP
     */
    getMaxLen(code: string): number {
      const erpMaxLenAddress = this.erpMaxLenAddress.filter(function (
        erpMaxLenAddress: ErpMaxLenAddress
      ) {
        return erpMaxLenAddress.libelle === code;
      });
      return erpMaxLenAddress[0].maxLen;
    },
    /**
     * Fonction qui ouvre la modal
     */
    async modalOpen(order: AmazonOrder) {
      this.order = order;
      await this.getErpMaxLenAddress();
      this.tempOrder = {
        idOrder: this.order.id,
        name: this.order.shipToName,
        addr1: this.order.shipToAddress1,
        addr2: this.order.shipToAddress2,
        addr3: this.order.shipToAddress3,
        zipCode: this.order.shipToZipCode,
        city: this.order.shipToCity,
        country: this.order.shipToCountryOrRegion,
        region: this.order.shipToState,
        phone: this.order.phoneNumber,
      };
      this.isOpen = true;
    },
    /**
     * Fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
  },
});
