import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "libelle" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSite = _resolveComponent("ButtonSite")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.idOrder.orderErrors, (orderError) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: orderError.id
        }, [
          (!orderError.isFixed)
            ? (_openBlock(), _createElementBlock("li", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('message.amazon.order.error.titre.'+ orderError.idRefTypeError.codeSite)) + " : ", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.libelleError(orderError.idRefTypeError.codeSite,orderError.idOrderItem)), 1)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    (_ctx.orderNeedUpdate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ButtonSite, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalUpdateOrder(_ctx.idOrder))),
            typeButton: 'ACTION'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order.update.btn_open")), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.idOrder.orderCanBeRemove)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_ButtonSite, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModalRemoveOrder(_ctx.idOrder))),
            typeButton: 'WARNING'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("message.amazon.order.remove.btn_open")), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}