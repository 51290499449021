
import { useGlobalStore } from "@/store/globalStore";
import { usePendingStore } from "@/store/pendingStore";
import { AmazonOrder } from "@/type/Amazon/AmazonOrderType";
import { defineComponent, PropType, ref } from "vue";
import BinariStatus from "@/components/global/BinariStatus.vue";
import { useI18n } from "vue-i18n";
import ServiceOrder from "@/services/Amazon/ServiceOrder";

import OrderError from "@/components/amazon/OrderError.vue";

export default defineComponent({
  name: "TableOrder",
  emits: ["refreshOrderBatch", "openModal", "openModalRemoveOrder"],
  props: {
    orders: {
      type: Array as PropType<AmazonOrder[]>,
      required: true,
    },
  },
  components: {
    BinariStatus,
    OrderError,
  },
  setup(props) {
    const { t } = useI18n();
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const rows = ref(props.orders as AmazonOrder[]);
    const columns = [
      {
        name: "amazonId",
        required: true,
        label: "message.amazon.order_batch_detail.table.amazon_id",
        align: "left",
      },
      {
        name: "receiver",
        required: true,
        label: "message.amazon.order_batch_detail.table.receiver",
        align: "left",
      },
      {
        name: "orderItems",
        required: true,
        label: "message.amazon.order_batch_detail.table.order_items",
        align: "center",
      },
      {
        name: "status",
        required: true,
        label: "message.amazon.order_batch_detail.table.status",
        align: "center",
      },
      {
        name: "info",
        required: true,
        label: "message.amazon.order_batch_detail.table.info",
        align: "left",
      },
    ];
    return {
      globalStore,
      pendingStore,
      rows,
      columns,
      t,
      ServiceOrder,
    };
  },
  methods: {
    openModalUpdateOrder(order: AmazonOrder) {
      this.$emit("openModal", order);
    },
    openModalRemoveOrder(order: AmazonOrder) {
      this.$emit("openModalRemoveOrder", order);
    },
    updateOrder(newOrder: AmazonOrder) {
      let tempOrder = [] as AmazonOrder[];
      this.rows.forEach((order) => {
        if (order.id === newOrder.id) {
          order = newOrder;
        }
        tempOrder.push(order);
      });
      this.rows = [];
      this.rows = tempOrder;
    },
  },
});
