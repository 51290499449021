
import { defineComponent, ref, onMounted } from "vue";
import { useKeycloak, isTokenReady } from "@baloise/vue-keycloak";
import Breadcrumb from "./Breadcrumb.vue";
import { router, hasAccessTo, hasAccessToExternal } from "@/router";
import { CapHeaderMenuType } from "../type/Header";
import { RouteName } from "@/constante/RouterConst";
import { usePendingStore } from "@/store/pendingStore";

export default defineComponent({
  name: "Header",
  props: {
    title: String,
    username: String,
  },
  components: {
    Breadcrumb,
  },
  emits: ["logout-event"],
  setup() {
    const menu = ref(null as null | CapHeaderMenuType[]);
    const { isAuthenticated } = useKeycloak();
    return {
      isAuthenticated,
      langs: ["fr", "en"],
      drawerLeft: ref(false),
      menu,
    };
  },
  async mounted() {
    await isTokenReady();
    const newMenu = [] as CapHeaderMenuType[];
    const routeMenu = this.$router
      .getRoutes()
      .filter(
        (routeMenu) =>
          (routeMenu.meta?.showInMenu &&
            hasAccessTo(routeMenu.name as string)) ||
          (routeMenu.meta?.showInMenu &&
            routeMenu.meta?.external &&
            hasAccessToExternal(routeMenu.name as string))
      );
    routeMenu.forEach((route) => {
      if (route.meta?.parent) {
        //Vérification si le parent existe déjà
        if (
          0 ===
          newMenu.filter((menu) => menu.libelleLink === route.meta?.parent)
            .length
        ) {
          const tempMenuParent = {
            routerRouteName: null,
            isHome: false,
            libelleLink: route.meta.parent as string,
            rawLibelle: route.meta.rawLibelle,
            showInMenu: true,
            showInBreadCrumb: true,
            position: route.meta?.parentPosition,
            childs: [],
          } as CapHeaderMenuType;
          newMenu.push(tempMenuParent);
        }
        const tempMenuChild = {
          routerRouteName: route.name,
          isHome: RouteName.Home === route.name ? true : false,
          libelleLink: route.meta.libelle,
          showInMenu: true,
          showInBreadCrumb: true,
          position: route.meta?.position,
        } as CapHeaderMenuType;
        newMenu.forEach((menu) => {
          if (menu.libelleLink === route.meta?.parent) {
            menu.childs?.push(tempMenuChild);
          }
        });
      } else {
        const tempMenu = {
          routerRouteName: route.name,
          isHome: RouteName.Home === route.name ? true : false,
          libelleLink: route.meta?.libelle,
          showInMenu: true,
          showInBreadCrumb: true,
          position: route.meta?.position,
        } as CapHeaderMenuType;
        newMenu.push(tempMenu);
      }
    });
    //Trie du menu par position
    //menu horizontal
    newMenu.sort((a, b) => {
      return a.position - b.position;
    });
    //trie menu vertical
    newMenu.forEach((menu) => {
      if (menu.childs && 1 < menu.childs?.length) {
        menu.childs.sort((a, b) => {
          return a.position - b.position;
        });
      }
    });
    this.menu = newMenu;
  },
  methods: {
    parentLogout() {
      this.$emit("logout-event");
    },
  },
});
