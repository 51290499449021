
import { useReturnStore } from "@/store/returnStore";
import {
  FamilyReturnItemRecommandations,
  ReturnItemRecommandation,
} from "@/type/Return/ReturnType";
import { computed, defineComponent, ref, toRaw } from "vue";
import TableReturnItem from "@/components/return/TableReturnItem.vue";
import ExpandGlobalReturn from "@/components/return/ExpandGlobalReturn.vue";

export default defineComponent({
  name: "ExpandReturnItemFamily",
  emits: ["openModalStatsDetailReturnItem", "openModalReturnItemByFamily"],
  props: {
    familyReturnItemRecommandations: {
      type: Object as () => FamilyReturnItemRecommandations,
      required: true,
    },
  },
  components: {
    TableReturnItem,
    ExpandGlobalReturn,
  },
  setup(props) {
    const returnStore = useReturnStore();
    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });
    //creation d'un objet réactif avec le props de FamilyReturnItemRecommandations
    const tempFamilyReturnItemRecommandations = ref(
      toRaw(
        props.familyReturnItemRecommandations
      ) as FamilyReturnItemRecommandations
    );

    const countPreco = ref(0 as number);
    const countSelected = ref(0 as number);
    const amount = ref(0 as number);

    return {
      returnStore,
      getReturnPreco,
      tempFamilyReturnItemRecommandations,
      countPreco,
      countSelected,
      amount,
    };
  },
  mounted() {
    this.updateReturnPreco();
  },
  methods: {
    async updateItemRecommandation(
      returnItemRecommandation: ReturnItemRecommandation
    ) {
      //on met à jour l'item
      this.tempFamilyReturnItemRecommandations.arrayReturnItemRecommandations.forEach(
        (element) => {
          if (element.id === returnItemRecommandation.id) {
            element = returnItemRecommandation;
          }
        }
      );
      //on met à jour les calculs
      this.updateReturnPreco();
      await this.returnStore.updateReturnPreco(returnItemRecommandation);
    },
    //fonction qui execute tout les calculs plus la mise à jour du store
    updateReturnPreco() {
      //on calcul le nombre de référence préconisé
      this.countPreco = this.calculPreco();
      //on calcul le nombre de référence sélectionné
      this.countSelected = this.calculSelected();
      //on calcul le montant total séléctionné
      this.amount = this.calculAmount();
      // //on met à jour le store
      // this.returnStore.updateReturnPreco(this.tempFamilyReturnItemRecommandations);
    },
    //function qui calcul le nombre de référence préconisé
    calculPreco() {
      let count = 0;
      this.tempFamilyReturnItemRecommandations.arrayReturnItemRecommandations.forEach(
        (element) => {
          if (element.qtyProposed > 0) {
            count++;
          }
        }
      );
      return count;
    },
    //fonction qui calcul le nombre de référence sélectionné
    calculSelected() {
      let count = 0;
      this.tempFamilyReturnItemRecommandations.arrayReturnItemRecommandations.forEach(
        (element) => {
          if (element.qtySelected > 0) {
            count++;
          }
        }
      );
      return count;
    },
    //fonction qui calcul le montant total séléctionné
    calculAmount() {
      let amount = 0;
      this.tempFamilyReturnItemRecommandations.arrayReturnItemRecommandations.forEach(
        (element) => {
          if (element.qtySelected > 0) {
            amount += element.qtySelected * element.unitPriceHt;
          }
        }
      );
      return amount;
    },
    openModalStatsDetailReturnItem(idReturnItemRecomandation: number) {
      this.$emit("openModalStatsDetailReturnItem", idReturnItemRecomandation);
    },
    openModalReturnItemByFamily(regroupementFamily: string) {
      this.$emit("openModalReturnItemByFamily", regroupementFamily);
    },
  },
});
