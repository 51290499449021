
import { RouteName } from "@/constante/RouterConst";
import {  defineComponent, ref } from "vue";
import { CapHeaderMenuType, TreeBreadcrumb } from "../type/Header";
import { router } from "@/router";

export default defineComponent({
  name: "Breadcrumb",
  setup() {
    const getTreeBreadcrumb = ref(null as null | TreeBreadcrumb);
    return {
      getTreeBreadcrumb,
    };
  },
  watch: {
    //a chaque changement de route
    $route(to, from) {
      //création d'un treeBreadcrump temporaire
      const treeBreadcrumb = {
        menuFields: [] as CapHeaderMenuType[],
      } as TreeBreadcrumb;
      //si pas la route home
      if (true === to.meta?.showInBreadCrumb) {
        //récupération des parametre de la route home
        const routeHome = router
          .getRoutes()
          .filter((route) => RouteName.Home === route.name)[0];
        const homeField = {
          routerRouteName: routeHome.name,
          isHome: true,
          libelleLink: routeHome.meta?.libelle,
          showInMenu: to.meta?.showInMenu,
          showInBreadCrumb: to.meta?.showInBreadCrumb,
          position: routeHome.meta?.position,
        } as CapHeaderMenuType;
        //ajout de la partie home
        treeBreadcrumb?.menuFields.push(homeField);
        if (to.meta?.parent) {
          let parent = to.meta?.parent;
          // si un parent ajout de la partie parente
          while (parent) {
            const parentRoute = router
              .getRoutes()
              .filter((route) => parent === route.name)[0];
            if (undefined !== parentRoute) {
              const menuField = {
                routerRouteName: parentRoute.name,
                isHome: false,
                libelleLink: parentRoute.meta?.libelle,
                showInMenu: parentRoute.meta?.showInMenu,
                showInBreadCrumb: parentRoute.meta?.showInBreadCrumb,
                position: parentRoute.meta?.parentPosition,
              } as CapHeaderMenuType;
              
              treeBreadcrumb?.menuFields.splice(1,0,menuField)
              parent = parentRoute.meta?.parent;
            } else {
              const menuField = {
                routerRouteName: null,
                isHome: false,
                libelleLink: parent,
                showInMenu: false,
                showInBreadCrumb: true,
                position: 0,
              } as CapHeaderMenuType;
              treeBreadcrumb?.menuFields.splice(1,0,menuField)
              parent = null;
            }
          }
          
        }
        //ajout de la partie route de destination
        const menuField = {
          routerRouteName: to.name,
          isHome: false,
          libelleLink: to.meta?.libelle,
          showInMenu: to.meta?.showInMenu,
          showInBreadCrumb: to.meta?.showInBreadCrumb,
          position: to.meta?.position,
        } as CapHeaderMenuType;
        treeBreadcrumb?.menuFields.push(menuField);
      } else {
        treeBreadcrumb.menuFields = [];
      }
      this.getTreeBreadcrumb = treeBreadcrumb;
    },
  },
});
