import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    class: _normalizeClass(["buttonSite", _ctx.classBtnSuppl])
  }, {
    default: _withCtx(() => [
      (_ctx.affIcon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_q_icon, { name: _ctx.classIcon }, null, 8, ["name"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}