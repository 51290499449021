
import { defineComponent, ref } from "vue";
import { MGItemTypeErpExcluded } from "@/type/MyGarage/MGItemTypeErpExcluded";
import ApiGestionCap from "@/services/ApiGestionCap";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import ModalAddItemTypeErpExcludedToCg from "@/components/mygarage/ModalAddItemTypeErpExcludedToCg.vue";

import { usePendingStore } from "@/store/pendingStore";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";

export default defineComponent({
  name: "ModalItemTypeErpExcluded",
  props: {
    idRefTypeCustomerGroup: {
      type: Number,
      required: true,
    },
  },
  components: {
    ButtonSite,
    TemplateModaleSite,
    ModalAddItemTypeErpExcludedToCg,
  },
  setup() {
    const pendingStore = usePendingStore();
    const itemTypeErpExcludeds = ref([] as MGItemTypeErpExcluded[]);
    const columns = [
      {
        name: "libelle",
        required: true,
        label:
          "message.gestion_customer_group_mg.item_type_erp_excluded.table.libelle",
        align: "left",
        field: (row: MGItemTypeErpExcluded) => row.idRefFamilyItemErp.libelle,
        sortable: true,
      },
      {
        name: "itemCategoryCode",
        required: true,
        label:
          "message.gestion_customer_group_mg.item_type_erp_excluded.table.itemCategoryCode",
        align: "left",
        field: (row: MGItemTypeErpExcluded) =>
          row.idRefFamilyItemErp.itemCategoryCode,
        sortable: true,
      },
      {
        name: "code",
        required: true,
        label:
          "message.gestion_customer_group_mg.item_type_erp_excluded.table.code",
        align: "left",
        field: (row: MGItemTypeErpExcluded) => row.idRefFamilyItemErp.code,
        sortable: true,
      },
      {
        name: "delete",
        required: true,
        label:
          "message.gestion_customer_group_mg.item_type_erp_excluded.table.delete",
        align: "center",
        sortable: true,
      },
    ];
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);
    return {
      saveButton,
      itemTypeErpExcludeds,
      columns,
      filter: ref(""),
      isOpen: ref(false),
      pendingStore,
    };
  },
  methods: {
    /**
     * Fonction qui recupere toute les famille erp exclu pour une enseigne envoyer en props isRefTypeCustomerGroup
     */
    async refrechItemTypeErpExcludeds() {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        this.itemTypeErpExcludeds = await ApiGestionCap.getItemTypeErpExcludeds(
          this.idRefTypeCustomerGroup
        );
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
    },
    /**
     * Fonction qui retire une famille de l'erp d'exclusion pour une enseigne
     */
    async deleteItemTypeErpExcluded(idItemTypeErpExcluded: number) {
      this.pendingStore.$patch({ routerIsPending: true });
      try {
        await ApiGestionCap.removeItemTypeErpExcludeds(idItemTypeErpExcluded);
      } catch (error) {
        router.push({ name: RouteName.Error });
      }
      this.pendingStore.$patch({ routerIsPending: false });
      await this.refrechItemTypeErpExcludeds();
    },
    /**
     * fonction qui recherche les famille erp d'exclusion et qui ouvre la modal
     */
    async modalOpen() {
      await this.refrechItemTypeErpExcludeds();
      this.isOpen = true;
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
  },
});
