import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "q-pa-md",
  ref: "MGExcludedItem"
}
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row blockTable" }
const _hoisted_5 = {
  key: 0,
  class: "col-12"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-12 blockForm" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12 blockForm" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-12 blockForm" }
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = { class: "row text-center" }
const _hoisted_16 = { class: "col-12 blockForm" }
const _hoisted_17 = { class: "row text-center" }
const _hoisted_18 = { class: "col-12 blockForm" }
const _hoisted_19 = { class: "row text-center" }
const _hoisted_20 = {
  key: 0,
  class: "col-12 blockForm"
}
const _hoisted_21 = {
  key: 0,
  class: "row justify-center text-center"
}
const _hoisted_22 = { class: "col-6" }
const _hoisted_23 = { class: "col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_BinariStatus = _resolveComponent("BinariStatus")!
  const _component_q_table = _resolveComponent("q-table")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_InputNumberSite = _resolveComponent("InputNumberSite")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_ModalFamilyExcluded = _resolveComponent("ModalFamilyExcluded")!
  const _component_ModalItemTypeErpExcluded = _resolveComponent("ModalItemTypeErpExcluded")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.gestion_customer_group_mg")), 1)
        ])
      ]),
      _createVNode(_component_Separator),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.rows)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_q_table, {
                ref: 'tableCustomerGroup',
                filter: _ctx.filter,
                rows: _ctx.rows,
                "rows-per-page-options": [10, 20, 50, 100],
                columns: _ctx.columns,
                "row-key": "id",
                class: "tableQuasar",
                "wrap-cells": ""
              }, {
                "top-left": _withCtx((props) => [
                  _createVNode(_component_q_btn, {
                    flat: "",
                    round: "",
                    icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
                    onClick: props.toggleFullscreen,
                    class: "q-ml-md"
                  }, null, 8, ["icon", "onClick"]),
                  _createVNode(_component_ButtonSite, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createCustomerGroup())),
                    typeButton: 'ACTION'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.btn_create")), 1)
                    ]),
                    _: 1
                  })
                ]),
                "top-right": _withCtx(() => [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    modelValue: _ctx.filter,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter) = $event)),
                    label: _ctx.$t('message.search.searchGlobal')
                  }, {
                    append: _withCtx(() => [
                      (_ctx.filter !== '')
                        ? (_openBlock(), _createBlock(_component_q_icon, {
                            key: 0,
                            name: "close",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter = '')),
                            class: "cursor-pointer"
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_q_icon, { name: "search" })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label"])
                ]),
                header: _withCtx((props) => [
                  _createVNode(_component_q_tr, { props: props }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                        return (_openBlock(), _createBlock(_component_q_th, {
                          key: col.name,
                          props: props
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                          ]),
                          _: 2
                        }, 1032, ["props"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["props"])
                ]),
                body: _withCtx((props) => [
                  _createVNode(_component_q_tr, {
                    props: props,
                    onClick: ($event: any) => (_ctx.updateCustomerGroup(props.row)),
                    class: "btn"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_td, {
                        key: "idErp",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.idErp), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "libelle",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(props.row.libelle), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "logo",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          (props.row.logo)
                            ? (_openBlock(), _createBlock(_component_q_img, {
                                key: 0,
                                fit: "scale-down",
                                src: props.row.logo,
                                class: "logo"
                              }, null, 8, ["src"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "accessExternalDocumentNo",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BinariStatus, {
                            status: props.row.accessExternalDocumentNo
                          }, null, 8, ["status"])
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "limitOrderPriceNeedValidation",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          (0 === props.row.limitOrderPriceNeedValidation)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.table.no_limit")), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.$n(props.row.limitOrderPriceNeedValidation, "price")), 1)
                              ], 64))
                        ]),
                        _: 2
                      }, 1032, ["props"]),
                      _createVNode(_component_q_td, {
                        key: "limitPriceItemCanBuy",
                        props: props
                      }, {
                        default: _withCtx(() => [
                          (0 === props.row.limitPriceItemCanBuy)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.table.no_limit")), 1)
                              ], 64))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.$n(props.row.limitPriceItemCanBuy, "price")), 1)
                              ], 64))
                        ]),
                        _: 2
                      }, 1032, ["props"])
                    ]),
                    _: 2
                  }, 1032, ["props", "onClick"])
                ]),
                _: 1
              }, 8, ["filter", "rows", "columns"])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 512),
    _createVNode(_component_TemplateModaleSite, {
      saveButton: _ctx.saveButton,
      idModal: "modalUpdateCustomerGroup",
      labelModal: _ctx.$t('message.gestion_customer_group_mg.modal.title'),
      isOpen: _ctx.isOpen,
      width: 'MEDIUM',
      onModal_close: _ctx.modalClose,
      onModal_save: _ctx.modalSave
    }, {
      modalTitle: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.modal.title")), 1)
      ]),
      modalBody: _withCtx(() => [
        _createVNode(_component_BlockMsgError),
        _createVNode(_component_q_form, {
          class: "q-gutter-md formContact",
          ref: "customerGroupForm"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_InputTextSite, {
                      modelValue: _ctx.refTypeCustomerGroupSelected.idErp,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.refTypeCustomerGroupSelected.idErp) = $event)),
                      label: 
                    _ctx.$t('message.gestion_customer_group_mg.modal.form.idErp')
                  ,
                      "lazy-rules": "",
                      rules: [
                    (val) =>
                      !!val ||
                      _ctx.$t(
                        'message.gestion_customer_group_mg.modal.form.validator.idErp.notNull'
                      ),
                  ]
                    }, null, 8, ["modelValue", "label", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_InputTextSite, {
                      modelValue: _ctx.refTypeCustomerGroupSelected.libelle,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.refTypeCustomerGroupSelected.libelle) = $event)),
                      label: 
                    _ctx.$t('message.gestion_customer_group_mg.modal.form.libelle')
                  ,
                      "lazy-rules": "",
                      rules: [
                    (val) =>
                      !!val ||
                      _ctx.$t(
                        'message.gestion_customer_group_mg.modal.form.validator.libelle.notNull'
                      ),
                  ]
                    }, null, 8, ["modelValue", "label", "rules"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_q_checkbox, {
                      dense: "",
                      modelValue: 
                    _ctx.refTypeCustomerGroupSelected.accessExternalDocumentNo
                  ,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((
                    _ctx.refTypeCustomerGroupSelected.accessExternalDocumentNo
                  ) = $event)),
                      label: 
                    _ctx.$t(
                      'message.gestion_customer_group_mg.modal.form.accessExternalDocumentNo'
                    )
                  
                    }, null, 8, ["modelValue", "label"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_InputNumberSite, {
                      ref: "limitOrderPriceNeedValidation",
                      id: "limitOrderPriceNeedValidation",
                      value: 
                    _ctx.refTypeCustomerGroupSelected.limitOrderPriceNeedValidation
                  ,
                      onUpdateQty: _ctx.updateLimitOrderPriceNeedValidation,
                      label: 
                    _ctx.$t(
                      'message.gestion_customer_group_mg.modal.form.limitOrderPriceNeedValidation'
                    )
                  
                    }, null, 8, ["value", "onUpdateQty", "label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_InputNumberSite, {
                      ref: "limitPriceItemCanBuy",
                      id: "limitPriceItemCanBuy",
                      value: _ctx.refTypeCustomerGroupSelected.limitPriceItemCanBuy,
                      onUpdateQty: _ctx.updateLimitPriceItemCanBuy,
                      label: 
                    _ctx.$t(
                      'message.gestion_customer_group_mg.modal.form.limitPriceItemCanBuy'
                    )
                  
                    }, null, 8, ["value", "onUpdateQty", "label"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  (_ctx.refTypeCustomerGroupSelected.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _createVNode(_component_ButtonSite, {
                          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deleteCustomerGroup(_ctx.refTypeCustomerGroupSelected.id))),
                          typeButton: 'WARNING'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.modal.btn_delete")), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        }, 512),
        (_ctx.refTypeCustomerGroupSelected.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_ModalFamilyExcluded, {
                  idRefTypeCustomerGroup: _ctx.refTypeCustomerGroupSelected.id
                }, null, 8, ["idRefTypeCustomerGroup"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_ModalItemTypeErpExcluded, {
                  idRefTypeCustomerGroup: _ctx.refTypeCustomerGroupSelected.id
                }, null, 8, ["idRefTypeCustomerGroup"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["saveButton", "labelModal", "isOpen", "onModal_close", "onModal_save"])
  ], 64))
}