import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_TemplateModaleSite = _resolveComponent("TemplateModaleSite")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ButtonSite, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalOpen())),
      typeButton: 'ACTION'
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("message.gestion_customer_group_mg.item_type_erp_excluded.btn_create")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_TemplateModaleSite, {
      idModal: "modalAddItemTypeErpExcludedToCg",
      labelModal: 
      _ctx.$t(
        'message.gestion_customer_group_mg.item_type_erp_excluded.modal.add_title'
      )
    ,
      isOpen: _ctx.isOpen,
      width: 'LARGE',
      saveButton: _ctx.saveButton,
      onModal_close: _ctx.modalClose,
      onModal_save: _ctx.addItemTypeErpExcluded
    }, {
      modalTitle: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t(
        "message.gestion_customer_group_mg.item_type_erp_excluded.modal.add_title"
      )), 1)
      ]),
      modalBody: _withCtx(() => [
        _createVNode(_component_q_select, {
          outlined: "",
          modelValue: _ctx.selectRefFamilyItemErp,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectRefFamilyItemErp) = $event)),
          options: _ctx.refFamilyItemErp,
          clearable: "",
          class: "qselect",
          "option-value": "id",
          label: 
          _ctx.$t(
            'message.gestion_customer_group_mg.item_type_erp_excluded.modal.add_select'
          )
        
        }, {
          "selected-item": _withCtx((scope) => [
            _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.opt)), {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(
                    "message.gestion_customer_group_mg.item_type_erp_excluded.modal.add_value",
                    {
                      libelle: scope.opt.libelle ? scope.opt.libelle : "VIDE",
                      itemCategoryCode: scope.opt.itemCategoryCode
                        ? scope.opt.itemCategoryCode
                        : "VIDE",
                      code: scope.opt.code ? scope.opt.code : "VIDE",
                    }
                  )), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1040)
          ]),
          option: _withCtx((scope) => [
            _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_item_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(
                    "message.gestion_customer_group_mg.item_type_erp_excluded.modal.add_value",
                    {
                      libelle: scope.opt.libelle ? scope.opt.libelle : "VIDE",
                      itemCategoryCode: scope.opt.itemCategoryCode
                        ? scope.opt.itemCategoryCode
                        : "VIDE",
                      code: scope.opt.code ? scope.opt.code : "VIDE",
                    }
                  )), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1040)
          ]),
          _: 1
        }, 8, ["modelValue", "options", "label"])
      ]),
      _: 1
    }, 8, ["labelModal", "isOpen", "saveButton", "onModal_close", "onModal_save"])
  ], 64))
}