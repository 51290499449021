import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "q-pa-md" }
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = {
  key: 1,
  class: "row blockInfoShipping"
}
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12 block" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-12 logo block" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12 block text-center" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-12 block text-center" }
const _hoisted_14 = { class: "col-8" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-12 block" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-12 block" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col-12 block" }
const _hoisted_22 = { class: "row form" }
const _hoisted_23 = { class: "col-6" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-12 blockNextStep" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderCustomerData = _resolveComponent("HeaderCustomerData")!
  const _component_HeaderStepButton = _resolveComponent("HeaderStepButton")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_InputNumberSite = _resolveComponent("InputNumberSite")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_ModalSelectCarrier = _resolveComponent("ModalSelectCarrier")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_HeaderCustomerData),
      (_ctx.getHeaderStepReturn)
        ? (_openBlock(), _createBlock(_component_HeaderStepButton, {
            key: 0,
            btns: _ctx.getHeaderStepReturn
          }, null, 8, ["btns"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.select_shipping")), 1)
        ])
      ]),
      (_ctx.selectedCarrier)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t(`message.return.select_shipping.selected_carrier`)), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_q_img, {
                    fit: "scale-down",
                    src: _ctx.selectedCarrier.logo,
                    class: "logo",
                    alt: _ctx.selectedCarrier.name
                  }, null, 8, ["src", "alt"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("message.return.select_shipping.return_weight", {
                weight: _ctx.$n(_ctx.getTotalWeightSelected, "weight"),
              })), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createVNode(_component_ButtonSite, {
                    onClick: _ctx.openModalSelectCarrier,
                    typeButton: 'BUSINESS'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_shipping.btn_select_carrier")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", {
                    innerHTML: 
                _ctx.$t(
                  `message.return.select_shipping.info_package.${_ctx.selectedCarrier.codeSite}`
                )
              
                  }, null, 8, _hoisted_17)
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createVNode(_component_q_img, {
                    fit: "scale-down",
                    src: _ctx.selectedCarrier.imgPackage,
                    class: "imgPackage",
                    alt: _ctx.selectedCarrier.name
                  }, null, 8, ["src", "alt"])
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_q_form, {
                    onSubmit: _ctx.validCarrier,
                    class: "q-gutter-md",
                    ref: "formValidCarrier"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t(`message.return.select_shipping.form.title`)), 1),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _createVNode(_component_InputNumberSite, {
                            id: "qtyPackage",
                            value: _ctx.modelSelectShipping.qtyPackage,
                            onUpdateQty: _ctx.updateQtyPackage,
                            label: _ctx.getLabelInputNumberCarrier(),
                            min: "1"
                          }, null, 8, ["value", "onUpdateQty", "label"]),
                          _createVNode(_component_InputTextSite, {
                            modelValue: _ctx.modelSelectShipping.contact,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelSelectShipping.contact) = $event)),
                            label: _ctx.$t('message.return.select_shipping.form.contact'),
                            "lazy-rules": "",
                            disable: _ctx.disableElement,
                            rules: [
                      (val) =>
                        !!val ||
                        _ctx.$t(
                          'message.return.select_shipping.form.not_null_contact'
                        ),
                    ]
                          }, null, 8, ["modelValue", "label", "disable", "rules"]),
                          _createVNode(_component_InputTextSite, {
                            modelValue: _ctx.modelSelectShipping.phone,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelSelectShipping.phone) = $event)),
                            label: _ctx.$t('message.return.select_shipping.form.phone'),
                            disable: _ctx.disableElement,
                            "lazy-rules": "",
                            rules: [
                      (val) =>
                        !!val ||
                        _ctx.$t(
                          'message.return.select_shipping.form.not_null_phone'
                        ),
                      (val) =>
                        _ctx.ServiceFormValidation.isValidePhone(val) ||
                        _ctx.$t(
                          'message.return.select_shipping.form.not_valid_phone'
                        ),
                    ]
                          }, null, 8, ["modelValue", "label", "disable", "rules"]),
                          _createVNode(_component_InputTextSite, {
                            modelValue: _ctx.modelSelectShipping.email,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelSelectShipping.email) = $event)),
                            label: _ctx.$t('message.return.select_shipping.form.email'),
                            disable: _ctx.disableElement,
                            "lazy-rules": "",
                            rules: [
                      (val) =>
                        !!val ||
                        _ctx.$t(
                          'message.return.select_shipping.form.not_null_email'
                        ),
                      (val) =>
                        _ctx.ServiceFormValidation.isValidEmail(val) ||
                        _ctx.$t(
                          'message.return.select_shipping.form.not_valid_email'
                        ),
                    ]
                          }, null, 8, ["modelValue", "label", "disable", "rules"])
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["onSubmit"])
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createVNode(_component_ButtonSite, {
            onClick: _ctx.nextStep,
            typeButton: 'PASTEL_VALIDATE',
            "icon-right": 'local_shipping',
            disable: _ctx.disableElement
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("message.return.select_return.btn.next_step")), 1)
            ]),
            _: 1
          }, 8, ["onClick", "disable"])
        ])
      ])
    ]),
    (_ctx.refCarriers)
      ? (_openBlock(), _createBlock(_component_ModalSelectCarrier, {
          key: 0,
          refCarriers: _ctx.refCarriers,
          ref: "ModalSelectCarrier",
          onUpdateCarrier: _ctx.setCarrier
        }, null, 8, ["refCarriers", "onUpdateCarrier"]))
      : _createCommentVNode("", true)
  ], 64))
}