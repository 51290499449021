import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row headerCustomerData"
}
const _hoisted_2 = { class: "col-md-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-12" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-12" }
const _hoisted_8 = {
  key: 0,
  class: "address"
}
const _hoisted_9 = { class: "col-md-6 blockLeft" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_ctx.getCustomer)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getCustomer.name), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_ctx.getCustomer)
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                    _createVNode(_component_q_icon, { name: "location_on" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.getCustomerFormatedAddress()), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_q_btn_dropdown, {
            class: "dropdownBusiness",
            label: _ctx.getActualRouteLabelButton(),
            dense: "",
            "content-class": "blockBtn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.redirectRoutes, (routeName, index) => {
                    return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                      clickable: "",
                      key: index,
                      to: { name: routeName }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, { class: "libelle" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getLabelForRouteName(routeName)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"])), [
                      [_directive_close_popup]
                    ])
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ]))
    : _createCommentVNode("", true)
}