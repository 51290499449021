
import { RouteName } from "@/constante/RouterConst";
import ApiGestionCap from "@/services/ApiGestionCap";
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import { useReturnStore } from "@/store/returnStore";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import { usePendingStore } from "@/store/pendingStore";
import {
  ModelCustomerOrder,
  ModelCustomerOrderCa,
} from "@/type/Return/ReturnType";
import { useI18n } from "vue-i18n";
import TableCustomerOrders from "@/components/return/TableCustomerOrders.vue";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useGlobalStore } from "@/store/globalStore";
import BlockCustomerOrderCa from "@/components/return/BlockCustomerOrderCa.vue";

export default defineComponent({
  name: "CustomerOrders",
  components: {
    HeaderCustomerData,
    TableCustomerOrders,
    BlockCustomerOrderCa,
  },
  setup() {
    const { t } = useI18n();
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const idCustomer = ref(null as string | null);

    const modelCustomerOrderCa = ref(null as null | ModelCustomerOrderCa);

    const actualYear = new Date().getFullYear();

    const modelCustomerOrders = ref([] as ModelCustomerOrder[]);

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    return {
      returnStore,
      idCustomer,
      getCustomer,
      pendingStore,
      modelCustomerOrderCa,
      actualYear,
      modelCustomerOrders,
      globalStore,
      t,
    };
  },
  async mounted() {
    this.pendingStore.$patch({ routerIsPending: true });
    this.globalStore.$patch({ errorMessage: null });
    await isTokenReady();
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: "Customers" });
    }
    //récupération des données du customer
    try {
      this.returnStore.refreshCustomer(this.idCustomer);
    } catch (error) {
      this.pendingStore.$patch({ routerIsPending: false });
      this.$router.push({ name: RouteName.Error });
    }
    //récupération des données purchase
    try {
      this.modelCustomerOrderCa = await ApiGestionCap.getOrderCa(
        this.idCustomer
      );
      this.modelCustomerOrders = await ApiGestionCap.getOrders(this.idCustomer);

      if (this.modelCustomerOrders.length === 0) {
        const errorSite = {
          message: this.t("message.customer.customer_orders.no_data"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      }
    } catch (error) {
      this.pendingStore.$patch({ routerIsPending: false });
      this.$router.push({ name: RouteName.Error });
    }
    this.pendingStore.$patch({ routerIsPending: false });
  },
});
