import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "col-12 blockForm"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 blockForm" }
const _hoisted_7 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-12 blockForm" }
const _hoisted_10 = { class: "row text-center" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_BlockMsgError = _resolveComponent("BlockMsgError")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_BlockAddress = _resolveComponent("BlockAddress")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.customergroups)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_q_select, {
                  outlined: "",
                  modelValue: _ctx.selectCustomerGroup,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectCustomerGroup) = $event)),
                  options: _ctx.customergroups,
                  clearable: "",
                  class: "qselect",
                  "option-value": "id",
                  label: _ctx.$t('message.search.searchCustomer.selectCustomerGroup')
                }, {
                  "selected-item": _withCtx((scope) => [
                    _createVNode(_component_q_item, _mergeProps(scope.opt, { class: "blockCustomerGroup" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.opt.libelle), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040),
                    (scope.opt.logo)
                      ? (_openBlock(), _createBlock(_component_q_item_section, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_img, {
                              src: scope.opt.logo,
                              fit: 'scale-down'
                            }, null, 8, ["src"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  option: _withCtx((scope) => [
                    _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.opt.libelle), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "label"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_InputTextSite, {
              modelValue: _ctx.postCode,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.postCode) = $event)),
              label: _ctx.$t('message.search.searchCustomer.postCode'),
              onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.searchCustomer(null)), ["enter"]))
            }, null, 8, ["modelValue", "label"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_InputTextSite, {
              modelValue: _ctx.idErpCustomer,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.idErpCustomer) = $event)),
              label: _ctx.$t('message.search.searchCustomer.customerId'),
              onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.searchCustomer(null)), ["enter"]))
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_ButtonSite, {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.searchCustomer(null))),
              typeButton: 'INFO'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("message.btn.search")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createVNode(_component_BlockMsgError),
    _createVNode(_component_Separator),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_q_table, {
          ref: 'tableCustomers',
          rows: _ctx.rows,
          "rows-per-page-options": [10, 20, 50, 100],
          columns: _ctx.columns,
          "row-key": "id",
          class: "tableQuasar",
          "wrap-cells": "",
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pagination) = $event)),
          "binary-state-sort": "",
          loading: _ctx.loading,
          onRequest: _ctx.searchCustomer
        }, {
          "top-left": _withCtx((props) => [
            _createVNode(_component_q_btn, {
              flat: "",
              round: "",
              icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
              onClick: props.toggleFullscreen,
              class: "q-ml-md"
            }, null, 8, ["icon", "onClick"])
          ]),
          header: _withCtx((props) => [
            _createVNode(_component_q_tr, { props: props }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                  return (_openBlock(), _createBlock(_component_q_th, {
                    key: col.name,
                    props: props
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["props"]))
                }), 128))
              ]),
              _: 2
            }, 1032, ["props"])
          ]),
          loading: _withCtx(() => [
            _createVNode(_component_q_inner_loading, {
              showing: "",
              color: "primary"
            })
          ]),
          body: _withCtx((props) => [
            _createVNode(_component_q_tr, {
              props: props,
              onClick: ($event: any) => (_ctx.isMyGarage ? null : _ctx.redirectToCustomer(props.row)),
              class: _normalizeClass(_ctx.getClassStyle(props.row))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_td, {
                  key: "id",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.id), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "name",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.name), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "address",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BlockAddress, {
                      name: props.row.name,
                      address: props.row.address,
                      address2: props.row.address2,
                      address3: props.row.address3,
                      postCode: props.row.postCode,
                      city: props.row.city
                    }, null, 8, ["name", "address", "address2", "address3", "postCode", "city"])
                  ]),
                  _: 2
                }, 1032, ["props"]),
                _createVNode(_component_q_td, {
                  key: "phoneNo",
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(props.row.phoneNo), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]),
                (_ctx.isMyGarage)
                  ? (_openBlock(), _createBlock(_component_q_td, {
                      key: "orderBlocked",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_checkbox, {
                          dense: "",
                          modelValue: props.row.idRefAutoCenter.orderBlocked,
                          "onUpdate:modelValue": ($event: any) => ((props.row.idRefAutoCenter.orderBlocked) = $event),
                          onClick: ($event: any) => (
                  _ctx.updateOrderBlockedRefAutoCenter(props.row.idRefAutoCenter)
                )
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onClick"])
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["props", "onClick", "class"])
          ]),
          _: 1
        }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
      ])
    ])
  ], 64))
}