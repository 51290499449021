
import ApiGestionCap from "@/services/ApiGestionCap";
import { useGlobalStore } from "@/store/globalStore";
import { usePendingStore } from "@/store/pendingStore";
import { AmazonRefTypeExport } from "@/type/Amazon/AmazonRefTypeExport";
import { isTokenReady } from "@baloise/vue-keycloak";
import { defineComponent, ref } from "vue";
import dialog from "@/services/DialogSite";
import { useI18n } from "vue-i18n";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";

export default defineComponent({
  name: "ExportsAmazon",
  setup() {
    const { t } = useI18n();
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const refTypeExports = ref([] as AmazonRefTypeExport[]);
    const loading = ref(false as boolean);
    return {
      t,
      globalStore,
      pendingStore,
      refTypeExports,
      loading,
    };
  },
  async mounted() {
    await isTokenReady();
    await this.getRefTypeExport();
  },
  methods: {
    async getRefTypeExport() {
      this.loading = true;
      try {
        this.refTypeExports = await ApiGestionCap.getAmazonRefTypeExport();
      } catch (error) {
        this.loading = false;
      }
      this.loading = false;
    },
    async exportAmazon(refTypeExport: AmazonRefTypeExport) {
      dialog
        .comfirm(
          this.t(
            `message.amazon.order_batch.export.comfirm.${refTypeExport.code}`,
            {
              libelle: refTypeExport.libelle,
            }
          ),
          true
        )
        .onOk(async () => {
          try {
            this.loading = true;
            await ApiGestionCap.generateExportAmazon(refTypeExport.id);
            this.loading = false;
            await this.getRefTypeExport();
          } catch (error) {
            router.push({ name: RouteName.Error });
          }
        });
    },
  },
});
