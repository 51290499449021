
import { ModelCustomerOrderCa } from "@/type/Return/ReturnType";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "BlockCustomerOrderCa",
  props: {
    modelCustomerOrderCa: {
      type: Object as PropType<ModelCustomerOrderCa>,
      required: true,
    },
  },
  setup() {
    const actualYear = new Date().getFullYear();

    return {
      actualYear,
    };
  },
  methods: {
    getAverageBasketPrice() {
      if (this.modelCustomerOrderCa.orderCount === 0) {
        return 0;
      }
      return this.modelCustomerOrderCa.ca / this.modelCustomerOrderCa.orderCount;
    },
  },
});
