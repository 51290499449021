import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "stepLine" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_PendingSpinner = _resolveComponent("PendingSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, [
      _createTextVNode(_toDisplayString(_ctx.$t(_ctx.title)) + " ", 1),
      _createVNode(_component_PendingSpinner, {
        isPending: _ctx.pending,
        classSpinner: 'stepLineSpinner'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_checkbox, {
            dense: "",
            modelValue: _ctx.status,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.status) = $event)),
            disable: "",
            "checked-icon": "task_alt",
            "unchecked-icon": "highlight_off"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["isPending"])
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t(_ctx.desc)), 1)
  ]))
}