import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row titleFamily"
}
const _hoisted_2 = { class: "row headerRight" }
const _hoisted_3 = { class: "libelle" }
const _hoisted_4 = {
  key: 0,
  class: "blockUpdateQtyNoReturnable danger"
}
const _hoisted_5 = {
  key: 1,
  class: "blockUpdateQtyNoReturnable"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_InputTextSite = _resolveComponent("InputTextSite")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_BinariStatus = _resolveComponent("BinariStatus")!
  const _component_q_checkbox = _resolveComponent("q-checkbox")!
  const _component_q_table = _resolveComponent("q-table")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_ctx.tempFamilyReturnItemRecommandations)
    ? (_openBlock(), _createBlock(_component_q_table, {
        key: 0,
        ref: 'tableReturnItem',
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        class: "tableBusiness tableReturnItem",
        "hide-pagination": "",
        "hide-bottom": "",
        "wrap-cells": "",
        "rows-per-page-options": [0],
        filter: _ctx.filter,
        "filter-method": _ctx.ServiceTable.filterData,
        separator: 'cell'
      }, {
        "top-left": _withCtx((props) => [
          _createVNode(_component_ButtonSite, {
            flat: "",
            round: "",
            typeButton: 'BASE',
            icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
            onClick: props.toggleFullscreen
          }, null, 8, ["icon", "onClick"]),
          (props.inFullscreen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.familyReturnItemRecommandations.regroupementFamily), 1))
            : _createCommentVNode("", true)
        ]),
        "top-right": _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ButtonSite, {
              flat: "",
              typeButton: 'BUSINESS',
              icon: "view_list",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.openModalReturnItemByFamily(
                _ctx.tempFamilyReturnItemRecommandations.regroupementFamily
              )
            )),
              label: 
              _ctx.$t('message.return.select_return.family_preco.table.list_stock')
            
            }, null, 8, ["label"]),
            (this.filter.lineModified === null)
              ? (_openBlock(), _createBlock(_component_ButtonSite, {
                  key: 0,
                  flat: "",
                  typeButton: 'BUSINESS',
                  icon: "visibility_off",
                  onClick: _ctx.hiddenLineModified,
                  label: 
                _ctx.$t(
                  'message.return.select_return.family_preco.table.hidden_line_modified'
                )
              
                }, null, 8, ["onClick", "label"]))
              : (_openBlock(), _createBlock(_component_ButtonSite, {
                  key: 1,
                  flat: "",
                  typeButton: 'BUSINESS',
                  icon: "visibility",
                  onClick: _ctx.displayAllRow,
                  label: 
                _ctx.$t(
                  'message.return.select_return.family_preco.table.view_all_line'
                )
              
                }, null, 8, ["onClick", "label"]))
          ])
        ]),
        header: _withCtx((props) => [
          _createVNode(_component_q_tr, { props: props }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                return (_openBlock(), _createBlock(_component_q_th, {
                  key: col.name,
                  props: props
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(col.label)), 1)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"]),
          _createVNode(_component_q_tr, {
            props: props,
            class: "searchHeader"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                return (_openBlock(), _createBlock(_component_q_td, {
                  key: col.name,
                  props: props
                }, {
                  default: _withCtx(() => [
                    (_ctx.filter.hasOwnProperty(col.name))
                      ? (_openBlock(), _createBlock(_component_InputTextSite, {
                          key: 0,
                          modelValue: _ctx.filter[col.name],
                          "onUpdate:modelValue": ($event: any) => ((_ctx.filter[col.name]) = $event),
                          dense: ""
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["props"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        body: _withCtx((props) => [
          _createVNode(_component_q_tr, {
            props: props,
            class: _normalizeClass(
            (props.row.lineModified ? 'lineModified' : '') +
            ' ' +
            (props.row.qtySelected +
              props.row.qtyStockReservedCustomer -
              props.row.qtyStockCustomer >
            0
              ? 'reserved'
              : '')
          )
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_td, {
                key: "detail",
                class: "detail",
                props: props
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_ButtonSite, {
                    flat: "",
                    round: "",
                    typeButton: 'BASE',
                    icon: "zoom_in",
                    onClick: ($event: any) => (_ctx.openModalStatsDetailReturnItem(props.row.id))
                  }, null, 8, ["onClick"]), [
                    [_directive_ripple]
                  ])
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "idErpItem",
                class: "idErpItem",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.idErpItemFormated), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "shortRef",
                class: "shortRef",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.shortRef), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "stock_customer",
                class: "stock_customer",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.qtyStockCustomer), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "stock_reserved",
                class: "stock_reserved",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.qtyStockReservedCustomer), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "stock_type",
                class: "stock_type",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.qtyStockType), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "purchase",
                class: "purchase",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.historicalQuantityBuyToCap), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "last_purchase_date",
                class: _normalizeClass(
              _ctx.checkIfLastPurchaseDateIsOver6Month(
                props.row.formatedDateLastBuyToCap
              )
                ? 'buyRecently'
                : ''
            ),
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$d(props.row.formatedDateLastBuyToCap, "short", "fr")), 1)
                ]),
                _: 2
              }, 1032, ["class", "props"]),
              _createVNode(_component_q_td, {
                key: "unit_price",
                class: "unit_price",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$n(props.row.unitPriceHt, "price")), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "preco",
                class: "preco",
                props: props
              }, {
                default: _withCtx(() => [
                  (props.row.qtyProposed > 0)
                    ? (_openBlock(), _createBlock(_component_BinariStatus, {
                        key: 0,
                        status: true
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "max_available_qty",
                class: "max_available_qty",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(props.row.qtyPossibleToReturn), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "qty_selected",
                class: "qty_selected",
                props: props
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(
                'blockUpdateQtySelected ' +
                (_ctx.getReturnData?.returnItemsSelected
                  ? 'returnItemsSelected'
                  : '')
              )
                  }, [
                    (!_ctx.getReturnData?.returnItemsSelected)
                      ? _withDirectives((_openBlock(), _createBlock(_component_ButtonSite, {
                          key: 0,
                          dense: "",
                          flat: "",
                          round: "",
                          icon: "remove",
                          typeButton: 'BASE',
                          class: "btnQtySelected",
                          onClick: ($event: any) => (_ctx.lessQtySelected(props.row))
                        }, null, 8, ["onClick"])), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(props.row.qtySelected), 1),
                    (!_ctx.getReturnData?.returnItemsSelected)
                      ? _withDirectives((_openBlock(), _createBlock(_component_ButtonSite, {
                          key: 1,
                          dense: "",
                          flat: "",
                          round: "",
                          icon: "add",
                          typeButton: 'BASE',
                          class: "btnQtySelected",
                          onClick: ($event: any) => (_ctx.addQtySelected(props.row))
                        }, null, 8, ["onClick"])), [
                          [_directive_ripple]
                        ])
                      : _createCommentVNode("", true)
                  ], 2)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "amount",
                class: "amount",
                props: props
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$n(props.row.qtySelected * props.row.unitPriceHt, "price")), 1)
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "item_no_returnable",
                class: "item_no_returnable",
                props: props
              }, {
                default: _withCtx(() => [
                  (_ctx.modifyNoReturnable[props.row.id])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (!_ctx.getReturnData?.returnItemsSelected)
                          ? _withDirectives((_openBlock(), _createBlock(_component_ButtonSite, {
                              key: 0,
                              dense: "",
                              flat: "",
                              round: "",
                              icon: "remove",
                              typeButton: 'BASE',
                              class: "btnQtySelected",
                              onClick: ($event: any) => (_ctx.lessQtyNoReturnable(props.row))
                            }, null, 8, ["onClick"])), [
                              [_directive_ripple]
                            ])
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(props.row.qtyNoReturnable), 1),
                        (!_ctx.getReturnData?.returnItemsSelected)
                          ? _withDirectives((_openBlock(), _createBlock(_component_ButtonSite, {
                              key: 1,
                              dense: "",
                              flat: "",
                              round: "",
                              icon: "add",
                              typeButton: 'BASE',
                              class: "btnQtySelected",
                              onClick: ($event: any) => (_ctx.addQtyNoReturnable(props.row))
                            }, null, 8, ["onClick"])), [
                              [_directive_ripple]
                            ])
                          : _createCommentVNode("", true),
                        (!_ctx.getReturnData?.returnItemsSelected)
                          ? _withDirectives((_openBlock(), _createBlock(_component_ButtonSite, {
                              key: 2,
                              dense: "",
                              flat: "",
                              round: "",
                              icon: "close",
                              typeButton: 'BASE',
                              class: "btnQtySelected",
                              onClick: ($event: any) => (_ctx.modifyNoReturnable[props.row.id] = false)
                            }, null, 8, ["onClick"])), [
                              [_directive_ripple]
                            ])
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createTextVNode(_toDisplayString(props.row.qtyNoReturnable), 1),
                        (!_ctx.getReturnData?.returnItemsSelected)
                          ? _withDirectives((_openBlock(), _createBlock(_component_ButtonSite, {
                              key: 0,
                              dense: "",
                              flat: "",
                              round: "",
                              icon: "edit",
                              typeButton: 'BASE',
                              class: "btnQtySelected",
                              onClick: ($event: any) => (_ctx.modifyNoReturnableAlert(props.row.id))
                            }, null, 8, ["onClick"])), [
                              [_directive_ripple]
                            ])
                          : _createCommentVNode("", true)
                      ]))
                ]),
                _: 2
              }, 1032, ["props"]),
              _createVNode(_component_q_td, {
                key: "check",
                class: "check",
                props: props
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_checkbox, {
                    dense: "",
                    modelValue: props.row.lineModified,
                    "onUpdate:modelValue": ($event: any) => ((props.row.lineModified) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["props", "class"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "filter-method"]))
    : _createCommentVNode("", true)
}