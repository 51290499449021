/**
 * Fichier de constante du router qui permet de gérer les nom des routes et les droit des utilisateurs à ses routes
 */


const SymfonyRoles = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_ADV: 'ROLE_ADV',
    ROLE_USER: 'ROLE_USER',
    ROLE_SUPER_ADV: 'ROLE_SUPER_ADV',
    ROLE_FINANCE: 'ROLE_FINANCE',
    ROLE_SUPER_FINANCE: 'ROLE_SUPER_FINANCE',
    ROLE_COMMERCIAL: 'ROLE_COMMERCIAL',
}

const RouteName: { [key: string]: string } = {
    Home: 'Home',
    Customers: 'Customers',
    Customer: 'Customer',
    CreateReturnOrder: 'CreateReturnOrder',
    SelectReturnItem: 'SelectReturnItem',
    CheckReturnOrder: 'CheckReturnOrder',
    CustomerPurchase: 'CustomerPurchase',
    CustomerStock: 'CustomerStock',
    CustomerOrders: 'CustomerOrders',
    SelectShipping: 'SelectShipping',
    ReturnValidation: 'ReturnValidation',
    Unauthorized: 'Unauthorized',
    Error: 'Error',
    GestionRefTypeCustomerGroup: 'GestionRefTypeCustomerGroup',
    CreateUser: 'CreateUser',
    ItemExcluded: 'ItemExcluded',
    RefTypeCustomerGroupMG: 'RefTypeCustomerGroupMG',
    MGUsers: 'MGUsers',
    MGRefAutoCenter: 'MGRefAutoCenter',
    MGForceUpdateItem: 'MGForceUpdateItem',
    AmazonOrderBatchs: 'AmazonOrderBatchs',
    AmazonDetailOrderBatch: 'AmazonDetailOrderBatch',
    AmazonShippingStats: 'AmazonShippingStats',
    AmazonManageShppingStatusRemainder: 'AmazonManageShppingStatusRemainder'
}


const RouterRoles: { [key: string]: string[] } = {}



RouterRoles[RouteName.Home] = [
    SymfonyRoles.ROLE_USER
]

RouterRoles[RouteName.Customers] = [
    SymfonyRoles.ROLE_USER
]

RouterRoles[RouteName.Customer] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.CreateReturnOrder] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.SelectReturnItem] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.CheckReturnOrder] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.SelectShipping] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.ReturnValidation] = [
    SymfonyRoles.ROLE_USER
]

RouterRoles[RouteName.CustomerPurchase] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.CustomerStock] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.CustomerOrders] = [
    SymfonyRoles.ROLE_COMMERCIAL,
    SymfonyRoles.ROLE_ADV
]


RouterRoles[RouteName.GestionRefTypeCustomerGroup] = [
    SymfonyRoles.ROLE_SUPER_ADV
]

RouterRoles[RouteName.CreateUser] = [
    SymfonyRoles.ROLE_ADMIN
]

RouterRoles[RouteName.ItemExcluded] = [
    SymfonyRoles.ROLE_SUPER_ADV, SymfonyRoles.ROLE_ADMIN
]

RouterRoles[RouteName.RefTypeCustomerGroupMG] = [
    SymfonyRoles.ROLE_SUPER_ADV, SymfonyRoles.ROLE_ADMIN
]

RouterRoles[RouteName.MGUsers] = [
    SymfonyRoles.ROLE_SUPER_ADV, SymfonyRoles.ROLE_SUPER_FINANCE, SymfonyRoles.ROLE_ADMIN
]

RouterRoles[RouteName.MGRefAutoCenter] = [
    SymfonyRoles.ROLE_SUPER_ADV, SymfonyRoles.ROLE_ADMIN
]

RouterRoles[RouteName.MGForceUpdateItem] = [
    SymfonyRoles.ROLE_SUPER_ADV, SymfonyRoles.ROLE_ADMIN
]

RouterRoles[RouteName.AmazonOrderBatchs] = [
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.AmazonDetailOrderBatch] = [
    SymfonyRoles.ROLE_ADV
]
RouterRoles[RouteName.AmazonManageShppingStatusRemainder] = [
    SymfonyRoles.ROLE_ADV
]

RouterRoles[RouteName.AmazonShippingStats] = [
    SymfonyRoles.ROLE_ADV
]

export { RouterRoles, RouteName }