import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "q-pa-md",
  ref: "MGUsers"
}
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 0,
  class: "col-12 blockForm"
}
const _hoisted_8 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12 blockForm" }
const _hoisted_11 = { class: "row text-center" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "row blockTable" }
const _hoisted_14 = {
  key: 0,
  class: "col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_ButtonSite = _resolveComponent("ButtonSite")!
  const _component_Separator = _resolveComponent("Separator")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_UserLog = _resolveComponent("UserLog")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.gestion_users_mg")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_ctx.customergroups)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_q_select, {
                  outlined: "",
                  modelValue: _ctx.selectCustomerGroup,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectCustomerGroup) = $event)),
                  options: _ctx.customergroups,
                  clearable: "",
                  class: "qselect",
                  "option-value": "id",
                  label: _ctx.$t('message.search.searchCustomer.selectCustomerGroup')
                }, {
                  "selected-item": _withCtx((scope) => [
                    _createVNode(_component_q_item, _mergeProps(scope.opt, { class: "blockCustomerGroup" }), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.opt.libelle), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040),
                    (scope.opt.logo)
                      ? (_openBlock(), _createBlock(_component_q_item_section, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_img, {
                              src: scope.opt.logo,
                              fit: 'scale-down'
                            }, null, 8, ["src"])
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  option: _withCtx((scope) => [
                    _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(scope.itemProps)), {
                      default: _withCtx(() => [
                        _createVNode(_component_q_item_section, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_item_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(scope.opt.libelle), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  _: 1
                }, 8, ["modelValue", "options", "label"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_q_input, {
              modelValue: _ctx.idErpCustomer,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.idErpCustomer) = $event)),
              type: "text",
              label: _ctx.$t('message.search.searchCustomer.customerId'),
              class: "inputText",
              outlined: ""
            }, null, 8, ["modelValue", "label"])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_ButtonSite, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchUsers(null))),
              typeButton: 'INFO'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("message.btn.search")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createVNode(_component_Separator),
    _createElementVNode("div", _hoisted_13, [
      (_ctx.rows)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_q_table, {
              ref: 'tableUsersMG',
              rows: _ctx.rows,
              "rows-per-page-options": [10, 20, 50, 100],
              columns: _ctx.columns,
              "row-key": "id",
              class: "tableQuasar",
              "wrap-cells": "",
              pagination: _ctx.pagination,
              "onUpdate:pagination": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pagination) = $event)),
              "binary-state-sort": "",
              loading: _ctx.loading,
              onRequest: _ctx.searchUsers
            }, {
              "top-left": _withCtx((props) => [
                _createVNode(_component_q_btn, {
                  flat: "",
                  round: "",
                  icon: props.inFullscreen ? 'fullscreen_exit' : 'fullscreen',
                  onClick: props.toggleFullscreen,
                  class: "q-ml-md"
                }, null, 8, ["icon", "onClick"])
              ]),
              header: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                      return (_openBlock(), _createBlock(_component_q_th, {
                        key: col.name,
                        props: props
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                        ]),
                        _: 2
                      }, 1032, ["props"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              body: _withCtx((props) => [
                _createVNode(_component_q_tr, { props: props }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_td, {
                      key: "username",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.modelUserInfoKeycloak.username), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "firstname",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.modelUserInfoKeycloak.firstName), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "lastname",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.modelUserInfoKeycloak.lastName), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "email",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.modelUserInfoKeycloak.email), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "idErpRefAutoCenter",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.idRefAutoCenter.idErp), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "nameRefAutoCenter",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.idRefAutoCenter.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "nameRefTypeCustomerGroup",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(props.row.idRefAutoCenter.idRefTypeCustomerGroup.libelle), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "dateLastLoginTimestamp",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$d(props.row.formatedDateLastLogin, "long", "fr")), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]),
                    _createVNode(_component_q_td, {
                      key: "accessLog",
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_UserLog, {
                          idUserSite: props.row.id
                        }, null, 8, ["idUserSite"])
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["props"])
              ]),
              _: 1
            }, 8, ["rows", "columns", "pagination", "loading", "onRequest"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512))
}