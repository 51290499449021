/**
 * Service qui permet de créer des notification quasar
 */

import Notify from 'quasar/src/plugins/Notify.js';;
import { i18n } from '@/translations';
const { t } = i18n.global;

const error = (message: string) => {
  Notify.create({
    message,
    type: 'negative',
    color: 'DANGER',
    actions: [
      { label: t('message.btn.close'), color: 'white', handler: () => { /* ... */ } },
    ],
  });
};

const primary = (message: string) => {
  Notify.create({
    message,
    type: 'info',
    color: 'ACTION',
    actions: [
      { label: t('message.btn.close'), color: 'white', handler: () => { /* ... */ } },
    ],
  });
};

const mercure = (message: string) => {
  Notify.create({
    message,
    type: 'info',
    color: 'MERCURE',
    actions: [
      { label: t('message.btn.close'), color: 'white', handler: () => { /* ... */ } },
    ],
  });
};

const success = (message: string) => {
  Notify.create({
    message,
    type: 'positive',
    color: 'SUCCESS',
    actions: [
      { label: t('message.btn.close'), color: 'white', handler: () => { /* ... */ } },
    ],
  });
};

const ongoing = (message: string) => {
  const notify = Notify.create({
    message,
    type: 'ongoing',
    color: 'ONGOING',
  });
  return notify
};

const defaultParamsSuccess = (message : string) => {
  return {
    message,
    type: 'positive',
    color: 'SUCCESS',
    icon: 'done',
    actions: [
      { label: t('message.btn.close'), color: 'white', handler: () => { /* ... */ } },
    ],
  };
}

const defaultParamsFail = (message : string) => {
  return {
    message,
    type: 'negative',
    color: 'DANGER',
    icon: 'error',
    actions: [
      { label: t('message.btn.close'), color: 'white', handler: () => { /* ... */ } },
    ],
  };
}


export default {
  error,
  primary,
  success,
  ongoing,
  defaultParamsSuccess,
  defaultParamsFail,
  mercure,
};