import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_3 = { class: "blockDetail" }
const _hoisted_4 = { class: "libelle" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_7 = {
  key: 0,
  class: "blockDetail"
}
const _hoisted_8 = { class: "libelle" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = {
  key: 1,
  class: "row"
}
const _hoisted_11 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_12 = {
  key: 0,
  class: "blockDetail"
}
const _hoisted_13 = { class: "value" }
const _hoisted_14 = {
  key: 1,
  class: "blockDetail"
}
const _hoisted_15 = { class: "libelle" }
const _hoisted_16 = { class: "value" }
const _hoisted_17 = {
  key: 2,
  class: "blockDetail"
}
const _hoisted_18 = { class: "libelle" }
const _hoisted_19 = { class: "value" }
const _hoisted_20 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_21 = {
  key: 0,
  class: "blockDetail"
}
const _hoisted_22 = { class: "libelle" }
const _hoisted_23 = { class: "value" }
const _hoisted_24 = {
  key: 1,
  class: "blockDetail"
}
const _hoisted_25 = { class: "libelle" }
const _hoisted_26 = { class: "value" }
const _hoisted_27 = {
  key: 2,
  class: "blockDetail"
}
const _hoisted_28 = { class: "libelle" }
const _hoisted_29 = { class: "value" }
const _hoisted_30 = {
  key: 2,
  class: "row"
}
const _hoisted_31 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_32 = { class: "blockDetail" }
const _hoisted_33 = { class: "libelle" }
const _hoisted_34 = { class: "value" }
const _hoisted_35 = {
  key: 0,
  class: "blockDetail"
}
const _hoisted_36 = { class: "libelle" }
const _hoisted_37 = { class: "value" }
const _hoisted_38 = { class: "col-md-6 col-sm-12 col-xs-12" }
const _hoisted_39 = {
  key: 0,
  class: "blockDetail"
}
const _hoisted_40 = { class: "libelle" }
const _hoisted_41 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.log.idRefTypeLog.code === _ctx.CODE_IMMAT)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.immat")), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.log.immat), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.log.vehicleVariations)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.vehicleVariations")), 1),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.log.vehicleVariations), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.log.idRefTypeLog.code === _ctx.CODE_ITEM)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_ctx.log.isOutOfCatalog)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.isOutOfCatalog")), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.log.vehicleVariations)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.vehicleVariations")), 1),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.log.vehicleVariations), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.log.searchText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.searchText")), 1),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.log.searchText), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_20, [
            (_ctx.log.idRefTypeItem)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.idRefTypeItem")), 1),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.log.idRefTypeItem.libelle), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.log.idRefFamilyItem)
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.idRefFamilyItem")), 1),
                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.log.idRefFamilyItem.libelle), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.log.idRefTypeRepair)
              ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.idRefTypeRepair")), 1),
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.log.idRefTypeRepair.libelle), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.log.idRefTypeLog.code === _ctx.CODE_ORDER)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.totalPriceOrderItems")), 1),
              _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$n(_ctx.log.idOrders.totalPriceOrderItems,'price')), 1)
            ]),
            (_ctx.log.idOrders.externalDocumentNo)
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.externalDocumentNo")), 1),
                  _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.log.idOrders.externalDocumentNo), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_38, [
            (_ctx.log.idOrders.erpOrderIds)
              ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                  _createElementVNode("span", _hoisted_40, _toDisplayString(_ctx.$t("message.users_mg.logs.detail.erpOrderIds")), 1),
                  _createElementVNode("span", _hoisted_41, _toDisplayString(typeof Array.isArray(_ctx.log.idOrders.erpOrderIds)
              ? _ctx.log.idOrders.erpOrderIds.join(", ")
              : _ctx.log.idOrders.erpOrderIds), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}