import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "q-pa-md",
  ref: "MGExcludedItem"
}
const _hoisted_2 = { class: "row justify-center" }
const _hoisted_3 = { class: "col-md-12 title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Separator = _resolveComponent("Separator")!
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_ExcludedTable = _resolveComponent("ExcludedTable")!
  const _component_q_tab_panel = _resolveComponent("q-tab-panel")!
  const _component_q_tab_panels = _resolveComponent("q-tab-panels")!
  const _component_q_splitter = _resolveComponent("q-splitter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("message.router.title.excluded_item")), 1)
      ])
    ]),
    _createVNode(_component_Separator),
    _createVNode(_component_q_splitter, {
      modelValue: _ctx.splitterModel,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.splitterModel) = $event))
    }, {
      before: _withCtx(() => [
        _createVNode(_component_q_tabs, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tab) = $event)),
          vertical: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab, {
              name: _ctx.itcName,
              label: _ctx.$t('message.excluded_item.itc')
            }, null, 8, ["name", "label"]),
            _createVNode(_component_q_tab, {
              name: _ctx.pgcName,
              label: _ctx.$t('message.excluded_item.pgc')
            }, null, 8, ["name", "label"]),
            _createVNode(_component_q_tab, {
              name: _ctx.rfName,
              label: _ctx.$t('message.excluded_item.rf')
            }, null, 8, ["name", "label"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      after: _withCtx(() => [
        _createVNode(_component_q_tab_panels, {
          modelValue: _ctx.tab,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tab) = $event)),
          animated: "",
          swipeable: "",
          vertical: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab_panel, { name: _ctx.itcName }, {
              default: _withCtx(() => [
                _createVNode(_component_ExcludedTable, { excludedType: _ctx.itcName }, null, 8, ["excludedType"])
              ]),
              _: 1
            }, 8, ["name"]),
            _createVNode(_component_q_tab_panel, { name: _ctx.pgcName }, {
              default: _withCtx(() => [
                _createVNode(_component_ExcludedTable, { excludedType: _ctx.pgcName }, null, 8, ["excludedType"])
              ]),
              _: 1
            }, 8, ["name"]),
            _createVNode(_component_q_tab_panel, { name: _ctx.rfName }, {
              default: _withCtx(() => [
                _createVNode(_component_ExcludedTable, { excludedType: _ctx.rfName }, null, 8, ["excludedType"])
              ]),
              _: 1
            }, 8, ["name"])
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 512))
}