
import { RouteName } from "@/constante/RouterConst";
import ApiGestionCap from "@/services/ApiGestionCap";
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import { useReturnStore } from "@/store/returnStore";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import { usePendingStore } from "@/store/pendingStore";
import { ModelHistoPurchaseStockTypeEtai } from "@/type/Return/ReturnType";
import InputTextSite from "@/components/customInput/InputTextSite.vue";
import { useGlobalStore } from "@/store/globalStore";
import { ErrorSite, ERROR_TYPE } from "@/type/Error";
import { useI18n } from "vue-i18n";
import BlockMsgError from "@/components/global/BlockMsgError.vue";
import ServiceFormatDate from "@/services/ServiceFormatDate";
import ServiceTable from "@/services/ServiceTable";

export default defineComponent({
  name: "CustomerPurchase",
  components: {
    HeaderCustomerData,
    InputTextSite,
    BlockMsgError,
  },
  setup() {
    const { t, d } = useI18n();
    const globalStore = useGlobalStore();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const idCustomer = ref(null as string | null);

    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const data = ref(null as ModelHistoPurchaseStockTypeEtai[][] | null);

    const rows = ref([] as ModelHistoPurchaseStockTypeEtai[][]);

    const columns = ref([] as any[]);

    const family = ref([] as string[]);

    const filter = ref({
      idErpItem: "" as string,
      vendorItemNo: "" as string,
      regroupementFamily: "" as string,
    });

    return {
      returnStore,
      idCustomer,
      getCustomer,
      pendingStore,
      data,
      rows,
      columns,
      family,
      filter,
      globalStore,
      t,
      d,
      ServiceTable,
    };
  },
  async mounted() {
    this.globalStore.$patch({ errorMessage: null });
    this.pendingStore.$patch({ routerIsPending: true });
    await isTokenReady();
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: "Customers" });
    }
    //récupération des données du customer
    try {
      this.returnStore.refreshCustomer(this.idCustomer);
    } catch (error) {
      this.pendingStore.$patch({ routerIsPending: false });
      this.$router.push({ name: RouteName.Error });
    }
    //récupération des données purchase
    try {
      this.data = await ApiGestionCap.getHistoPurchase(this.idCustomer);
      if (this.data.length === 0) {
        const errorSite = {
          message: this.t("message.customer.customer_purchase.no_data"),
          type: ERROR_TYPE.GLOBAL,
        } as ErrorSite;
        this.globalStore.$patch({ errorMessage: errorSite });
      } else {
        this.initColumn(this.data);
        this.initFamily(this.data);
        this.rows = this.data;
      }
    } catch (error) {
      this.pendingStore.$patch({ routerIsPending: false });
      this.$router.push({ name: RouteName.Error });
    }
    this.pendingStore.$patch({ routerIsPending: false });
  },
  methods: {
    initColumn(data: ModelHistoPurchaseStockTypeEtai[][]) {
      this.columns = [
        {
          name: "regroupementFamily",
          required: true,
          label: "message.customer.customer_purchase.table.regroupementFamily",
          align: "left",
          field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
            row[0].regroupementFamily,
          sortable: true,
          classes: "fixedWidth",
        },
        {
          name: "idErpItem",
          required: true,
          label: "message.customer.customer_purchase.table.idErpItem",
          align: "left",
          field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
            row[0].idErpItemFormated,
          sortable: true,
          classes: "fixedWidth",
        },
        {
          name: "vendorItemNo",
          required: true,
          label: "message.customer.customer_purchase.table.vendorItemNo",
          align: "left",
          field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
            row[0].vendorItemNo,
          sortable: true,
          classes: "fixedWidth",
        },
      ];
      //ajout des colonnes pour chaque mois
      data[0].forEach(
        (modelHistoPurchaseStockTypeEtai: ModelHistoPurchaseStockTypeEtai) => {
          this.columns.push({
            name: modelHistoPurchaseStockTypeEtai.monthYear,
            required: true,
            label: this.d(
              ServiceFormatDate.ParseMonthYearString(
                modelHistoPurchaseStockTypeEtai.monthYear
              ),
              "nameMonthYear"
            ),
            noTrans: true,
            align: "center",
            field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
              row.find(
                (value: ModelHistoPurchaseStockTypeEtai) =>
                  modelHistoPurchaseStockTypeEtai.monthYear === value.monthYear
              )?.quantityPurchase,
            sortable: true,
          });
        }
      );
      this.columns.push(
        {
          name: "totalPurchase",
          required: true,
          label: "message.customer.customer_purchase.table.totalPurchase",
          align: "center",
          field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
            row.reduce(
              (
                accumulator: number,
                currentValue: ModelHistoPurchaseStockTypeEtai
              ) => accumulator + currentValue.quantityPurchase,
              0
            ),
          sortable: true,
        },
        {
          name: "quantityRequested",
          required: true,
          label: "message.customer.customer_purchase.table.quantityRequested",
          align: "center",
          field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
            row[0].quantityRequested,
          sortable: true,
        },
        {
          name: "quantityStockType",
          required: true,
          label: "message.customer.customer_purchase.table.quantityStockType",
          align: "center",
          field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
            row[0].quantityStockType,
          sortable: true,
        },
        {
          name: "averagePurchase",
          required: true,
          label: "message.customer.customer_purchase.table.averagePurchase",
          align: "center",
          field: (row: ModelHistoPurchaseStockTypeEtai[]) =>
            (
              row.reduce(
                (
                  accumulator: number,
                  currentValue: ModelHistoPurchaseStockTypeEtai
                ) => accumulator + currentValue.quantityPurchase,
                0
              ) / row.length
            ).toFixed(2),
          sortable: true,
        }
      );
    },
    initFamily(data: ModelHistoPurchaseStockTypeEtai[][]) {
      data.forEach(
        (
          modelHistoPurchaseStockTypeEtai: ModelHistoPurchaseStockTypeEtai[]
        ) => {
          //controle si la famille est déjà présente dans le tableau
          if (
            !this.family.includes(
              modelHistoPurchaseStockTypeEtai[0].regroupementFamily
            )
          ) {
            this.family.push(
              modelHistoPurchaseStockTypeEtai[0].regroupementFamily
            );
          }
        }
      );
      //trie du tableau par ordre alphabétique
      this.family.sort();
    },
    filterData(rows: any[], filters: any) {
      for (const [key, value] of Object.entries(filters)) {
        //filtre sur les colonnes avec le filtre correspondant à la clef voulu
        //test du type du filtre (string ou boolean ou null)
        if (typeof value === "string") {
          const filterValue = filters[key].toLowerCase().replace(/\s/g, "");
          rows = rows.filter(
            (row) =>
              (row[0][key] + "")
                .toLowerCase()
                .replace(/\s/g, "")
                .indexOf(filterValue) !== -1
          );
        }
      }
      return rows;
    },
  },
});
