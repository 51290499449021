
import { defineComponent, computed, ref } from "vue";
import { useKeycloak, isTokenReady, getToken } from "@baloise/vue-keycloak";

// Components
import Header from "../CAP_VUE_BUNDLE/headerBundle/components/Header.vue";
import Footer from "../CAP_VUE_BUNDLE/footerBundle/components/Footer.vue";
import PendingSpinnerFullCover from "@/components/global/PendingSpinnerFullCover.vue";
import { usePendingStore } from "@/store/pendingStore";
import { useUserStore } from "./store/userStore";
import { useI18n } from "vue-i18n";
import { router } from "@/router";
import { RouteRecordRaw, useRouter } from "vue-router";
import Home from "@/views/Home.vue";
import { ListIdClientSso } from "./type/MenuSSOType";
import ApiGestionCap from "./services/ApiGestionCap";
import ServiceCreateRoute from "./services/ServiceCreateRoute";
import { useGlobalStore } from "./store/globalStore";

export default defineComponent({
  components: {
    Header,
    Footer,
    PendingSpinnerFullCover,
  },

  setup() {
    const globalStore = useGlobalStore();
    const initIsEnded = ref(false);
    const { t } = useI18n();
    const pendingStore = usePendingStore();
    const userStore = useUserStore();
    pendingStore.$patch({ firstLoading: true });
    const getFirstLoading = computed(() => {
      return pendingStore.getFirstLoading;
    });
    const getRouterIsPending = computed(() => {
      return pendingStore.getRouterIsPending;
    });

    //chargement et identification keycloak
    const { isAuthenticated, keycloak, isPending, username } = useKeycloak();
    const isLoading = computed(() => keycloak != null && isPending.value);
    const keycloakInstance = computed(() => useKeycloak().keycloak);

    const email = computed(() =>
      undefined === keycloakInstance.value ||
      undefined === keycloakInstance.value.tokenParsed
        ? ""
        : keycloakInstance.value.tokenParsed.email
    );
    const token = computed(() =>
      undefined === keycloakInstance.value ||
      undefined === keycloakInstance.value.token
        ? ""
        : keycloakInstance.value.token
    );

    const user = computed(() => username.value);
    const title = process.env.VUE_APP_TITLE;

    const getMenuSSO = computed(() => {
      return globalStore.getMenuSSO;
    });
    return {
      globalStore,
      isAuthenticated,
      isLoading,
      user,
      title,
      keycloakInstance,
      email,
      token,
      getRouterIsPending,
      getFirstLoading,
      userStore,
      pendingStore,
      t,
      initIsEnded,
      getMenuSSO,
    };
  },
  async mounted() {
    await isTokenReady();

    const listIdClientSsos = {
      listIdClientSso: Object.keys(this.keycloakInstance.resourceAccess as any),
    } as ListIdClientSso;

    await this.globalStore.setMenuSSO(listIdClientSsos);
    const menuSSO = this.getMenuSSO;

    if (menuSSO && menuSSO.ssoSiteInfoMenus !== undefined) {
      menuSSO.ssoSiteInfoMenus.forEach((siteSSO) => {
        if (siteSSO.idClientSso !== process.env.VUE_APP_KEYCLOAK_CLIENT) {
          const newRoute = ServiceCreateRoute.createRoute(siteSSO);
          this.$router.addRoute(newRoute);
          this.$router.replace(this.$router.currentRoute.value.fullPath);
        }
      });
    }
    const userInfo = (await this.keycloakInstance.loadUserInfo()) as any;
    this.userStore.$patch({
      user: {
        idKeycloak: userInfo.sub,
        name: userInfo.name,
        userName: userInfo.preferred_username,
      },
    });
    this.initIsEnded = true;
    this.pendingStore.$patch({ firstLoading: false });
  },
  methods: {
    async logout() {
      const token = await getToken();
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + token,
        },
        body: new URLSearchParams({
          id_token_hint: this.keycloakInstance.idToken as string,
        }),
      };
      await fetch(
        process.env.VUE_APP_KEYCLOAK_URL +
          "/realms/" +
          process.env.VUE_APP_KEYCLOAK_REALM +
          "/protocol/openid-connect/logout",
        options
      ).catch(() => window.location.reload());
    },
  },
});
