const AMAZ_STATUS_TYPE_FINAL = "DEFINITIF";
const AMAZ_STATUS_TYPE_NOT_FINAL = "PAS DEFINITIF";
const AMAZ_STATUS_DELIVERY = 'Livré';
const AMAZ_STATUS_IMPOSSIBLE_DELIVERY = 'Non_livrable';
const AMAZ_STATUS_LOST = 'Perdu';
const AMAZ_STATUS_REFUSED = 'Refusé_à_la_livraison';
const AMAZ_STATUS_DELIVERY_IN_PROGRESS = 'En_cours_de_livraison';
const AMAZ_STATUS_TRANSIT = 'En_transit';
const AMAZ_STATUS_SHIPPED = 'Expédié';
const AMAZ_STATUS_DELAYED = 'Retardé';

export {
    AMAZ_STATUS_TYPE_FINAL,
    AMAZ_STATUS_TYPE_NOT_FINAL,
    AMAZ_STATUS_DELIVERY,
    AMAZ_STATUS_IMPOSSIBLE_DELIVERY,
    AMAZ_STATUS_LOST,
    AMAZ_STATUS_REFUSED,
    AMAZ_STATUS_DELIVERY_IN_PROGRESS,
    AMAZ_STATUS_TRANSIT,
    AMAZ_STATUS_SHIPPED,
    AMAZ_STATUS_DELAYED,
}