
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import HeaderCustomerData from "@/components/customers/HeaderCustomerData.vue";
import HeaderStepButton from "@/components/return/HeaderStepButton.vue";
import { useReturnStore } from "@/store/returnStore";
import { RouteName } from "@/constante/RouterConst";
import ButtonSite from "@/components/customInput/ButtonSite.vue";
import { usePendingStore } from "@/store/pendingStore";
import TableModelDetailByFamily from "@/components/return/TableModelDetailByFamily.vue";
import TableDetailItemPreco from "@/components/return/TableDetailItemPreco.vue";
import dialog from "@/services/DialogSite";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CheckReturnOrder",
  components: {
    HeaderCustomerData,
    HeaderStepButton,
    ButtonSite,
    TableModelDetailByFamily,
    TableDetailItemPreco,
  },
  setup() {
    const { t } = useI18n();
    const pendingStore = usePendingStore();
    const returnStore = useReturnStore();
    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const getReturnData = computed(() => {
      return returnStore.getReturnData;
    });

    const getHeaderStepReturn = computed(() => {
      return returnStore.getHeaderStepReturn;
    });

    const getReturnPreco = computed(() => {
      return returnStore.getReturnPreco;
    });

    const idCustomer = ref(null as string | null);

    const getTotalQtySelected = computed(() => {
      return returnStore.getTotalQtySelected();
    });

    return {
      getCustomer,
      getReturnData,
      getHeaderStepReturn,
      idCustomer,
      returnStore,
      getReturnPreco,
      pendingStore,
      t,
      getTotalQtySelected,
    };
  },
  async mounted() {
    await isTokenReady();
    this.pendingStore.$patch({ routerIsPending: true });
    this.idCustomer = this.$route.params.idCustomer as string;
    if (!this.idCustomer) {
      this.$router.push({ name: RouteName.Customers });
    }
    await this.returnStore.initReturn(this.idCustomer);
    if (!this.getReturnData) {
      this.$router.push({
        name: RouteName.CreateReturnOrder,
        params: { idCustomer: this.idCustomer },
      });
    }
    if (this.getReturnData && this.getReturnData.allStatusStepOk) {
      //récupération de la préco dans le store
      await this.returnStore.refreshReturnPreco();
      this.pendingStore.$patch({ routerIsPending: false });
    }
    if(this.getTotalQtySelected === 0) {
      this.$router.push({
        name: RouteName.SelectReturnItem,
        params: { idCustomer: this.idCustomer },
      });
    }
    
    this.returnStore.initHeaderStepReturn();
  },
  methods: {
    async nextStep() {
      if (this.getCustomer) {
        const TableModelDetailByFamily = this.$refs
          .TableModelDetailByFamily as any;
        if (TableModelDetailByFamily) {
          if (TableModelDetailByFamily.checkAllCheckBoxAreChecked()) {
            this.$router.push({
              name: RouteName.SelectShipping,
              params: { idCustomer: this.getCustomer.id },
            });
          } else {
            dialog.alertReturn(
              this.t(
                "message.return.check_return.alert_not_all_return_item_checked"
              )
            );
          }
        }
      }
    },
  },
});
