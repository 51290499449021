
import { defineComponent, ref } from "vue";
import PendingSpinner from "@/components/global/PendingSpinner.vue";

export default defineComponent({
  name: "StepLineReturn",
  components: {
    PendingSpinner,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
  },
  setup() {
    const status = ref(false);
    const pending = ref(false);
    return { status, pending };
  },
  methods: {
    updateStatus(status: boolean) {
      this.status = status;
    },
    updatePending(pending: boolean) {
      this.pending = pending;
    },
  },
});
