import { defineStore } from 'pinia'

export const usePendingStore = defineStore({
    id: 'pendingStore',
    state: () => ({
        routerIsPending: false as boolean,
        firstLoading: true as boolean,
    }),
    getters: {
        getRouterIsPending(state){
            return state.routerIsPending;
        },
        getFirstLoading(state){
            return state.firstLoading;
        }
    },
    actions:{
        /**
         * Reset toutes les variable de pending
         */
        removeAllPending(){
            this.routerIsPending = false;
            this.firstLoading = false;
        }
    }
})