import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    type: "text",
    class: "inputText",
    outlined: ""
  }, _createSlots({ _: 2 }, [
    (_ctx.hint)
      ? {
          name: "hint",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.hint), 1)
          ])
        }
      : undefined
  ]), 1024))
}