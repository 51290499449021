
import { isTokenReady } from "@baloise/vue-keycloak";
import { computed, defineComponent, ref } from "vue";
import { useReturnStore } from "@/store/returnStore";
import { RouteName } from "@/constante/RouterConst";

export default defineComponent({
  name: "HeaderCustomerData",
  components: {},
  setup() {
    const returnStore = useReturnStore();
    const getCustomer = computed(() => {
      return returnStore.getCustomer;
    });

    const redirectRoutes = ref([
      RouteName.CreateReturnOrder,
      RouteName.Customer,
      RouteName.CustomerPurchase,
      RouteName.CustomerStock,
      RouteName.CustomerOrders,
    ]);

    return {
      getCustomer,
      redirectRoutes,
    };
  },
  async mounted() {
    await isTokenReady();
    //suppression de la route correspondant à la route actuelle
    this.redirectRoutes = this.redirectRoutes.filter(
      (route) => route !== this.$route.name
    );
  },
  methods: {
    getCustomerFormatedAddress() {
      if (this.getCustomer)
        return (
          this.getCustomer.address +
          " " +
          this.getCustomer.address2 +
          " " +
          this.getCustomer.address3 +
          ", " +
          this.getCustomer.postCode +
          " " +
          this.getCustomer.city
        );
    },
    getActualRouteLabelButton() {
      return this.getLabelForRouteName(this.$route.name as string);
    },
    getLabelForRouteName(routeName: string): string {
      switch (routeName) {
        case RouteName.Customer:
          return this.$t("message.customer.btn.customer_sheet");
        case RouteName.CustomerPurchase:
          return this.$t("message.customer.btn.customer_purchase");
        case RouteName.CustomerStock:
          return this.$t("message.customer.btn.customer_stock");
        case RouteName.CustomerOrders:
          return this.$t("message.customer.btn.customer_orders");
        case RouteName.CreateReturnOrder:
        case RouteName.SelectReturnItem:
        case RouteName.CheckReturnOrder:
        case RouteName.SelectShipping:
        case RouteName.ReturnValidation:
          return this.$t("message.customer.btn.create_return");
        default:
          return "";
      }
    },
  },
});
