
import { defineComponent, ref } from "vue";
import TemplateModaleSite from "@/components/modal/TemplateModalSite.vue";
import { ModalBtnType } from "@/type/Modal/ModalType";
import { StatsReturnItemRecommandation } from "@/type/Return/ReturnType";
import { router } from "@/router";
import { RouteName } from "@/constante/RouterConst";
import ApiGestionCap from "@/services/ApiGestionCap";
import PendingSpinner from "@/components/global/PendingSpinner.vue";

export default defineComponent({
  name: "ModalStatsDetailReturnItem",
  components: {
    TemplateModaleSite,
    PendingSpinner,
  },
  props: {},
  setup() {
    const isOpen = ref(false as boolean);
    const saveButton = ref({
      label: "",
      affBtn: false,
    } as ModalBtnType);
    const statsReturnItemRecommandation = ref(
      null as null | StatsReturnItemRecommandation
    );
    const isPending = ref(false as boolean);
    const idReturnItemRecomandation = ref(null as null | number);
    return {
      isOpen,
      saveButton,
      statsReturnItemRecommandation,
      isPending,
      idReturnItemRecomandation,
    };
  },

  methods: {
    /**
     * fonction qui recupere les famille disponible pour l'exclusion et qui ouvre la modal
     */
    modalOpen(idReturnItemRecomandation: number) {
      this.idReturnItemRecomandation = idReturnItemRecomandation;
      this.isOpen = true;
      this.refreshData();
    },
    /**
     * fonction qui ferme la modal
     */
    modalClose() {
      this.isOpen = false;
    },
    async refreshData() {
      if (this.idReturnItemRecomandation) {
        this.isPending = true;
        try {
          this.statsReturnItemRecommandation =
            await ApiGestionCap.getDetailStatReturnItemReco(
              this.idReturnItemRecomandation
            );
        } catch (error) {
          this.isPending = false;
          router.push({ name: RouteName.Error });
        }
        this.isPending = false;
      }
    },
    getTotalHistoPurchase() {
      if (this.statsReturnItemRecommandation) {
        return this.statsReturnItemRecommandation.statsHistoItemStockAndPurchases.reduce(
          (acc, stat) => acc + stat.quantityPurchase,
          0
        );
      }
    },
    getTotalHistoRequested() {
      if (this.statsReturnItemRecommandation) {
        return this.statsReturnItemRecommandation.statsHistoItemStockAndPurchases.reduce(
          (acc, stat) => acc + stat.quantityRequested,
          0
        );
      }
    },
    getTotalHistoSales() {
      if (this.statsReturnItemRecommandation) {
        return this.statsReturnItemRecommandation.statsDetailHistoSales.reduce(
          (acc, stat) => acc + stat.quantitySales,
          0
        );
      }
    },
    getTotalHistoSalesSubstitute() {
      if (this.statsReturnItemRecommandation) {
        return this.statsReturnItemRecommandation.statsDetailHistoSales.reduce(
          (acc, stat) => acc + stat.quantitySubstituteSales,
          0
        );
      }
    },
    getTotalHistoSalesTotal() {
      if (this.statsReturnItemRecommandation) {
        return this.statsReturnItemRecommandation.statsDetailHistoSales.reduce(
          (acc, stat) => acc + stat.quantitySalesTotal,
          0
        );
      }
    },
  },
});
